import { userService } from "../_services/api";

export const LoginController = {
    userLogin,
    userSignUp,
    otpVerification,
    checkEmail,
    checkMobile,
    FindCustomer,
    ForgotPasswordOTP,
    PasswordReset,
    resendOTp,
    cartCount
}

async function userLogin(payload) {
    try {
        let apiEndpoint = `api/customer/CustomerSignin`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function userSignUp(payload) {
    try {
        let apiEndpoint = `api/customer/CustomerSignup`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


async function otpVerification(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerOtpVerification`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function resendOTp(payload) {
    try {
        let apiEndpoint = `api/Customer/ResetSendOTP`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function checkEmail(payload) {
    try {
        let apiEndpoint = `api/Customer/CheckEmail`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function checkMobile(payload) {
    try {
        let apiEndpoint = `api/Customer/CheckMobile`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


async function FindCustomer(payload) {
    try {
        let apiEndpoint = `api/Login/FindCustomer`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


async function ForgotPasswordOTP(payload) {
    try {
        let apiEndpoint = `api/Login/CheckCustomerOTP`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


async function PasswordReset(payload) {
    try {
        let apiEndpoint = `api/Login/ResetPassword`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


async function cartCount(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerCartCount`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}
