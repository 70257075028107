import { userService } from "../_services/api";

export const ConfiguratorController = {
    getSkuDetails,
    getSkuDetailsOnChange,
    getGenderWiseImages,
    getCustomerLogo,
    getFontList,
    getColorList,
    insertCartDesign,
    insertCustomisationCart
}

async function getSkuDetails(payload) {
    try {
        let apiEndpoint = `api/Customer/GetCustomerSkuDetails`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getSkuDetailsOnChange(payload) {
    try {
        let apiEndpoint = `api/Customer/GetCustomerSkuDetailsOnchange`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getGenderWiseImages(payload) {
    try {
        let apiEndpoint = `api/Customer/GetCustomerSkuDetails_Images`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getCustomerLogo(payload) {
    try {
        let apiEndpoint = `api/Customer/GetCustomerlogo`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getFontList(payload) {
    try {
        let apiEndpoint = `api/Customer/customisePageTextFont`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getColorList(payload) {
    try {
        let apiEndpoint = `api/Customer/customisePageTextColor`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


async function insertCustomisationCart(payload) {
    try {
        let apiEndpoint = `api/Customer/InsertCart`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function insertCartDesign(payload) {
    try {
        let apiEndpoint = `api/Customer/InsertCartDesign`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


