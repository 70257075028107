import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Login from '../components/login';
import 'react-owl-carousel2/lib/styles.css';
import Footer from '../components/footer';
import Header from '../components/header';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';
import Jump from 'react-reveal/Jump';
import OwlCarousel from 'react-owl-carousel2';
import {Helmet} from "react-helmet";

const awsURL = "https://go4m-products.s3.ap-south-1.amazonaws.com/";


const Home = () => {
	const inputRef = React.createRef(null);
	const inputRef2 = React.createRef(null);
	const [open,setOpen] = useState(false);
	const [bannerIndex,setBannerIndex] = useState(1);
	
	var slideIndex = 0;

	useEffect(() => {
		showSlides();
	},[]);

	function showSlides() {
		var i;
		var slides = document.getElementsByClassName("slide");
		var dots = document.getElementsByClassName("dots");
			for (i = 0; i < slides.length; i++) {
				slides[i].className = slides[i].className.replace(" active", "");;  
			}
			slideIndex++;
			if (slideIndex > slides.length) {slideIndex = 1}  
			for (i = 0; i < dots.length; i++) {
				dots[i].className = dots[i].className.replace(" active", "");
			  }
			  slides[slideIndex-1].className += " active";
		dots[slideIndex-1].className += " active";	
		setBannerIndex(slideIndex);
		setTimeout(showSlides, 5000); // Change image every 2 seconds
	}

	const options = {
		nav: false,
		dots:true,
		slideTransition: 'linear',
        autoplayTimeout: 0,
        autoplaySpeed: true,
        autoplayHoverPause: false,
		stagePadding:10,
		loop:true,
		responsive:{
			0:{
				items:2
			},
			600:{
				items:3
			},
			1000:{
				items:4
			}
		}
	};

	const options2 = {
		items: 1,
		nav: false,
		dots:false,
		autoplay: false,
		stagePadding:0,
		loop:false,
		touchDrag:false,
		mouseDrag:false,
		smartSpeed:1500
	};

    return(
        <React.Fragment>
			<Helmet>
				<title>Go4Merchandise | Create,Design & Customise T-Shirts and Caps</title>
				<meta name="description" content="Customize your merchandise online. Create, Design, Customise and Shop t-shirts and caps with unmatched level of customisation, variety of fabrics, colours and styles" />
			</Helmet>
			<Header />
			{open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
            	setOpen(false);
          	}} /> : null}
			<div className="mainScreen">
			<div className="banner">
		  		<div className="slider-container">
				  <div className={"slide "}>
					<div className="slideImg">
						<img src={awsURL+"assets/images/model1.png"} />
					</div>	
					<div className="text">
						<h2>FREEDOM</h2>
						<h4>to</h4>
						<h2>CUSTOMISE</h2>
					</div>	
					<div className="slideImg">
						<img src={awsURL+"assets/images/model2.png"} />
					</div>
				</div>
				<div className={"slide"}>
					<div className="slideImg">
						<img src={awsURL+"assets/images/model3.png"} />	
					</div>
					<div className="text">
						<h4 className="liteText">We bring your</h4>
						<h2>IMAGINATION</h2>
						<h4>into</h4>
						<h2>REALITY</h2>
					</div>
					<div className="slideImg">
						<img src={awsURL+"assets/images/model4.png"} />
					</div>	
				</div>
			</div>

				<div className="circle">
					<img src={awsURL+"assets/images/circel.png"} className="w-100" />
				</div>
				<div className="slideIndex">0{bannerIndex}</div>
				<div className="dots-container">
					<span className="dots" ></span>
					<span className="dots"></span>
					<span className="dots"></span>
				</div>
			</div>
			</div>
            <div className="container-fluid mainScreen p-0 p-sm-3">
			<div className="row">
			
				{/* <header>
					<div className="topmenu">FREE Shiping on all orders.</div>
					<nav className="navbar navbar-default topmenu2">					
						<div className="container">
							<div className="navbar-header page-scroll">
								<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
									<span className="sr-only">Toggle navigation</span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
								<a href="#">
									<img src="images/logo.jpg" className="logo" />
								</a>
							</div>
							<div className="collapse navbar-collapse navbar-ex1-collapse">
								<ul className="nav navbar-nav">
									<li>
										<a href="#">Customise</a>
									</li>
									<li>
										<a href="#">Why Go4M?</a>
									</li>
									<li>
										<a href="#">How it Works?</a>
									</li>
								</ul>
							</div>
						</div>

					</nav>
				</header>	 */}
			</div>
				
					
			<div className="clr"></div>
			<div className="row toppad7">
				<div className="linehead">
					<div className="lineheadleft">
						<div className="lineheadleft1"> </div>
						<div className="lineheadleft2"> </div>
					</div>
					<div className="lineheadright">
						<h1>Wear good. Feel good.</h1>
						<h2>Wear good. Feel good.</h2>
					</div>					
				</div>
			</div>
			<div className="clr"></div>
			<div className="panelbg ">
				<div className="panelinner pl-lg-5 pr-lg-2">
					<div className="container-fluid">						
						<div className="row no-gutters">
						
						<Fade top>
						<div className="col-lg-5 col-5" >
							<div className="panelleft">
								<img src={awsURL+"assets/images/panel1.png"} className="img-fluid"/>
							</div>	
						</div>
						</Fade>

                      
						<div className="col-lg-4 col-7">
							<div className="panelmiddile mt-0 mt-lg-4">
								<h2>olo t-Shirt</h2>
								<h3 className="ml-3 ml-lg-5 text-right text-lg-left">Make it Personal. Make it Right</h3>
								<button className="btn btn-theme-outlined-animate px-4 ml-auto mr-0 mr-lg-5 mt-1 mt-lg-3 float-right py-1 py-lg-2" onClick={() => {
								if(localStorage.getItem("login") && (window.outerWidth > 600)){
									window.open(`https://configurator.go4merchandise.com/customise/15?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
								}
								else if(localStorage.getItem("login") && (window.outerWidth < 600)){
									window.open(`/configurator?selectionId=15`);
								}
								else{
									setOpen(true);
								}
								}}>Customise</button>
								{/* <a className="font-weight-bold mt-5 d-inline-block py-2 px-5" ></a> */}
							</div>	
						</div>
						<Fade bottom>
						<div className="col-lg-3 d-none d-sm-block">
							<div className="panelright">
								<img src={awsURL+"assets/images/panel2.png"}/>
							</div>
						</div>
						</Fade>
                        </div>
					</div>	
				</div>
			</div>
			<div className="clr"></div>
            <div className="panelbg1">
				<div className="panelinner1 pl-lg-3 pr-lg-5">
					<div className="container-fluid">						
						<div className="row no-gutters">
						<Slide  top>
                        <div className="col-lg-5 col-7 order-2 order-lg-3">
							<div className="panelleft1">
								<img src={awsURL+"assets/images/panel3.png"} className="img-fluid"/>
							</div>	
						</div>
						</Slide >
						<div className="col-lg-4 order-lg-2 col-5">
							<div className="panelmiddile1 mt-0 mt-lg-4">
								<h2>5 - Panel Ca</h2>
								<h3 className="ml-lg-3 ml-0">Make it Personal. Make it Right</h3>
								<button className="btn btn-theme-outlined-animate px-4 ml-auto mr-0 mr-lg-5 mt-1 mt-lg-3 float-left" onClick={() => {
										if(localStorage.getItem("login") && (window.outerWidth > 600)){
											window.open(`https://configurator.go4merchandise.com/customise/53?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
										}
										else if(localStorage.getItem("login") && (window.outerWidth < 600)){
											window.open(`/configurator?selectionId=53`);
										}
										else{
											setOpen(true);
										}
								}}>Customise</button>
								
								{/* <a className="font-weight-bold mt-5 d-inline-block py-2 px-5" href="#" >Customise</a> */}
							</div>	
						</div>
						<Slide bottom>
						<div className="col-lg-3 order-1 d-none d-sm-block">
							<div className="panelright1">
								<img src={awsURL+"assets/images/panel4.png"}/>
							</div>
						</div>
						</Slide>
                        </div>
					</div>	
				</div>
			</div>
			<div className="clr"></div>
			<div className="panelbg2 mb-5 mb-md-0">
				<div className="panelinner2 pl-lg-3 pr-lg-5">
					<div className="container-fluid">						
						<div className="row">
						<Fade top>
                        <div className="col-lg-5 col-5">
							<div className="panelleft2">
								<img src={awsURL+"assets/images/panel5.png"} className="img-fluid"/>
							</div>	
						</div>
						</Fade>
						<div className="col-lg-4 col-7">
							<div className="panelmiddile2 mt-0 mt-lg-4">
								<h2> - Panel Cap</h2>
								<h3 className="ml-5">Make it Personal. Make it Right</h3>
								<button className="btn btn-theme-outlined-animate px-4 ml-auto mr-0 mr-lg-5 mt-1 mt-lg-3 float-right py-1 py-lg-2" onClick={() => {
									if(localStorage.getItem("login") && (window.outerWidth > 600)){
										window.open(`https://configurator.go4merchandise.com/customise/102?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
									}
									else if(localStorage.getItem("login") && (window.outerWidth < 600)){
										window.open(`/configurator?selectionId=102`);
									}
									else{
										setOpen(true);
									}
								}}>Customise</button>
								
								{/* <a className="font-weight-bold mt-5 d-inline-block py-2 px-5" href="#" >Customise</a> */}
							</div>	
						</div>
						<Fade bottom>
						<div className="col-lg-3 d-none d-sm-block">
							<div className="panelright2">
								<img src={awsURL+"assets/images/panel6.png"}/>
							</div>
						</div>
						</Fade>
                        </div>
					</div>	
				</div>
			</div>
				<div className="clr"></div>
			</div>
				
			<div className="clr"></div>
		 <div className="mainScreen">
			<section className="unlockTreasure mt-5">
						<div className="container-fluid p-0">
						
								<OwlCarousel ref={inputRef2} options={options2}>
									<div>
									<div className="row no-gutters m-0">
										<div className="col-md-3">
											<img src={awsURL+"assets/images/offer_left.jpg"} className="w-100 h-100 d-none d-md-block"/>
										</div>
										<div className="col-md-6 d-flex ">
												<Jump>
													<div className="introofferblue">
														<img src={awsURL+"assets/images/smile.jpg"} />
													</div>
												</Jump>
											<div className="introofferbxin3 w-100">
												<div className="introofferbxin3up">
													<img className="d-block m-auto" src={awsURL+"assets/images/offerbx.jpg"} />
												</div>
												<div className="introofferbxin3down">
													<h2>INTRODUCTORY</h2>
													<h2>OFFER!!</h2>
													<a className="btn btn-theme-outlined-animate font_13" onClick={() => inputRef2.current.next()}>Click Here</a>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<img src={awsURL+"assets/images/offer_right.jpg"} className="w-100 h-100 d-none d-md-block"/>
										</div>
									</div>
									</div>
									<div>
								<img src={awsURL+"assets/images/Mask-banner.gif"} alt="offer"/>
									</div>
								</OwlCarousel>
						
						</div>
					</section>
					<div className="row toppad7 knowMoreSec mt-5">
				<div className="linehead">
					<div className="lineheadleft">
						<div className="lineheadleft1"> </div>
						<div className="lineheadleft2"> </div>
					</div>
					<div className="lineheadright">
						<h1>Why <span className="text-theme-color">Go4Merchandise.com</span></h1>
						<h2>Why <span className="text-theme-low-opacity">Go4Merchandise.com</span> </h2>
					</div>					
				</div>
			</div>
			<div className="clr"></div>
			<div className="row toppad7 abtusbx mb-5 p-3 p-md-0 mt-md-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<p>
								Digital is the new normal. The year 2020 has brought in new meaning to life and the way we do business. 
								Our endeavor to serve our clients has led to the inception of Go4Merchandise.com (lovingly called as Go4M). 
								Go4M is backed by 30 years of experience of Brij & Co.
								<br/><br/>
								Ease of ordering, Multiple levels of Customization and Unmatched variety of fabric, colors and styles makes 
								Go4M unique in itself for Apparel Merchandise industry. Let’s have Freedom to Customize!
								<br/><br/>
							</p>
							<a href="/whyGo4M" className="text-muted">Know More <i className="fas fa-arrow-right ml-2 text-dark"></i></a>
						</div>
						<div className="col-lg-4">
							<img className="w-100 mt-3 mt-md-0" src={awsURL+"assets/images/praveen-tayal.png"} />
						</div>
					</div>
				</div>	
			</div>
			<div className="clr"></div>
		
			<div className="clr"></div>
		
		 </div>
			 
		
		
		<div className="clientbx mt-5">
			<div className="titlelogo">
				<img src={awsURL+"assets/images/client-circle.png"} />
			</div>
			<div className="container">
					<div className="row">
				<OwlCarousel ref={inputRef} options={options} >
					<div><img src={awsURL+"assets/img/clients/AntonPaar.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/Apple.png"} className="img-logo" alt="" /></div>        
					<div><img src={awsURL+"assets/img/clients/Bose.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/Escorts.png"} className="img-logo" alt="" /></div>  
					<div><img src={awsURL+"assets/img/clients/Google.png"} className="img-logo" alt="" /></div>       
					<div><img src={awsURL+"assets/img/clients/Hero.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/Jeep.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/KIA.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/MarutiSuzuki.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/MGMotors.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/Sony.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/StandardChartered.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/Voltas.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/Wildcraft.png"} className="img-logo" alt="" /></div>
					<div><img src={awsURL+"assets/img/clients/Yokohama.png"} className="img-logo" alt="" /></div>
				</OwlCarousel>
				</div>
			</div>	
			</div>
			<div className="container-fluid">
			<Footer />
			</div>
        </React.Fragment>
    )
}

export default Home;