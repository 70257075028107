import React, { useEffect, useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
  } from 'reactstrap';
import logo from '../assets/images/logo.jpg';
import flag from '../assets/images/flag.png';
import user from '../assets/images/user.png';
import tshirt from '../assets/images/tshirt-icon.png';
import cap from '../assets/images/cap-icon.png';
import _cap from '../assets/images/5-cap.png';
import logout from '../assets/images/logout.png';
import help from '../assets/images/help.png';
import profile from '../assets/images/profile.png';
import orders from '../assets/images/orders.png';
import library from '../assets/images/library.png';
import Login from './login';
import { Badge, Chip, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { LoginController } from '../controllers/login.controller';
  
const useStyles = makeStyles({
  drawer: {
    width: 200,
    flexShrink: 0,
  },
  root:{
    minWidth:20
  },
  icons:{
    width:20,
    height:20,
    filter:'invert(1)',
    marginRight:10
  }
});

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLogin,setIsLogin] = useState(false);
    const [cart,setCart] = useState(0);
    const [sideDrawer,updateSideDrawer] = useState(false);
    const classes = useStyles();
    
    // const toggle = () => setIsOpen(!isOpen);
    useEffect(() => {
      if(localStorage.getItem("login")){
        setIsLogin(true);
        LoginController.cartCount({customer_id:localStorage.getItem("customer_id")}).then(data => setCart(data))
      }
    },[]);

    const handleDrawer = () => {
      updateSideDrawer(!sideDrawer);
    }
    
    

    const [showLogin,setShowLogin] = useState(false);
    return (
        <React.Fragment>
          {showLogin ? <Login onHide={() => setShowLogin(false)} onSuccess={() => {
            setIsLogin(true);
            setShowLogin(false);
          }} /> : null}
        <div className="header_strip">
            <p>Free Shipping on all orders</p>
        </div>
        <Navbar className="w-100 mb-0 shadow-sm" style={{position:'sticky',top:0,zIndex:99}} expand="md">
        <div className="container pl-md-5">
        <NavbarBrand className="p-0" href="/"><img src={logo} className="logo" /></NavbarBrand>
        <Badge badgeContent={cart.total} className="d-md-none" color="primary">
        <IconButton aria-label="delete" className={"p-0"}>
          <i class="fal fa-shopping-cart"></i>
        </IconButton>
        </Badge>
        <NavbarToggler onClick={handleDrawer} />
        <Collapse className="position-relative" isOpen={false} navbar>
          <Nav className="mx-auto" navbar>           
            <UncontrolledDropdown className="custom_drop product_drop" nav inNavbar>
              <DropdownToggle nav caret>
              Customise
              </DropdownToggle>
              <DropdownMenu className="px-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="dropTitle">
                        T-shirt
                    </div>
                    <DropdownItem  onClick={() => {
                    if(localStorage.getItem("login") && (window.outerWidth > 600)){
                      window.open(`https://configurator.go4merchandise.com/customise/15?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
                    }
                    else if(localStorage.getItem("login") && (window.outerWidth < 600)){
                      window.open(`/configurator?selectionId=15`);
                    }
                    else{
                      setShowLogin(true);
                    }
                  }}>
                   <img src={tshirt} /> Polo Shirt
                   </DropdownItem>
                  </div>
                  <div className="col-md-6">
                        <div className="dropTitle">
                            Cap
                        </div>
                      <DropdownItem onClick={() => {
                        if(localStorage.getItem("login") && (window.outerWidth > 600)){
                          window.open(`https://configurator.go4merchandise.com/customise/53?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
                        }
                        else if(localStorage.getItem("login") && (window.outerWidth < 600)){
                          window.open(`/configurator?selectionId=53`);
                        }
                        else{
                          setShowLogin(true);
                        }
                      }}>
                       <img src={_cap} /> 5-Panel Cap
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                        if(localStorage.getItem("login") && (window.outerWidth > 600)){
                          window.open(`https://configurator.go4merchandise.com/customise/102?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
                        }
                        else if(localStorage.getItem("login") && (window.outerWidth < 600)){
                          window.open(`/configurator?selectionId=102`);
                        }
                        else{
                          setShowLogin(true);
                        }
                      }}>
                       <img src={cap} /> 6-Panel Cap
                    </DropdownItem>
                  </div>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/whyGo4m">Why Go4M?</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/howitworks">How it works?</NavLink>
              </NavItem>
          </Nav>
          <NavbarText className="nav-text mr-5">
              <ul className="p-0 list-unstyled m-0 d-flex align-items-center">
                <li className="mr-2">
                  <img src={flag} style={{width:30}} />
                </li>
                  <li className="mr-2">
                      <a className="extra-icons text-dark" href="#" onClick={() => window.location = "/cart"}>
                      <Badge badgeContent={cart.total} color="primary">
                        <i class="fal fa-shopping-cart"></i>
                      </Badge>
                        
                        </a>
                  </li>                   
                    {isLogin ?
                     <UncontrolledDropdown className="custom_drop profile" nav inNavbar>
                     <DropdownToggle nav caret>
                     <Chip
                        icon={<img style={{width:30,borderRadius:50,marginLeft:0}} src={localStorage.getItem("profile") ? localStorage.getItem("profile") : user} />}
                        label={localStorage.getItem("username").substr(0,localStorage.getItem("username").indexOf(' '))}
                      />
                     
                     </DropdownToggle>
                     <DropdownMenu right>
                       <DropdownItem onClick={() => window.location = "/profile"}>
                         <img src={profile} className="mr-2" /> Profile
                       </DropdownItem>
                       <DropdownItem onClick={() => window.location = "/order"}>
                          <img src={orders} className="mr-2" /> My Orders
                       </DropdownItem>
                       <DropdownItem disabled onClick={() => window.location = "/profile"}>
                          <img src={library} className="mr-2" /> Library
                       </DropdownItem>
                       <DropdownItem onClick={() => {
                         localStorage.clear();
                         window.location.href = "/home"
                       }}>
                         <img src={logout} className="mr-2" />
                         logout
                       </DropdownItem>
                     </DropdownMenu>
                   </UncontrolledDropdown>
                     : <a className="" href="#" onClick={() => {
                      setShowLogin(true);
                    }}><img src={user} style={{width:30}} /></a>}
                 
              </ul>
          </NavbarText>
        </Collapse>
        </div>
      </Navbar>
      <Drawer className={"sideMob"} anchor={"right"} open={sideDrawer} onClose={handleDrawer}>
         <div className="userDetails d-flex">
              <img src={localStorage.getItem("profile") ? localStorage.getItem("profile") : user} className="rounded-circle" />
              <div className="ml-3">
                    <h4 className="text-white font_13 mb-0">Hello, {isLogin ? localStorage.getItem("username").substr(0,localStorage.getItem("username").indexOf(' ')) : "Guest"}</h4>
                    {!isLogin ? <button type="button" className="btn btn-link text-theme-color" onClick={() => setShowLogin(true)}>Signup / Login</button> : <button type="button" className="btn btn-link text-theme-color">Freedom to Customise</button>}
              </div>
         </div>
         <div className="link_list text-white">
              <List
                dense={true}
                component="nav">
                <ListItem>
                  <ListItemText disableTypography className="font_11" primary="Customise" />
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.root}>
                    <i class="fas fa-chevron-right text-theme-color font_11"></i>
                  </ListItemIcon>
                  <ListItemText disableTypography className="font_11" primary="T-shirt" />
                </ListItem>
                <List dense={true}>
                      <ListItem button onClick={() => {
                    if(localStorage.getItem("login") && (window.outerWidth > 600)){
                      window.open(`https://configurator.go4merchandise.com/customise/15?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
                    }
                    else if(localStorage.getItem("login") && (window.outerWidth < 600)){
                      window.open(`/configurator?selectionId=15`);
                    }
                    else{
                      setShowLogin(true);
                    }
                  }}>
                      <ListItemIcon className={classes.root}>
                         <img className={classes.icons} src={tshirt} /> 
                      </ListItemIcon>
                      <ListItemText disableTypography className="font_11" primary="Polo Shirt" />
                      </ListItem>
                  </List>
                <ListItem>
                <ListItemIcon className={classes.root}>
                    <i class="fas fa-chevron-right text-theme-color font_11"></i>
                  </ListItemIcon>
                  <ListItemText disableTypography className="font_11" primary="Cap" />
                </ListItem>
                  <List dense={true}>
                  <ListItem button onClick={() => {
                         if(localStorage.getItem("login") && (window.outerWidth > 600)){
                          window.open(`https://configurator.go4merchandise.com/customise/53?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
                        }
                        else if(localStorage.getItem("login") && (window.outerWidth < 600)){
                          window.open(`/configurator?selectionId=53`);
                        }
                        else{
                          setShowLogin(true);
                        }
                      }}>
                    <ListItemIcon className={classes.root}>
                        <img className={classes.icons} src={_cap} /> 
                    </ListItemIcon>
                    <ListItemText disableTypography className="font_11" primary="5-Panel Cap" />
                  </ListItem>
                  <ListItem button onClick={() => {
                       if(localStorage.getItem("login") && (window.outerWidth > 600)){
                          window.open(`https://configurator.go4merchandise.com/customise/102?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
                        }
                        else if(localStorage.getItem("login") && (window.outerWidth < 600)){
                          window.open(`/configurator?selectionId=102`);
                        }
                        else{
                          setShowLogin(true);
                        }
                      }}>
                    <ListItemIcon className={classes.root}>
                        <img className={classes.icons} src={cap} /> 
                    </ListItemIcon>
                    <ListItemText disableTypography className="font_11" primary="6-Panel Cap" />
                  </ListItem>
                </List>
                <ListItem button onClick={() => window.location.href = "/whyGo4M"}>
                  <ListItemText disableTypography className="font_11" primary="Why Go4M?" />
                </ListItem>
                <ListItem button onClick={() => window.location.href = "/howitworks"}>
                  <ListItemText disableTypography className="font_11" primary="How it Works?" />
                </ListItem>
                <Divider className="bg-light my-2"/>
                <ListItem button onClick={() => window.location.href = "/profile"}>
                    <ListItemIcon className={classes.root}>
                        <img className={classes.icons} src={profile} /> 
                    </ListItemIcon>
                  <ListItemText disableTypography className="font_11" primary="Profile" />
                </ListItem>
                <ListItem button onClick={() => window.location.href = "/order"}>
                    <ListItemIcon className={classes.root}>
                        <img className={classes.icons} src={orders} /> 
                    </ListItemIcon>
                  <ListItemText disableTypography className="font_11" primary="My Orders" />
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.root}>
                        <img className={classes.icons} src={library} /> 
                    </ListItemIcon>
                  <ListItemText disableTypography className="font_11" primary="Library" />
                </ListItem>
                <ListItem>
                    <ListItemIcon className={classes.root}>
                    <img className={classes.icons} src={help} /> 
                    </ListItemIcon>
                  <ListItemText disableTypography className="font_11" primary="Help" />
                </ListItem>
                {isLogin ? <ListItem button onClick={() => {
                  localStorage.clear();
                  window.location.pathname = "/"
                }}>
                    <ListItemIcon className={classes.root}>
                        <img className={classes.icons} src={logout} /> 
                    </ListItemIcon>
                  <ListItemText disableTypography className="font_11" primary="Logout" />
                </ListItem> : null}
                <Divider className="bg-light my-2" />
                <ListItem button onClick={() => window.location.href = "/terms"}>
                  <ListItemText disableTypography className="font_11 text-theme-color" primary="Terms of use" />
                </ListItem>
                <ListItem button onClick={() => window.location.href = "/order"}>
                  <ListItemText disableTypography className="font_11 text-theme-color" primary="Privacy Policy" />
                </ListItem>
              </List>
              </div>
        
      </Drawer>
    </React.Fragment>
    )
}
 
export default Header;