import {ButtonToggle}  from 'reactstrap';
import { CircularProgress, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import logo from '../assets/img/Go4.png';
import SimpleReactValidator from 'simple-react-validator';
import useForceUpdate from 'use-force-update';
import { LoginController } from '../controllers/login.controller';
const ForgotPassword = () => {
  
    const [forgotParam,setForgotParam] = useState({
        email:'',
        otp:'',
        password:'',
        encrypt_id:'',
        confirm_password:''
    });
    const [validator,setValidator] = useState(new SimpleReactValidator({
        className:"text-danger validation_text"
    }));
    const [validate,setValidate] = useState({
        message:null
    });

    const forceUpdate = useForceUpdate();
    
    const [customerFound,setcustomerFound] = useState(false);
    const [changePassword,setPassword] = useState(false);
    const [emailPhoneForm,setEmailPhoneForm] = useState(true);
    const [changePasswordOTP,setPasswordOTP] = useState(false);
    const [passwordChange,setPasswordChange] = useState(false);
    const [confirmpasswordVail,setConfirmpasswordVail] = useState(false);
    const [confirmpasswordVailMSG,setConfirmpasswordVailMSG] = useState("");
    const [otpVail,setOtpVail] = useState(false);
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    const stringRegex = /[a-zA-Z]/g;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const [loading,setLoading] = useState(false);

    const handleChange = (e) => {   
        setForgotParam({
            ...forgotParam,
            [e.target.name]:e.target.value
        });
        if(e.target.value.match(stringRegex)){
            if(e.target.value.match(emailRegex)){
                setValidate({...forgotParam,message:null});
            }
            else{
                const param = {
                    message:'Please enter valid email'
                  }
                  setValidate(param);
            }
        }
        else{
            if(e.target.value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && ! (e.target.value.match(/0{5,}/)) ){
                setValidate({...forgotParam,message:null});
            }
            else{
                const param = {
                    message:'Please enter valid mobile number'
                  }
                  setValidate(param);
            }
        }
    }

    const checkEmail = () => {
        setLoading(true);
        if(forgotParam.email !==""){
            const param = {
                email:forgotParam.email
            }
            LoginController.FindCustomer(param).then(data => {
                if(data.valid !== "false")
                {
                    setPasswordOTP(data.valid);
                    setEmailPhoneForm(!data.valid);
                }
                else{
                    setcustomerFound(true);
                }
                setLoading(false);
              
            });
        }
        else{
            alert("Please Enter Email/Phone no.");
            setLoading(false);
        }
       
    }

    const checkOTP = () => {
        setLoading(true);
        if(forgotParam.otp !==""){
        const param = {
            email:forgotParam.email,
            otp:forgotParam.otp
        }
        LoginController.ForgotPasswordOTP(param).then(data => {
            if(data.valid === "true")
            {
                setForgotParam({
                    ...forgotParam,
                    encrypt_id:data.encrypt_id
                });
                setPassword(data.valid);
                setPasswordOTP(!data.valid);
            }
            else{
                setOtpVail(true);
            }
            setLoading(false);

        });
      }
      else{
          alert("Please enter OTP");
          setLoading(false);
      }
    }
    const resetPssword = () => {
        if(forgotParam.password === "")
        {
            setConfirmpasswordVail(true);
            setConfirmpasswordVailMSG("Please enter password.")
        }
        else if(forgotParam.confirm_password ==="")
        {
            setConfirmpasswordVail(true);
            setConfirmpasswordVailMSG("Please enter confirm password.")
        }
        else if(forgotParam.password !== forgotParam.confirm_password){
            setConfirmpasswordVail(true);
            setConfirmpasswordVailMSG("Password and Confirm password should be same.")
        }
        else{
            const param = {
                customer_id:forgotParam.encrypt_id,
                password:forgotParam.password
            }
            LoginController.PasswordReset(param).then(data => {
                setPasswordChange(data.valid);
                setPassword(!data.valid);
            });
        }
      
    }
    
    const backOTP = () => {
        setPasswordOTP(false);
        setEmailPhoneForm(true);
    }

    const backResetpassword = () => {
        setPasswordChange(false);
        setPassword(true);
    }
    
    
    



    return (
        <div className="forgotpassword_panel">
            <div className="container-fluid px-md-5">
                <div  className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <div className="forgot_logo d-flex align-items-center">
                            <img src={logo} />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="forgot_box">
                            <div className="logo_header position-relative mt-2">
                               <h4 className="font_14">{passwordChange ? "Your Password has been updated" : changePassword ? "Choose a new password" : "Find your account"}</h4>
                               <p className="font_10">{passwordChange ? null : changePassword ? "Please enter your new password" : changePasswordOTP ? "please enter otp which you have received on your registered mobile or email address" : "please enter your email or mobile to reset your password"}</p>
                            </div>
                           {passwordChange ? <div className="footer_btns mt-3 align-items-center d-flex ">
                            <button className="font_11 btn btn-theme-outlined"onClick={ ()=> {
                                window.location.pathname="/home";
                            }}>Login</button>
                        </div>  :  changePassword ? <div className="changePassword_panel">
                            <TextField type="password" fullWidth InputLabelProps={{
                            className:"login_labels"
                                }} id="standard-basic" className="mb-3" error={validator.message('Password', forgotParam.password , 'required')} onChange={handleChange} onClick={()=> setConfirmpasswordVail(false)} name="password" label="New Password" />
                            <TextField type="password" fullWidth InputLabelProps={{
                            className:"login_labels"
                                }} id="standard-basic" error={validator.message('Confirm Password', forgotParam.confirm_password , 'required')} onChange={handleChange} onClick={()=> setConfirmpasswordVail(false)} name="confirm_password" label="Confirm Password" />
                                  
                                 {confirmpasswordVail ? <p className="font_11" style={{color:"red"}}>{confirmpasswordVailMSG}</p> : null}
                                  <div className="footer_btns mt-3 align-items-center d-flex " onClick={() => backResetpassword()}>
                                        <button className="font_11 btn btn-theme-outlined" onClick={()=> resetPssword()}>Reset Password</button>
                                    </div> 
                                    
                                </div>
                             :  null}

                     
                        {emailPhoneForm ? <React.Fragment> <TextField fullWidth InputLabelProps={{
                            className:"login_labels"
                                }} id="standard-basic" color="primary" error={!validate.message} helperText={validate.message} onChange={handleChange} onClick={()=>setcustomerFound(false)} name="email" label="Email/Phone" />
                         {customerFound ? <p className="font_11" style={{color:"red"}}>Coustomer Not found.</p> : null}
                        <div className="footer_btns mt-3 align-items-center d-flex ">
                        <ButtonToggle disabled={loading}  className="btn-theme-outlined btn font_13 px-4" onClick={() => checkEmail()}>{loading ? <CircularProgress style={{width:15,height:15}} /> : null} Find an account</ButtonToggle>
                            <button className="btn font_11  btn-link text-muted ml-auto" >Back</button>
                        </div> </React.Fragment>                        
                       : null}

                        {changePasswordOTP ?
                        <React.Fragment> <TextField fullWidth InputLabelProps={{
                            className:"login_labels"
                                }} id="standard-basic" color="primary" name="otp" onChange={handleChange} onClick={()=> setOtpVail(false)} label="OTP" />
                          {otpVail ? <p className="font_11" style={{color:"red"}}>OTP is not valid.</p> : null}
                        <div className="footer_btns mt-3 align-items-center d-flex ">
                        <ButtonToggle disabled={loading}  className="btn-theme-outlined btn font_11 px-4" onClick={() => checkOTP()}>{loading ? <CircularProgress style={{width:15,height:15}} /> : null} Continue</ButtonToggle>
                            <button className="btn font_11  btn-link text-muted ml-auto" onClick={() =>backOTP()}>Back</button>
                        </div>
                        
                        
                         </React.Fragment>
                        :null

                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;