import { AppBar, makeStyles, Tabs,Tab, Divider,Tooltip, IconButton, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Backdrop } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormGroup,Input,Label } from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from '../config/config';
import { ConfiguratorController } from '../controllers/configurator.controller';
import Loading from '../Utils/loading';
import 'react-lazy-load-image-component/src/effects/blur.css';
import selectionIcon1 from '../assets/images/icons/fabIcon.png';
import selectionIcon2 from '../assets/images/icons/colorIcon.png';
import selectionIcon3 from '../assets/images/icons/brandingIcon.png';
import selectionIcon5 from '../assets/images/icons/sizeIcon.png';
import selectionIcon6 from '../assets/images/icons/summaryIcon.png';
// import Cropper from 'react-easy-crop';
// import getCroppedImg from '../Utils/cropImage';
import { Beforeunload } from 'react-beforeunload';
import uploadBtn from '../assets/images/icons/upload_btn.png';
import removeBgIcon from '../assets/images/icons/removeBg.png';
import removeImageIcon from '../assets/images/icons/Remove.png';
import editLogo from '../assets/images/icons/editLogo.png';
import { post } from 'axios';
import { CommonMethods } from '../Utils/commonMethod';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import startIcon from '../assets/images/start_icon.png';
let imageUrl = "https://api.go4merchandise.com/Upload/SKU/";
const img_url_Logo = "https://api.go4merchandise.com/Upload/CP_logo/";
const color_chip_path = "https://api.go4merchandise.com/Upload/product_color/"
const contrast_icon_path = "https://api.go4merchandise.com/Upload/Contrast_icon/"


const ConfiguratorMob = (props) => {
    const [isLoading,setIsLoading] = useState(false);

    // const [skuId,setSkuId] = useState(102);
    const [skuId,setSkuId] = useState(new URLSearchParams(props.location.search).get("selectionId"));
    const [skuData,setSkuData] = useState(null);
    const [imageType,updateImageType] = useState(0);
    const [zoomImageIndex,updateZoomImageIndex] = useState({
      isTrue:false,
      isIndex:0
    });
    const [selectedOption,updateSelectedOption] = useState({
      index:-1,
      heading:""
    });
    const [currentParams,updateCurrentParams] = useState(null);
    const [sizeBox,setSizeBox] = useState(false);
    const [contrastCheck,setContrastCheck] = useState({
      check:false,
      cost:"0.00"
    }); 
    const [showEdit , setShowEdit] = useState(false);
    const [editComment , updateEditComment] = useState({
      id:null,
      comment:''
    });
    const [genderSet,updateGenderSet] = useState([]);
    const [qtySet,updateQtySet] = useState([]);
    const [totalQty,setTotalQty] = useState(0);
    const [totalAmt,setTotalAmt] = useState(0);
    const [brandingOptions,setBrandingOptions] = useState({
      tab:0,
      option:-1,
      image:4
    });
    const [isDirty,setIsDirty] = useState(false);
    const [uploadedFile,setUploadedFile] = useState(null);
    const [tempUploadedFile,setTempUploadedFile] = useState(null);
    const [choosenFile,setChoosenFile] = useState(null);
    const [customerLogo,setCustomerLogo] = useState([]);
    const [selectedLogoValues,setSelectedLogoValue] = useState(null);
    const [logoArr,setLogorr] = useState([]);
    const [copyrightCheckUpload,setCopyrightCheckUpload] = useState({
        showMessage:false,
        check:false
    });

    const [logoConcernCheck,setLogoConcernCheck] = useState({
      check:"",
      comment:""
    });

    const [backdrop , setBackdrop] = useState(true);

    const [copyrightCheckText,setCopyrightCheckText] = useState({
      showMessage:false,
      check:false
    });
    const [addTextObj,setAddTextObj] = useState({
      text:"",
      color:"",
      character:18,
      font:"",
      rgb:""
    });

    const [fontList,setFontList] = useState([]);
    const [colorList,setColorList] = useState([]);
    const [customiseCost,setCustomiseCost] = useState(0);
    const [productPrice,setProductPrice] = useState(0);
    const [proceedConfirmation,setConfirmation] = useState({
      show:false,
      proceed:false
    });

    const [proceedBefore , setProceedBefore] = useState(false);

    const [openTooltip , setOpenTooltip] = useState(false);
    const [removeBgDone , setRemoveBgDone] = useState(false);
  
    
    const LOGO_POSITIONS = ["FRONT","BACK","LEFT","RIGHT"];

    // const [state,setState] = useState({
    //   image: 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000',
    //   crop: { x: 0, y: 0 },
    //   zoom: 1,
    //   aspect: 4 / 3,
    // });
    //   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    //   const [croppedImage, setCroppedImage] = useState(null)

    const useStyles = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      appBar: {
          top: 'auto',
          bottom: 45,
          marginBottom:0,
          padding:0,
          backgroundColor:'#ddd'
        },
        footerBar: {
          top: 'auto',
          bottom: 0,
          marginBottom:0,
          padding:10,
          backgroundColor:'#fff'
        },
        footerBtn:{
          fontSize: 10,
          padding: '5px 10px',
          width: 100,
          marginLeft: 'auto',
        },
        tabPnl:{
          height: (selectedOption.index == 4 && sizeBox) ? 240 : selectedOption.index != 4 ? `calc(100% - ${selectedOption.index === 5 ? 120 : 165}px)` : 20,
          position: 'absolute',
          top: selectedOption.index == 4 ? 'auto' : 75,
          bottom: selectedOption.index == 4 ? 90 : 'auto',
          zIndex: 99,
          width: '100%',
          background: '#fff',
          overflowY:'auto'
        },
        tooltip:{
          fontSize:9
        },
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor:'rgb(255 255 255 / 56%)'
        },
        startIcon:{
          position:'absolute',
          top:'10%',
          left:'25%',
          width:100
        }
    }));

    const classes = useStyles();
    const handleChange = (event, newValue) => {
        setBackdrop(false);
        updateSelectedOption({...selectedOption,index:newValue,heading:event.target.innerText});
        if(newValue === 4){
          setSizeBox(!sizeBox)
        }
        setBrandingOptions({...brandingOptions,option:-1});
    };
      
      function a11yProps(index) {
        return {
          id: `scrollable-auto-tab-${index}`,
          'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
      }

      useEffect(() => {
        setIsLoading(true);
        getSkuDetails({sku_id:skuId});
        getAllLogos();
        getColorList();
        getFontList();
      },[]);

      useEffect(() => {
        if(isDirty){
          calculateRateonChange();
        }
      },[skuData,contrastCheck]);

      const handleTooltipClose = () => {
        setOpenTooltip(false);
      };
    
      const handleTooltipOpen = () => {
        setOpenTooltip(true);
      };


      const calculateRateonChange = () => {
        var tempSkuData = {...skuData};
        var tempCustCost = 0;
        var tempProductPrice = productPrice;
        for (let index = 0; index < tempSkuData.elements.length; index++) {
            const element = tempSkuData.elements[index];
            if(element.check && !element.free){
                tempCustCost = tempCustCost + parseInt(element.cost);
            }
            console.log(tempCustCost);
        }

        if(contrastCheck.check){
            tempCustCost = tempCustCost + parseInt(contrastCheck.cost);
        }

        if(totalQty > 0){
          var slab = tempSkuData.price_slab;
          for (let index = 0; index < slab.length; index++) {
                const element = slab[index];
                if(element.min_qty <= totalQty && element.max_qty >= totalQty){
                    element.selected = true;
                    tempProductPrice = parseInt(element.price) + parseInt(tempCustCost);
                }
          }
        }
        else{
          tempProductPrice = parseInt(tempSkuData.price) + tempCustCost;
        }
        setTotalAmt(totalQty * tempProductPrice);
        setProductPrice(tempProductPrice);
        setCustomiseCost(tempCustCost);
        setSkuData(tempSkuData);
        setIsDirty(false);
      }


      // Get SKU Details on Load
      const getSkuDetails = (param) => {
        ConfiguratorController.getSkuDetails(param).then(data => {
          const currentParams = {
            product_id: data[0].product_id,
            style_id: data[0].style_id,
            fabric_id: data[0].fabric_id,
            color_id: data[0].color_id,
            contrast_id: data[0].contrast_id,
            gender_id:data[0].gender_id
          }     
          updateCurrentParams(currentParams);
          setSkuData(data[0]);
          setProductPrice(data[0].price);
          setIsLoading(false);
        });
        // setIsLoading(false);
      }
      
      // Get SKU Details on change
      const getSkuDetailsOnChange = (param) => {
        var tempLogoArr = [...logoArr];
        ConfiguratorController.getSkuDetailsOnChange(param).then(data => {
          const currentParams = {
            product_id: data[0].product_id,
            style_id: data[0].style_id,
            fabric_id: data[0].fabric_id,
            color_id: data[0].color_id,
            contrast_id: data[0].contrast_id,
            gender_id:data[0].gender_id
          }     
          updateCurrentParams(currentParams);
          if(tempLogoArr.length > 0){
            console.log(tempLogoArr.length);
            for (let index = 0; index < tempLogoArr.length; index++) {
                  const element = tempLogoArr[index];
                  for (let _index = 0; _index < data[0].elements.length; _index++) {
                      var _element = data[0].elements[_index];
                      console.log(index)

                    if(element.element_id == _element.tran_id){
                         console.log(_element.tran_id)
                        _element.check = true;
                        if(index === 0){
                            _element.free = true;
                        }
                        break;
                    }
                   
                  }
            }
          }

          setIsDirty(true);
          setSkuData(data[0]);
          setProductPrice(data[0].price);
          setIsLoading(false);
          updateSelectedOption({...selectedOption,index:null});
        });
      }

      const getAllLogos = () => {
        ConfiguratorController.getCustomerLogo({customer_id:localStorage.getItem("customer_id")})
        .then(data => setCustomerLogo(data));
      }

      const getFontList = () => {
        ConfiguratorController.getFontList("").then(data => setFontList(data))
      }
      const getColorList = () => {
        ConfiguratorController.getColorList("").then(data => setColorList(data))
      }


      const handleFabricChange = (item) => {
            setIsLoading(true);
            var temp = {...currentParams};
            temp.fabric_id = item.fabric_id;
            temp.color_id = item.color_id;
            temp.contrast_id = item.contrast_id
            getSkuDetailsOnChange(temp);
            // setIsLoading(false);
      }

      const handleColorChange = (item) => {
            setIsLoading(true);
            var temp = {...currentParams};
            temp.color_id = item.color_id;
            temp.contrast_id = item.contrast_id;
            getSkuDetailsOnChange(temp);
            // setIsLoading(false);
      }

      const handleContrastChange = (item,index) => {
        setIsLoading(true);
        var temp = {...currentParams};
        var tempCon = [...skuData.contrasts];
        temp.color_id = item.color_id;
        temp.contrast_id = item.tran_id;
        setContrastCheck({...contrastCheck,
          check:true,
          cost:tempCon[index].cost
        });
        setIsDirty(true);
        getSkuDetailsOnChange(temp);
        // setIsLoading(false);
      }

      const handleSizeBox = () => {
        setSizeBox(!sizeBox);
      }

      const handleRadioChange = (e) => {
          setLogoConcernCheck({...logoConcernCheck,
            check:e.target.value
          });
      }

      const handleCommentChange = (e) => {
        setLogoConcernCheck({...logoConcernCheck,
          comment:e.target.value
        });
      }

      const getGenderImages = (gender_id) => {
        setIsLoading(true);
        const param = {
          gender_id:gender_id,
          sku_id:skuData.sku_id
        }
        ConfiguratorController.getGenderWiseImages(param).then(data => {
          setSkuData({...skuData,images:data});
          setIsLoading(false);
        });
      }

      const calculateQty = (id,size,val,name) => {
        var temp = 0;
        var tempQtySet = [...qtySet];
        var tempGenderSet = [...genderSet];
        var tempSkuData = {...skuData};
        var tempProductPrice = productPrice;
        val = val !== "" ? parseInt(val) : 0;
        
        var tempIndex = tempQtySet.findIndex(x => x.gender_id === id && x.size == size);
        var tempGenderIndex = tempGenderSet.findIndex(x => x.gender_id === id);

        // for updating size wise qty array object
        if(tempIndex !== -1){
          tempQtySet[tempIndex].qty = val;
        }
        else{
          var obj = {};
          obj.sku_id = skuData.sku_id;
          obj.gender_id = id;
          obj.size = size;
          obj.qty = val;
          tempQtySet.push(obj);
        }

        // for updating gender wise qty array object
        for (let index = 0; index < tempQtySet.length; index++) {
            const element = tempQtySet[index];
            if(element.gender_id === id){
              temp = temp + element.qty;
            }
        }

        if(tempGenderIndex !== -1){
          tempGenderSet[tempGenderIndex].qty = temp;
        }
        else{
          var obj = {};
          obj.gender_id = id;
          obj.gender = name;
          obj.qty = temp;
          tempGenderSet.push(obj);
        }

        // for updating total qty
        var _qty = 0;
        for (let index = 0; index < tempGenderSet.length; index++) {
              const element = tempGenderSet[index].qty;
              _qty = _qty + element;
        }

        // for updating price slab according qty
        var slab = tempSkuData.price_slab;
        for (let index = 0; index < slab.length; index++) {
              const element = slab[index];
              if(element.min_qty <= _qty && element.max_qty >= _qty){
                  element.selected = true;
                  tempProductPrice = parseInt(element.price) + parseInt(customiseCost);
              }
              else{
                  element.selected = false;
              }
        }
        
        setProductPrice(tempProductPrice);
        setTotalAmt(_qty * tempProductPrice);
        setTotalQty(_qty);
        updateQtySet(tempQtySet);
        updateGenderSet(tempGenderSet);
        setSkuData(tempSkuData);
      }

      const handleBrandingTab = (event,newValue) => {
        setBrandingOptions({...brandingOptions,
          tab:newValue,
          option:-1,
          image:(newValue + 4)
        });
      }

      const convertFile = (uploadFile) => {
        if (uploadFile) {
        setIsLoading(true);
        const url = 'https://api.remove.bg/v1.0/removebg'; 
        const config = {    
            headers: {    
              'X-API-Key': 'JuKjFz4H9stNWKqGajzZ4pXB',  
              'Accept': 'application/json ' 
            },    
        }; 

        let formData = new FormData();
        formData.append("image_file_b64",uploadFile);
        post(url, formData, config).then((response) => { 
           //  var data = btoa(response);
            var temp = "data:image/png;base64," + response.data.data.result_b64;
            setUploadedFile(temp);
            setRemoveBgDone(true);
            setIsLoading(false);
        })
      }  
      else{
        alert("Please choose image first")
      }
    }


    const handleBackDropClose = () => {
      setBackdrop(false);
    };
    const handleBackDropOpen = () => {
      setBackdrop(true);
    };

      // const onCropChange = (crop) => {
      //   setState({ ...state,
      //   crop:crop })
      // }
     
      // const onZoomChange = (zoom) => {
      //   setState({ ...state,
      //       zoom:zoom })
      // }

      // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      //   setCroppedAreaPixels(croppedAreaPixels)
      // }, [])
    
      // const showCroppedImage = useCallback(async () => {
      //   try {
      //     const croppedImage = await getCroppedImg(
      //       state.image,
      //       croppedAreaPixels
      //     )
      //     console.log('donee', { croppedImage })
      //     setCroppedImage(croppedImage)
      //   } catch (e) {
      //     console.error(e)
      //   }
      // }, [croppedAreaPixels])

      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
     });
     
     async function maintobase64(file) {
        console.log(await toBase64(file));
       return await toBase64(file)
     }


     const UploadChoosenLogo = () => {
        setIsLoading(true);
        var tempLogoArr = [...logoArr];
        var tempEL = [...skuData.elements];
        var objectIndex = tempLogoArr.findIndex(x => x.element_id === skuData.elements[brandingOptions.tab].tran_id);
        if(objectIndex !== -1){
            tempLogoArr[objectIndex].logo_id = selectedLogoValues.logo_id;
            tempLogoArr[objectIndex].filePath = selectedLogoValues.logo;
            tempLogoArr[objectIndex].gender_id = skuData.gender_id;
        }
        else{
          const logoParams = {
            element_id:skuData.elements[brandingOptions.tab].tran_id,
            logo_id:selectedLogoValues.logo_id,
            filePath:selectedLogoValues.logo,
            image_path:skuData.images[brandingOptions.tab].image_path,
            gender_id:skuData.gender_id,
            position:LOGO_POSITIONS[brandingOptions.tab],
            type:"logo",
            sku_id:skuData.sku_id,
            comment:editComment.comment  
          }
         tempLogoArr.push(logoParams);
        }
        
        setLogorr(tempLogoArr);

        tempEL[brandingOptions.tab].check = true;

        var tempELIndex = tempEL.findIndex(x => x.free === true);
        if(tempELIndex === -1){
            tempEL[brandingOptions.tab].free = true;
        }
        
        setIsDirty(true);
        setSkuData({...skuData,
          elements:tempEL
        });
        console.log(tempLogoArr);
        setChoosenFile(null);
        setSelectedLogoValue(null);
        setIsLoading(false);
        setBrandingOptions({...brandingOptions,option:-1});
     }

     const insertUploadedLogo = () => {
        if(uploadedFile){
            if(logoConcernCheck.check != ""){
                if(logoConcernCheck.check == "yes"){
                  if(copyrightCheckUpload.check){
                      insertLogoAfterCheck();
                  }
                  else{
                    setCopyrightCheckUpload({...copyrightCheckUpload,showMessage:true});
                    setIsLoading(false);
                  }
                }
                else{
                  if(logoConcernCheck.comment != ""){
                    if(copyrightCheckUpload.check){
                      insertLogoAfterCheck();
                    }
                    else{
                      setCopyrightCheckUpload({...copyrightCheckUpload,showMessage:true});
                      setIsLoading(false);
                    }
                  }
                  else{
                    alert("Please add your comment");
                    return false;
                  }
                }
            }
            else{
              alert("Please give your concern");
              return false;
            }
        }
        else{
            alert("Please upload file");
            return false;
        }



          // if(uploadedFile){
          //   console.log(logoConcernCheck);
          //   if(logoConcernCheck.check != "" && logoConcernCheck.check == "no"){
          //       if(logoConcernCheck.comment != ""){
                  
          //       }
          //       else{
          //         alert("Please add your comment");
          //       }
          //   }
          //   else{
          //     alert("Please give your concern");              
          //   }
          // }
          // else{
          //   alert("Please upload file");
          //   return false;
          // }
          
     }


     
     const insertLogoAfterCheck = () => {
      setIsLoading(true);
      let files = uploadedFile;
      let formData = new FormData(); 
      formData.append("customer_id",localStorage.getItem("customer_id"));
      formData.append("tran_id", "0");
      formData.append("uploadedfile",files);
      formData.append("gender_id", skuData.gender_id);
      formData.append("product_id", skuData.product_id === "7" ? "shirt" : "cap");
      formData.append("position", LOGO_POSITIONS[brandingOptions.tab]);
      formData.append("comment", logoConcernCheck.comment);
      formData.append("type","logo");
      formData.append("sku_id",skuData.sku_id); 
      const url = config.baseUrl +`api/Customer/UploadLogoNew`; 
      const _config = {    
          headers: {    
            'content-type': 'multipart/form-data',    
          },    
      };   
      post(url, formData, _config).then((response) => {
        var tempLogoArr = [...logoArr];
        var tempEL = [...skuData.elements];
        var objectIndex = tempLogoArr.findIndex(x => x.element_id === skuData.elements[brandingOptions.tab].tran_id);
        if(objectIndex !== -1){
            tempLogoArr[objectIndex].logo_id = response.data.msg;
            tempLogoArr[objectIndex].filePath = response.data.filePath;
            tempLogoArr[objectIndex].image_path = skuData.images[brandingOptions.tab].image_path;
            tempLogoArr[objectIndex].gender_id = skuData.gender_id;
        }
        else{
          const logoParams = {
            element_id:skuData.elements[brandingOptions.tab].tran_id,
            logo_id:response.data.msg,
            filePath:response.data.filePath,
            image_path:skuData.images[brandingOptions.tab].image_path,
            gender_id:skuData.gender_id,
            position:LOGO_POSITIONS[brandingOptions.tab],
            type:"logo",
            sku_id:skuData.sku_id  
          }
          tempLogoArr.push(logoParams);
        }
        
        tempEL[brandingOptions.tab].check = true;
        var tempELIndex = tempEL.findIndex(x => x.free === true);
        if(tempELIndex === -1){
            tempEL[brandingOptions.tab].free = true;
        }
        setLogorr(tempLogoArr);
        console.log(tempLogoArr);
        setIsDirty(true);
        setSkuData({...skuData,
          elements:tempEL
        });
        
        setUploadedFile(null);
        setTempUploadedFile(null)
        getAllLogos();
        setCopyrightCheckUpload({showMessage:false,check:false});
        setIsLoading(false);
        setBrandingOptions({...brandingOptions,option:-1});
      });
     }

     const uploadText = () => {
      if(addTextObj.text === ""){
          alert("Please fill text");
          return false;
      }
      else if(addTextObj.color === ""){
        alert("Please choose color");
        return false;
      }
      else if(addTextObj.font === ""){
        alert("Please choose font");
        return false;
      }
      else if(!copyrightCheckText.check){
          setCopyrightCheckText({...copyrightCheckText,showMessage:true});
          return false;
      }
      else{
        setIsLoading(false);
        let formData = new FormData();
        formData.append("customer_id",localStorage.getItem("customer_id"));
        formData.append("tran_id", "0");
        formData.append("text",addTextObj.text);
        formData.append("font1",addTextObj.font);
        formData.append("color",addTextObj.rgb);
        formData.append("gender_id",skuData.gender_id);
        formData.append("product_id",skuData.product_id === "7" ? "shirt" : "cap");
        formData.append("position",LOGO_POSITIONS[brandingOptions.tab]);
        formData.append("type","text");
        formData.append("sku_id",skuData.sku_id);
        const url = config.baseUrl + `api/Customer/UploadLogoNew`; 
         const _config = {    
            headers: {    
               'content-type': 'multipart/form-data',    
            },    
         };  
         post(url, formData, _config).then((response) => {
          var tempLogoArr = [...logoArr];
          var tempEL = [...skuData.elements];
          var objectIndex = tempLogoArr.findIndex(x => x.element_id === skuData.elements[brandingOptions.tab].tran_id);
          if(objectIndex !== -1){
              tempLogoArr[objectIndex].logo_id = response.data.msg;
              tempLogoArr[objectIndex].filePath = response.data.filePath;
              tempLogoArr[objectIndex].image_path = skuData.images[brandingOptions.tab].image_path;
              tempLogoArr[objectIndex].gender_id = skuData.gender_id;
          }
          else{
            const logoParams = {
              element_id:skuData.elements[brandingOptions.tab].tran_id,
              logo_id:response.data.msg,
              filePath:response.data.filePath,
              image_path:skuData.images[brandingOptions.tab].image_path,
              gender_id:skuData.gender_id,
              position:LOGO_POSITIONS[brandingOptions.tab],
              type:"text",
              sku_id:skuData.sku_id  
            }
            tempLogoArr.push(logoParams);
          }
          
          tempEL[brandingOptions.tab].check = true;
          var tempELIndex = tempEL.findIndex(x => x.free === true);
          if(tempELIndex === -1){
              tempEL[brandingOptions.tab].free = true;
          }
          setAddTextObj({...addTextObj,
            text:"",
            color:"",
            character:18,
            font:"",
            rgb:""              
          });
          setIsDirty(true);
          setLogorr(tempLogoArr);
          console.log(tempLogoArr);
          setSkuData({...skuData,
            elements:tempEL
          });
         
          setCopyrightCheckText({showMessage:false,check:false});
          setIsLoading(false);
          setBrandingOptions({...brandingOptions,option:-1});
         });
      }
     }


     const checkValuesBeforeProceed = () => {
       if(!(qtySet.length > 0)){
            alert("Please fill in the required quantity.")
       }
       else if(totalQty < 50){
        alert("Please fill minimum quantity (MOQ) of 50 Pcs.")
       }
       else if(totalQty > 500){
        alert("For bulk enquiries of more than 500 Pcs, Kindly visit us at www.brij.com.")
       }
       else if(!(logoArr.length > 0)){
        alert("One Logo is compulsory")
       }
       else{
          setConfirmation({...proceedConfirmation,show:true});
       }
     }  

     const insertData = () => {
       setIsLoading(true);
       setProceedBefore(true);
      var sizes = qtySet;
      var finalLogoArr = [...logoArr];
      for (let index = 0; index < sizes.length; index++) {
          var element = sizes[index];
          element.sku_id = skuData.sku_id
      }

      for (let index = 0; index < finalLogoArr.length; index++) {
        var _element = finalLogoArr[index];
        for (let index = 0; index < skuData.images.length; index++) {
              var tempLogoElement = skuData.images[index];
              var tempHead = tempLogoElement.heading.split(' ')[0];
              if(tempHead === _element.position){
                  _element.image_path = tempLogoElement.image_path;
              }          
        }
        
      }
      const param = {
        sku_id: skuData.sku_id,
        rate:isNaN(productPrice) ? 0 : productPrice,
        amount:isNaN(totalAmt) ? 0 : totalAmt,
        tran_id:"0",
        customer_id:localStorage.getItem("customer_id"),
        sizes:sizes,
        qty:totalQty
      }

      ConfiguratorController.insertCustomisationCart(param).then(data => {
             var NewID = data.newId;
             const _param = {
               customer_id:localStorage.getItem("customer_id"),
               mtran_id:data.newId,
               sku_id:skuData.sku_id,
               type:"logo",
               logos:logoArr,
               gender_id:skuData.gender_id
           }
           ConfiguratorController.insertCartDesign(_param).then(data => {
               window.location = "https://go4merchandise.com/preview/" + window.btoa(NewID);
           });
      })

      console.log(sizes);
      console.log(finalLogoArr);
      console.log(param);
     }

     const removeUncheckLogo = (id) => {
       var tempLogoArr = [...logoArr];
       var tempLogoArrIndex = tempLogoArr.findIndex(x => x.element_id === id);
       if(tempLogoArrIndex !== -1){
         tempLogoArr.splice(tempLogoArrIndex,1);
         setLogorr(tempLogoArr);
       }
     }


    const handleEditComment = (event) => {
      updateEditComment({...editComment,
        comment:event.target.value
      });
    }

    const onFeedbackChange = (event) => {
      setLogoConcernCheck({...logoConcernCheck,
        comment:event.target.value
      });
   }  
     

      return <React.Fragment>
        {proceedBefore ? null : <Beforeunload onBeforeunload={() => "Are you sure want to leave this page? You have unsaved changes.If you leave the page this changes will be lost."} />}
        {isLoading ? <Loading /> : null}
        {skuData ? <React.Fragment>
          <Backdrop className={classes.backdrop} open={backdrop} onClick={handleBackDropClose}>
            <img className={classes.startIcon + " swingimage"} src={startIcon} />
          </Backdrop> 
        <div className={classes.root}>
          <div className="backToHome">
            <i className="fas fa-arrow-left" onClick={() => window.location = "/home"}></i>
          </div>
           <AppBar elevation={0} variant="elevation" className="p-0 mb-0" position="static" color="default">
               <Tabs
                   value={selectedOption.index}
                   onChange={handleChange}
                   indicatorColor="primary"
                   textColor="primary"
                   className="tabParent"
                   variant="scrollable"
                   scrollButtons="on"
                   aria-label="scrollable auto tabs example"
               >
                 <Tab style={{ zIndex:backdrop ? 9999 : 9 }} className={"selection_tab font_13 " + (currentParams.fabric_id === skuData.fabric_id ? "active text-theme-color" : "")} icon={<img src={selectionIcon1} />} label="Fabric" {...a11yProps(0)} />
                 <Tab className={"selection_tab font_13 " + (currentParams.color_id === skuData.color_id ? "active text-theme-color" : "")} icon={<img src={selectionIcon2} />} label="Color" {...a11yProps(1)} />
                 <Tab className={"selection_tab font_13 " + (logoArr.length > 0 ? "active text-theme-color" : "")} icon={<img src={selectionIcon3} />} label="Branding" {...a11yProps(2)} />
                 <Tab className={"selection_tab font_13 " + (contrastCheck.check ? "active text-theme-color" : "")} icon={<img src={contrast_icon_path + skuData.contrast_icon_path} />} label="Contrast" {...a11yProps(3)} />
                 <Tab className={"selection_tab font_13 " + (totalQty > 0 ? "active text-theme-color" : "")} icon={<img src={selectionIcon5} />} label="Sizes" {...a11yProps(4)} />
                 <Tab className="selection_tab font_13 " icon={<img src={selectionIcon6} />} label="Summary" {...a11yProps(5)} />
               </Tabs>
           </AppBar>
           <div className={classes.tabPnl + (selectedOption.index === 0 ? "" : " d-none")}>
               <div className="customise_panel position-relative">
                 <h6 className="font_11 text-center mb-0 text-theme-color py-2 my-2"><img src={selectionIcon1} /> Fabric</h6>
                 <button className="btn btn_link font_11 panel_close p-2" onClick={() => updateSelectedOption({...selectedOption,index:null})}><i className="fas fa-times"></i></button>
                 <Divider />
                 <div className="container-fluid">
                  <div className="row mt-3 no-gutters">
                  {skuData.fabrics.map((item,index) => {
                      return <div key={"fab" + index} onClick={() => handleFabricChange(item)} className={"optionBox col-12 mb-3 d-flex " + (item.fabric_id === currentParams.fabric_id ? "active" : "")}>
                        <img src={config.baseUrl + "Upload/product_fabric/" + item.icon_path} className="mr-3" />
                        <div>
                          <p className="mb-0">{item.fabric}</p>
                          <p className="mb-0">{item.description}</p>
                          <p className="mb-0">{item.gsm}</p>
                        </div>
                    </div>
                  })}
                  </div>
                 </div>
               </div>
           </div>
           <div className={classes.tabPnl + (selectedOption.index === 1 ? "" : " d-none")}>
               <div className="customise_panel">
               <h6 className="font_11 text-center mb-0 text-theme-color py-2 my-2"><img src={selectionIcon2} /> Color</h6>
                 <button className="btn btn_link font_11 panel_close p-2" onClick={() => {
                   updateSelectedOption({...selectedOption,index:null});
                   setBrandingOptions({...brandingOptions,option:-1});
                 }}><i className="fas fa-times"></i></button>
                 <Divider />
                  <div className="container-fluid">
                    <div className="row mt-3 no-gutters">
                  {skuData.colors.map((item,index) => {
                      return <div key={"color"+index} onClick={() => handleColorChange(item)} className={"optionBox col-6 text-center mb-3 " + (item.color_id === currentParams.color_id ? "active" : "")}>
                        <img src={config.baseUrl + "Upload/product_color/" + item.icon_path} className="mb-2" />
                        <p className="mb-0 text-center">{item.color}</p>
                    </div>
                  })}
                  </div>
                  </div>
               </div>
           </div>
           <div className={classes.tabPnl + (selectedOption.index === 2 ? "" : " d-none")}>
               <div className="customise_panel position-relative h-100">
                  <h6 className="font_11 text-center mb-0 text-theme-color py-2 my-2"><img src={selectionIcon3} /> Branding</h6>
                  <button className="btn btn_link font_11 panel_close p-2" onClick={() => {
                   updateSelectedOption({...selectedOption,index:null});
                   setBrandingOptions({...brandingOptions,option:-1});
                 }}><i className="fas fa-times"></i></button>
                  <Divider />
                  <Tabs
                      value={brandingOptions.tab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="on"
                      style={{minHeight:33,borderBottom:'1px solid #ccc'}}
                      onChange={handleBrandingTab}>
                        {skuData.elements.map((tabEL,index) => {
                          return <Tab label={tabEL.element} className={"font_10 brandTypeTab "  + (tabEL.check ? "text-theme-color" : "")} style={{minHeight:33}} icon={<i className={"fas fa-check " + (tabEL.check ? "text-theme-color" : "d-none")}></i>}  key={"tabEl" + index} />
                        })}
                    </Tabs>
                    <Tabs
                      value={brandingOptions.option}
                      textColor="primary"
                      style={{minHeight:33,borderBottom:'1px solid #ccc'}}
                      onChange={(event,newValue) => {
                        setBrandingOptions({...brandingOptions,
                            option:newValue
                        });
                      }}>
                        <Tab label={'Upload Logo'} className="font_10 brandTypeTab" style={{minHeight:33}} />
                        <Tab label={'Choose Logo'} className="font_10 brandTypeTab" style={{minHeight:33}} />
                        {(skuData.product_id !== "7" && brandingOptions.tab === 1) ? null :  <Tab label={'Add Text'} className="font_10 brandTypeTab" style={{minHeight:33}} />}
                        
                    </Tabs>
                    <div className={"brandingTabPanelLogo pt-2 " + (brandingOptions.option === 0 ? "" : " d-none")} >
                        <p className="font_11 text-center mb-0">Choose to upload file</p>
                        <input accept="image/*" id="contained-button-file"onChange={(e) => {
                          if(e.target.files[0].size > 5000000){
                            alert("Max file size : 5MB");
                          }
                          else if(e.target.files.length > 0){
                              maintobase64(e.target.files[0]).then(data => {
                                setUploadedFile(data);
                                setTempUploadedFile(data);
                              });
                          }
                          e.target.value = null;
                        }} className="d-none" type="file" />
                               <label htmlFor="contained-button-file" className="d-block text-center">
                               <Button
                                 disableElevation
                                 component="span"
                                 color="default"
                                 style={{fontSize:13}}>
                                 <img style={{width:100}} src={uploadBtn} />
                              </Button>                                 
                                 </label>
                                 <p className="font_10 mb-0 text-center">* Maximum file size :5 MB</p>
                                 <p className="font_10 mb-0 text-center">Accepted file type- png, jpg. <br />No Gradient or photographic logo/ image allowed.</p>
                                 <div className="logo_chip position-relative mt-4" style={{backgroundImage:`url(${color_chip_path + skuData.colors[skuData.colors.findIndex(x => x.color_id === skuData.color_id )].icon_path })`}}>
                                    {uploadedFile ?  <img src={uploadedFile} /> : null}
                                 {/* <Cropper
                                    image={state.image}
                                    crop={state.crop}
                                    zoom={state.zoom}
                                    aspect={state.aspect}
                                    onCropChange={onCropChange}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={onZoomChange}
                                    cropShape={'square'}
                                    cropSize={{
                                        width:150,
                                        height:150
                                    }}
                                    style={{
                                        containerStyle:{
                                            height:180
                                        }
                                    }}
                                /> */}
                            </div>
                            <ul className="list-unstyled logoDesc">
                                <li>Maximum logo size width - 10cm</li>
                                <li>Maximum No. of colors - 6cm</li>
                            </ul>
                            {uploadedFile ? <div className="logo_concern px-3">
                              <p className="font_11 mb-2">Are you sure to proceed with the logo as shown</p>
                              <FormGroup row className="px-3">
                                <FormGroup check className="font_13">
                                  <Input id="radio1-option1" value="yes" onChange={handleRadioChange} type="radio" name="radio2" />
                                  <Label check for="radio1-option1">
                                    Yes
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="ml-2 font_13">
                                  <Input id="radio1-option2" value="no" onChange={handleRadioChange} type="radio" name="radio2" />
                                  <Label check for="radio1-option2">
                                    No
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                              {logoConcernCheck.check == "no" ? <>
                              <div className="logo_comment_list mb-3">
                              <FormGroup check>
                                <Label check className="font_11">
                                  <Input type="radio" value={"Remove background"} onChange={onFeedbackChange} name="radio1" />{' '}
                                  Remove background
                                </Label>
                              </FormGroup>
                              <FormGroup check >
                                <Label check className="font_11">
                                  <Input type="radio" value={"Change the logo color to black"} onChange={onFeedbackChange} name="radio1" />{' '}
                                  Change the logo color to black
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label check className="font_11">
                                  <Input type="radio" value={"Change the logo color to white"} onChange={onFeedbackChange} name="radio1" />{' '}
                                  Change the logo color to white
                                </Label>
                              </FormGroup>
                              </div>
                              
                               <p className="font_13">Add Comments</p>
                              <textarea maxLength="50" placeholder="Min length 50 Characters" className="form-control font_13 w-100 mb-3" value={logoConcernCheck.comment} onChange={handleCommentChange}></textarea> </> : null}
                            </div> : null}
                            <div className="copyrightText d-flex px-3">
                              <input type="checkbox" checked={copyrightCheckUpload.check} onChange={(e) => {
                                  setCopyrightCheckUpload({...copyrightCheckUpload,check:e.target.checked,
                                    showMessage:!e.target.checked
                                  });
                              }} className="form-control" />
                              <p className={copyrightCheckUpload.showMessage ? "text-danger" : ""}>Please read and agree to ’Use of logo and image’ in terms of use. Kindly ensure that you
                                  have copyrights to ue any logo or image uploaded by you.</p>
                            </div>
                          
                        </div> 

                        <div className={"brandingTabPanelLogo px-3 " + (brandingOptions.option === 1 ? "" : " d-none")} >
                            <div className="logo_chip position-relative mt-4" style={{backgroundImage:`url(${color_chip_path + skuData.colors[skuData.colors.findIndex(x => x.color_id === skuData.color_id )].icon_path })`}}>
                                {choosenFile && customerLogo.length > 0 ?  <img src={img_url_Logo + customerLogo[parseInt(choosenFile)].logo} /> : null}                                  
                            </div>      
                            {customerLogo.length > 0 ? <div className="d-flex align-items-center mb-3">
                              <p className="font_11 w-75 mx-auto mb-0">Comment: {choosenFile ? (editComment.id === customerLogo[parseInt(choosenFile)].logo_id) ? editComment.comment : customerLogo[parseInt(choosenFile)].comment  : ""}</p>
                              {choosenFile ? customerLogo[parseInt(choosenFile)].comment == "" ? null : showEdit ? <i onClick={() => setShowEdit(false)} className="fas fa-times font_11"></i> : <i onClick={() => {
                                setShowEdit(true);
                                updateEditComment({...editComment,
                                  id:customerLogo[parseInt(choosenFile)].logo_id,
                                  comment:customerLogo[parseInt(choosenFile)].comment
                                })
                              }} className="fas fa-pencil-alt font_11"></i>  : ""}
                            </div> : null}
                            {showEdit ? <div className="editComment">
                            <textarea className="form-control font_11" value={editComment.comment} onChange={handleEditComment}></textarea>
                            <div className="w-100 my-3">
                              <button onClick={() => {
                                updateEditComment(customerLogo[parseInt(choosenFile)].comment);
                                setShowEdit(false);
                              }} className="btn btn-danger font_11 mr-2">Cancel</button>
                              <button onClick={() => {
                                setShowEdit(false);
                              }} className="btn btn-theme-outlined font_11">Submit</button>
                              </div>
                              </div> : null}
                            <h6 className="ml-2">Choose Logo</h6>
                            <Divider />
                            <div className="logo_panel">
                              <div className="container-fluid">
                                  <div className="row">
                                    {customerLogo.length > 0 ? customerLogo.map((logo , index) => {
                                      return  <div key={"logo" + index} className="col-6 p-2">
                                      <div className="logoBox w-100 bg-light border p-2">
                                          <LazyLoadImage 
                                            effect="blur"
                                            src={img_url_Logo + logo.logo}
                                            onClick={() => {
                                              updateEditComment(customerLogo[index].comment)
                                              setChoosenFile(String(index));
                                              setSelectedLogoValue({logo:logo.logo,logo_id:logo.logo_id});
                                          }}
                                            width={'100%'} />
                                       <div className="w-100">
                                        <span className="font_13 d-block text-center">Logo {index + 1}</span>
                                       </div>
                                      </div>
                                    </div>
                                    }) : <div className="col-12">
                                      <p className="text-center mt-2">No Logo in library</p>
                                      </div>}
                                  </div>
                                </div>
                            </div>
                            
                        </div>
                     <div className={"brandingTabPanelText pt-3 " + (brandingOptions.option === 2 ? "" : " d-none")} >
                          <div className="w-75 mx-auto">
                            <input className="form-control font_11" maxLength={18} value={addTextObj.text} onChange={(e) => setAddTextObj({...addTextObj,
                              character:18 - e.target.value.length,
                              text:e.target.value
                            })} style={{height:28}} type="text" />
                            <p className="font_10 mb-0 text-right">({addTextObj.character} characters left)</p>
                            
                          </div>
                          <div className="logo_chip position-relative my-4" style={{height:95,backgroundImage:`url(${color_chip_path + skuData.colors[skuData.colors.findIndex(x => x.color_id === skuData.color_id )].icon_path })`}}>
                                <p style={{fontSize:20,fontFamily:addTextObj.font,color:`rgb(${addTextObj.rgb})`}} className="mb-0">{addTextObj.text}</p>                                
                          </div>
                          <div className="chooseOptions">
                            <label>Choose Font </label>
                            <select className="form-control font_11" onChange={(e) => setAddTextObj({...addTextObj,font:e.target.value})}>
                              <option value="" >Choose Font</option>
                                {fontList.length > 0 ? fontList.map((font,index) => (
                                  <option key={"font" + index} value={font.font_name}>{font.font_name}</option>
                                )) : null}
                            </select>
                          </div>
                          <div className="chooseOptions mt-2">
                            <label>Choose Color</label>
                            <span className="text-center" style={{fontSize:11,width:'auto',padding:'2px 10px'}}>{addTextObj.color}</span>
                          </div>
                          <div className="colorList w-75 mx-auto mt-3">
                          {colorList.length > 0 ? colorList.map((color,index) => (
                                <span onClick={() => setAddTextObj({...addTextObj,color:color.color,rgb:color.rgb})} key={"color" + index} style={{backgroundColor:`rgb(${color.rgb})`,width:26,height:26,display:'inline-block',fontSize:12,textAlign:'center',lineHeight:1.5}}>{color.tran_id === addTextObj.colorId ? <i class="fas fa-check"></i> : null}</span>
                            )) : null}
                          </div>
                          <div className="copyrightText d-flex px-3">
                              <input type="checkbox" checked={copyrightCheckText.check} onChange={(e) => {
                                  setCopyrightCheckText({...copyrightCheckText,check:e.target.checked,
                                    showMessage:!e.target.checked
                                  });
                              }} className="form-control" />
                              <p className={"mt-3" + copyrightCheckText.showMessage ? "text-danger" : ""}>Please read and agree to ’Use of logo and image’ in terms of use. Kindly ensure that you
                                  have copyrights to ue any logo or image uploaded by you.</p>
                            </div>
                            
                    </div>
                    <div className="zoom_image_panel position-relative mt-5">
                      <LazyLoadImage
                        style={{height:(skuData.sku_id !== 1 ? 300 : 'auto'),objectFit:'contain'}}
                        effect="blur"
                        src={imageUrl + skuData.images[brandingOptions.image].image_path}
                        width={'100%'} />
                        {logoArr.length > 0 && (logoArr.findIndex(x => x.element_id === skuData.elements[brandingOptions.tab].tran_id) !== -1) ? <img className={"logo_image " + skuData.images[brandingOptions.image].zoom_class} src={img_url_Logo + logoArr[logoArr.findIndex(x => x.element_id === skuData.elements[brandingOptions.tab].tran_id)].filePath} /> : null}
                    </div>
               </div>
           </div>
           <div className={classes.tabPnl + (selectedOption.index === 3 ? "" : " d-none")}>
               <div className="customise_panel">
               <h6 className="font_11 text-center mb-0 text-theme-color py-2 my-2"><img src={contrast_icon_path + skuData.contrast_icon_path} /> Contrast</h6>
                 <button className="btn btn_link font_11 panel_close p-2" onClick={() => {
                   updateSelectedOption({...selectedOption,index:null});
                   setBrandingOptions({...brandingOptions,option:-1});
                 }}><i className="fas fa-times"></i></button>
                 <Divider />
                 <div className="container-fluid">
                  <div className="row mt-3 no-gutters">
                  {skuData.contrasts.map((item , index) => {
                      return <div key={"contrast" + index} onClick={() => handleContrastChange(item,index)} className={"optionBox col-6 text-center mb-3 " + (item.tran_id === currentParams.contrast_id ? "active" : "")}>
                        <img src={config.baseUrl + "Upload/Product_Contrast/" + item.icon_path} className="mb-2" />
                        <p className="mb-0 text-center">{item.contrast_color}</p>
                    </div>
                  })}
                  </div>
                 </div>
               </div>
           </div>
           <div className={classes.tabPnl + (selectedOption.index === 4 ? "" : " d-none")}>
              <div className="customise_panel">
                <div className={"size_panel px-3 " + (sizeBox || selectedOption.index === 5 ? "active" : "")}>
                  <button onClick={handleSizeBox} className="btn btn-link size_toggle w-100 position-relative"></button>
                  <p className="text-muted font_13">Select Size / Qty</p>
                  {skuData.genders.map((gender,index) => {
                    return <div className="gender_options" key={"gender" + index}>
                    <IconButton onClick={() => getGenderImages(gender.tran_id)} aria-label="view" size="small">
                      <i className="far fa-eye font_14"></i>
                    </IconButton>
                    <span className="font_13 ml-2">{gender.gender}: <span id={"genderId" + gender.gender}>{genderSet.length > 0 && (genderSet.findIndex(x => x.gender_id === gender.tran_id) !== -1) ? genderSet[genderSet.findIndex(x => x.gender_id === gender.tran_id)].qty : 0}</span></span>
                  <div className="size_box my-2">
                    {gender.sizes.map((size,index) => {
                      return <TextField type="text" key={"size" + index} id={"outlined-basic" + size.size + index} value={qtySet.length > 0 && (qtySet.findIndex(x => x.gender_id === gender.tran_id && x.size == size.size) !== -1) ? qtySet[qtySet.findIndex(x => x.gender_id === gender.tran_id && x.size == size.size)].qty === 0 ? "" : qtySet[qtySet.findIndex(x => x.gender_id === gender.tran_id && x.size == size.size)].qty : ""} className={"size_textfield"}  InputProps={{
                        className:" gender" + gender.gender
                      }} onChange={(e) => {
                        if(/^\d+$/.test(e.target.value) || e.target.value === ""){
                          calculateQty(gender.tran_id,size.size,e.target.value,gender.gender)
                        }
                        else{
                          return false;
                        }
                      }} label={size.size} variant="outlined" size="small" />
                    })}
                    {/* // calculateQty(gender.tran_id,size.size,e.target.value,gender.gender)} */}
                    </div>
                </div>
                  })}
                </div>
              </div>
           </div>
           <div className={classes.tabPnl + (selectedOption.index === 5 ? "" : " d-none")}>
               <div className="customise_panel">
                  <h5 className="font-weight-bold my-3 ml-2">Your Order</h5>
                 <button className="btn btn_link font_11 panel_close p-2" onClick={() => {
                   updateSelectedOption({...selectedOption,index:null});
                   setBrandingOptions({...brandingOptions,option:-1});
                 }}><i className="fas fa-times"></i></button>
                 <AppBar position="static" color="default" variant="elevation" elevation={0} className={classes.appBar}>
                    <div className="product_info d-flex align-items-center">
                      <div className="product_icon p-2">
                        <img src={config.baseUrl + "Upload/Product_Icon/" + skuData.elements[0].icon_path} />
                      </div>
                      <p className="mb-0 font_13 ml-2">{skuData.style}-{skuData.gender}-{skuData.color}</p>
                      <p className="mb-0 font_13 ml-auto mr-2">Rs. {parseInt(productPrice)}.00</p>
                    </div>
                  </AppBar>
                  <div className="price_slab_unit position-relative mb-2">
                    <button className="btn btn_slab mt-2">View Price Slab</button>
                    <div className="best-offer mt-3">
                      <table className="table table-bordered bg-white">
                        <thead className="thead-dark">
                          <tr>
                            <th>Qty</th>
                            <th>Price/Pc</th>
                          </tr>
                        </thead>
                      <tbody>
                        {skuData.price_slab.map((price,index) => {
                          return  <tr key={"slab" + index}>
                          <td> {price.min_qty} - {price.max_qty}</td>
                          <td>Rs  {price.price} {price.selected ? <i className="fas fa-check-circle font_13 text-theme-color"></i> : null}</td>
                        </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                  </div>
                  <table className="table table-bordered itemList w-75 mx-auto">
                    <thead>
                      <tr>
                        <th>Items</th>
                        <th></th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {skuData.elements.map((el,index) => {
                        return <tr key={"el" + index}>
                          <td>{el.element}</td>
                          <td><input type="checkbox" className="form-control" onChange={(e) => {
                            var elTemp = [...skuData.elements];
                            elTemp[index].check = e.target.checked;
                            elTemp[index].free = e.target.checked;
                            setIsDirty(true);
                            setSkuData({...skuData,elements:elTemp});
                            removeUncheckLogo(el.tran_id);
                          }} disabled={!el.check} checked={el.check} /></td>
                          <td>{el.free ? "FOC" : el.check ? el.cost : "0.00"}</td>
                        </tr>
                      })}
                      <tr>
                        <td>Contrast</td>
                        <td><input type="checkbox" disabled={!contrastCheck.check} onChange={(e) => {
                          setContrastCheck({check:e.target.checked,cost:"0.00"});
                        }} className="form-control" checked={contrastCheck.check} /></td>
                        <td>{contrastCheck.cost} </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="total_qty_details">
                    <p className="text-muted font_13">Select Size / Qty</p>
                    {genderSet ? genderSet.map((set,index) => {
                     return <div className="gender_options" key={"genderDetails" + index}>
                     <IconButton onClick={() => getGenderImages(set.gender_id)} aria-label="view" size="small">
                       <i className="far fa-eye font_14"></i>
                     </IconButton>
                     <span className="font_13 ml-2">{set.gender}: {set.qty}</span>
                  </div>
                   }) : null}
                     <span className="font_13 ml-2 d-block mt-3">Total Pcs: {totalQty} Nos.</span>
                     <span className="font_13 ml-2 d-block">Price/pc: {parseInt(productPrice)}.00</span>
                     <span className="font_13 ml-2 d-block mt-3">Total Amount: Rs {CommonMethods.currenyMasking(parseInt(totalAmt))}.00 <span className="font_11">(incl of taxes.)</span></span>
                  </div>
               </div>
           </div>
       </div>
 
       <div className="main_container position-relative">
         <div className="panel_options">
           <ul className="list-unstyled p-0">
             {skuData.images.map((image,index) => {
                if(index < 4){
                    return <li key={"type"+index} className={index === imageType ? "active" : ""} onClick={() => {
                      updateImageType(index);
                      setBrandingOptions({...brandingOptions,tab:index,image:index + 4});
                    }}>{image.heading}</li>
                }
             })}
           </ul>
         </div>
         <LazyLoadImage
           effect="blur"  
          src={config.baseUrlImg + skuData.images[imageType].image_path} // use normal <img> attributes as props
          width={'100%'} />
          {logoArr.length > 0 && (logoArr.findIndex(x => x.element_id === skuData.elements[imageType].tran_id) !== -1) ? <img className={"logo_image " + skuData.images[imageType].classs} src={img_url_Logo + logoArr[logoArr.findIndex(x => x.element_id === skuData.elements[imageType].tran_id)].filePath} /> : null}
          
       </div>
 
       {selectedOption.index === 5 ? null : <AppBar position="fixed" color="default" variant="elevation" elevation={0} className={classes.appBar}>
            {brandingOptions.option === -1 ? <div className="product_info d-flex align-items-center">
             <div className="product_icon p-2">
               <img src={config.baseUrl + "Upload/Product_Icon/" + skuData.elements[0].icon_path} />
             </div>
             <p className="mb-0 font_13 ml-2">{skuData.style}-{skuData.gender}-{skuData.color}</p>
             <p className="mb-0 font_13 ml-auto mr-2">Rs. {productPrice}</p>
           </div> : 
           <div className="brandingImageOptions d-flex justify-content-between p-2 px-3">
              <div className="d-flex align-items-center">
                {brandingOptions.option === 0 ?  <React.Fragment> <div className="bd_option" onClick={() => convertFile(uploadedFile)}>
                    <img src={removeBgIcon} />
                    <span>Remove Background</span>
                </div> 
                <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true
                    }}
                    className="tooltip_mob"
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="For best results use PNG file."
                  >
                    <IconButton className="p-0" onClick={handleTooltipOpen}><i className="fas fa-info-circle ml-2 font_11"></i></IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
              </React.Fragment>
              : null}
              </div>
              {removeBgDone ? <div className="bd_option" onClick={() => {
                setRemoveBgDone(false);
                setUploadedFile(tempUploadedFile)
              }}>
                  <i className="fas fa-undo-alt mr-1"></i>
                  <span>Undo</span>
              </div> : null}
              {/* {brandingOptions.option === 2 ?  <div className="bd_option" onClick={() => convertFile(uploadedFile)}>
                  <img src={editLogo} />
                  <span>Edit Text</span>
              </div> : null} */}
              {brandingOptions.option === 0 ? 
              <div className="bd_option" onClick={() => setUploadedFile(null)}>
                  <img src={removeImageIcon} />
                  <span>Remove</span>
              </div> : null }
              {brandingOptions.option === 1 ? 
              <div className="bd_option" onClick={() => setChoosenFile(null)}>
                  <img src={removeImageIcon} />
                  <span>Remove</span>
              </div> : null }
              {brandingOptions.option === 2 ? 
              <div className="bd_option" onClick={() => setAddTextObj({...addTextObj,
                text:"",
                color:"",
                character:18,
                font:"",
                rgb:""              
              })}>
                  <img src={removeImageIcon} />
                  <span>Remove</span>
              </div> : null }
           </div>}
           {/* <div className="step_count d-flex align-items-center bg-white p-3">
               <div className="step_circle active">1</div>
               <i className="fas fa-long-arrow-alt-right"></i>
               <div className="step_circle">2</div>
               <i className="fas fa-long-arrow-alt-right"></i>
               <div className="step_circle">3</div>
               <i className="fas fa-long-arrow-alt-right"></i>
               <div className="step_circle">4</div>
           </div> */}
       </AppBar>}
       <AppBar position="fixed" color="default" variant="elevation" elevation={0} className={classes.footerBar}>
            {selectedOption.index === 5 ? <button onClick={checkValuesBeforeProceed} className={"btn btn-theme-outlined-animate " + classes.footerBtn}>Proceed</button> : selectedOption.index === 2 && brandingOptions.option !== -1 ? <button className={"btn btn-theme-outlined-animate " + classes.footerBtn} onClick={() => {
              if(brandingOptions.option === 0){
                  insertUploadedLogo();
              } 
              else if(brandingOptions.option === 1){
                  UploadChoosenLogo();
                  
              }
              else{
                  uploadText(addTextObj);
                  
              }
            }}>Submit</button> : 
            <button onClick={() => updateSelectedOption({...selectedOption,
              index:selectedOption.index + 1
            })} className={"btn btn-theme-outlined-animate " + classes.footerBtn}>Next</button>}
       </AppBar>
   </React.Fragment> : null}

    {/* Proceed Confirmation Dialog */}
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={proceedConfirmation.show}
    >
      <DialogTitle id="confirmation-dialog-title">Proceed Confirmation</DialogTitle>
      <DialogContent>
        <p className="font_13">Please check your design & sizes before clicking the proceed button as your design will be finalized & will be non-editable.</p>
        <p className="font_13">Do you wish to continue?</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setConfirmation({...proceedConfirmation,show:false})} color="primary">
          Cancel
        </Button>
        <Button onClick={insertData} color="primary">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>


      </React.Fragment>
}

export default ConfiguratorMob;