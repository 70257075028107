import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import FilterResults from 'react-filter-search'; 
import Header from '../components/header';
import Login from '../components/login';


const FAQList = (props) => {
    const [open,setOpen] = useState(false);
    const [collapse,setCollapse] = React.useState({faq:false,go4m:false,order:false,invoice:false,shipping:false,return:false,image:false,})

    const [faqObject,setfaqObject] = useState([
        {     
            type:"heading",  
            question:"1. FAQ's",
             },
        {       
            type:"text",  
        question:"Q. What is go4Merchandise.com",
        answer:`A. <a href=''>go4Merchandise.com</a> is an initiative from the House of BRIJ. It is an online platform which offers an experience to create your customised apparel merchandise. You choose your own colour, fabric and contrast design elements (from the available options) and by adding your logo, text or message. Your created product designs are made to order and are shipped between 14-21 business days.`
        },
        {   
            type:"text",     
        question:"Q. What is the MOQ (Minimum & Maximum Order Quantity) for customised apparel",
        answer:`A. 50 No’s Minimum & 499 Maximum via this Portal. The minimum order quantity is 50 Nos. per colour/ per style. You can order Male/ Female Style in 6 different sizes in each gender.`
        },
        { 
            type:"text",       
        question:"Q. Can I have my delivery expedited?",
        answer:`  A. We deliver your orders as fast as possible. Expedited delivery isn’t currently available.`
        },
        {  
            type:"text",      
        question:"Q. How long does it take to receive my order?",
        answer:`    A. Once your order is received, it will be accessed with feasibility of the logo and Size uploaded. Standard Schedule is 14-21 days for Delivery.`
        },
        {    
            type:"text",    
        question:"Q. How to check the status of my order?",
        answer:` A. Once order is placed, you will be notified via email/ SMS. Dispatches happen only
        between Monday - Friday excluding national holidays. You will receive an order
        confirmation mail with ETD (estimated time of delivery). Once order is dispatched , the
        logistics team will update your dispatch details which will have (Date of Dispatch/ Courier
        Service/ Docket No). You can track online.`
        },
        {   
            type:"text",     
        question:"Q. Can i modify or cancel an online order?",
        answer:`  A. The orders received on go4merchandise.com are processed automatically. Therefore,
        any modifications or cancellations of orders will not be possible.`
        },
        {    
            type:"text",    
        question:"Q. What kind of products could be returned to go4merchandise.com",
        answer:` A. Any orders with the following details can be returned
        <ul>
        <li>Product with Manufacturing Defects*</li>
        <li>Wrong placements of logos</li>
        </ul>`
        },
        {      
            type:"text",  
        question:"Q. How do I return a defective/ faulty garment?",
        answer:`  A. You have to dispatch faulty material at your own cost, once received back Go4M team
        will analyse your issue and confirm back. Once accepted by our team we will process
        your order again.
        `
        },
        {    
            type:"text",    
        question:"Q. What payment methods are accepted?",
        answer:` A. All the orders are directed to CCAVenue Payment Gateway (Third Party). We accept all the credit cards and debit cards (VISA/ MasterCard), Net-banking, Payment Wallets and UPI transactions. We take orders 24/7. <b>No Cash on Delivery mode is
        applicable on <a href="">www.go4merchandise.com</a></b>
        `
        },

        {   
            type:"text",     
        question:"Q. How do I place an order?",
        answer:`  A. <a href="/home/index#how-it-work"><u>Click how it works</u></a><br/>
        In any case need any assistance, drop a mail at info@go4merchandise.com. you will be
        assisted by our experts in 48 working hours.
        `
        },

        {  
            type:"text",      
        question:"Q. What will be the shipping method?",
        answer:`  A. All <a href="">go4Merchandise.com</a> products are shipped via Road Transport.
        `
        },    
        {    
            type:"text",    
        question:"Q. How much are the delivery charges?",
        answer:`  A. There are no delivery charges on the orders placed on <a href="">www.go4merchandise.com</a>`
        },     

        {    
            type:"text",    
        question:"Q. What if I want to order more than 500 pieces?",
        answer:` A. If you want to order more than 500 pieces, you will be assisted by our team. kindly <a href="http://brij.com/">click here</a>.`
        },    
        
        
        {    
            type:"heading",    
        question:" 2. What is go4Merchandise.com",
        },  

        {     
            type:"text",   
        question:"Q. What is go4Merchandise.com",
        answer:`A. <a href=''>go4Merchandise.com</a> is an initiative from the House of BRIJ. It is an online platform which offers an experience to create your customised apparel merchandise. You choose your own colour, fabric and contrast design elements (from the available options) and by adding your logo, text or message. Your created product designs are made to order and are shipped between 14-21 business days.`
        },

        {   
            type:"text",     
        question:"Q. Can I order quantity in multiple sizes?",
        answer:`
        A. Yes, you can order products on go4Merchandise.com in multiple sizes (in size options given).
        `
        },

        {   
            type:"text",     
        question:"Q. How do I know what size to order?",
        answer:`
        A. We follow Asian Standard Sizes. <a href="">Click here</a> to preview size chart   
        `
        },

        {  
            type:"heading",      
        question:"3. Ordering and Payment",

        },

        {    
            type:"text",    
        question:"Q. How do I place an order?",
        answer:`
        A. Kindly follow the steps as mentioned below step by step to place an order <a href="">ongo4Merchandise.com</a>
        <ul>
        <li>First go to <a href="">go4Merchandise.com</a>. Click on the product you want to customise and order on the homepage</li>
        <li>Complete all the customisation, add logos if required and then click on ‘review’ to take a look at your customised design. If you are satisfied with the design, add quantity in male and female sizes as required and then proceed further</li>
        <li>Click on proceed to checkout, you will be redirected to the payment transaction page. Make the payment to confirm your order.</li>
        </ul>


        If you have any difficulties, you can drop a mail at <a href="">info@go4merchandise.com</a>.
        `
        },
        {   
            type:"text",     
        question:"Q. What payment methods are accepted?",
        answer:`
        A. All the orders are directed to CCAVenue Payment Gateway (Third Party). We accept all the credit cards and debit cards (VISA/ MasterCard), Net-banking, Payment Wallets and UPI transactions. We take orders 24/7. <b>No Cash on Delivery mode is applicable on <a href="">www.go4merchandise.com</a></b>

        `
        },

        {    
            type:"text",    
        question:"Q. Is my credit card/ debit card details secured?",
        answer:`
        All credit cards/ debit cards transactions are handled by CCAvenue payment gateway.
        The information is encrypted using Secure Socket Layer Technology (SSL). Although
        go4merchandise.com recommends you to read their privacy policy in relation to you
        personal information handled by the Third Party.<br/>
        go4merchandise.com is committed to protect your information through the use of
        physical and technological procedures. Although no method of transmission over the internet
        or electronic storage is 100% secure. Therefore, we recommend you to take
        additional security measures to safeguard you and your personal information by
        installing anti-virus software, keeping your log-in details confidential and by making sure
        that you regularly update your software and apps on your devices.    
        `
        },
        {  
            type:"heading",      
        question:"4. Invoice",

        },
        {    
            type:"text",    
        question:"Q. What type of invoice will I receive",
        answer:`
        A. CGST/ SGST Invoice will be issued against your GST No Provided along with E-way Bill.
        `
        },
        {   
            type:"text",     
        question:"Q. What is the importance of invoice?",
        answer:`
        A. If you need to exchange or return the product for any reason, you will have to share your
        original invoice. We cannot accept exchange or returns without an original invoice.               `
        },

        {     
            type:"text",   
        question:"Q. How do I get an invoice",
        answer:`
        A. The invoice will be sent along with the order shipment. If you do not receive your invoice with the shipment, please contact our customer support.
        `
        },

        {  
            type:"heading",      
        question:"5. Shipping and Delivery",

        },

        {     
            type:"text",   
        question:"Q. Can I have my delivery expedited?",
        answer:`
        A. We deliver your orders as fast as possible. Expedited delivery isn’t currently available.
        `
        },

        {  
            type:"text",      
        question:"Q. How long does it take to receive my order?",
        answer:`
        A. Once your order is received, it will be accessed with feasibility of the logo and Size
        uploaded.Standard Schedule is 14-21 days for Delivery.
        `
        },

        {   
            type:"text",     
        question:"Q. How to check the status of my order?",
        answer:`
        A. Once order is placed, you will be notified via email/ SMS. Dispatches happen only
        between Monday - Friday excluding national holidays. You will receive an order
        confirmation mail with ETD (estimated time of delivery). Once order is dispatched , the
        logistics team will update your dispatch details which will have (Date of Dispatch/ Courier
        Service/ Docket No). You can track online.
        `
        },

        {  
            type:"text",      
        question:"Q. What will be the shipping method?",
        answer:`
        A. All <a href="">go4merchandise.com</a> products are shipped via Road Transport.

        <strong>Q. How much are the delivery charges?</strong>

        A. Free Shipping on all the orders placed on <a href="">www.go4merchandise.com</a>
        `
        },

        {   
            type:"heading",     
        question:"6. Return and Cancellation",

        },

        {   
            type:"text",     
        question:"Q. Can i modify or cancel an online order?",
        answer:`
        A. The orders received on <a href="">go4Merchandise.com</a> are processed immediately. Therefore, any modifications or cancellations of orders are not possible.

        `
        },

        {    
            type:"text",    
        question:"Q. What kind of products could be returned to go4Merchandise.com",
        answer:`
        A. Any orders with the following details can be returned
        <ul>
        <li>Product with Manufacturing Defects*</li>
        <li>Wrong placement of logos</li>
        </ul>
        `
        },


        {   
            type:"text",     
        question:"Q. How do I return a defective/ faulty garment?",
        answer:`
        A. In case of any manufacturing defect/ wrong order, kindly drop a mail to us within 48 hours of receipt of order at <a href="">support@go4merchandise.com</a> to start your process to return your order. Kindly ensure that the products are unused and in good condition.

        `
        },
        {    
            type:"heading",    
        question:"7. User Provided Image and Logo",

        },

        {       
        question:"Q. User Provided Image and Logo",
        answer:`
        <div>You agree, represent and warrant to <a href="">Go4Merchandise.com</a> that you have the right to use, copy and distribute each copyright, copyrightable works, trademarks, service marks, trade name, logo, statement, tagline, graphic, artwork, name, photograph, portrait, picture or illusion of any person or any other intellectual property in the way that is to included (embroidered/ printed or applied) on to the product ordered by you on <a href="">go4Merchandise.com</a>.
        <br/><br/>
        You agree and warrant that in obtaining and using the material submitted to <a href="">go4Merchandise.com</a> you have not violated and breached the property rights of any third party. You agree to indemnify defend and hold harmless <a href="">Go4Merchandise.com</a>, and its affiliates, officers, directors, employees, agents, licensors and suppliers from and against all claims, losses, liabilities, expenses, damages and costs, including reasonable attorney’s fee made by any Third Parties due to or arising from or relating in any way to your use of Site, your conduct in connection with the Site.
        <br/><br/>                    
        The right of determining the use of not protected copyright, copyrightable works, trademarks, service marks, trade name, logo, statement, tagline, graphic, artwork, name, photograph, portrait, picture or illusion of any person or any other intellectual property rests with you. You are solely responsible for obtaining proper permissions and licenses, except as may be permitted by copyright law, from third parties whose rights may be violated or infringed by the use of any material protected by copyright, trademark, service mark or other proprietary rights. You will not use any logo or trademark in a vulgar, disparaging or otherwise unlawful manner. We assume no responsibility for damages or any wrongdoing that you may cause using a logo, trademark or copyrighted items.</div>

        `
        }
                                                                                        

]);
const [searchable,Setsearchable]= useState({
    data: faqObject,
    value: ''
  })
 const handleChange = event => {
    Setsearchable({...searchable,value:event.target.value});
  };
  function createMarkup(html) {
    return {__html: html};
  }

    return (
        <React.Fragment>
            <Header />
                {open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
                setOpen(false);
            }} /> : null}
            <div className="faq-container mt-4">
                <div className="container">
                    <div className="position-relative mt-2 d-flex ">                   
                      
                        <input type="text" placeholder="Search" className="ml-auto" value={searchable.value} onChange={(e) => handleChange(e)} />
                    </div>


                  
                        <FilterResults
                        value={searchable.value}
                        data={searchable.data}
                        renderResults={results => (                            
                            <div>
                            {results.map(el => ( 
                               
                                
                                <React.Fragment>
                                    {el.type == "heading" ?
                                    <div className="logo_header position-relative mt-3 mb-3 d-flex ">

                                    <h4>{el.question}</h4>
                                    </div>:
                                    <Accordion>
                                <AccordionSummary
                                expandIcon={<i class="fas fa-chevron-down"></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography><strong>{el.question}</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                <div dangerouslySetInnerHTML={createMarkup(el.answer)}></div>
                                
                                </Typography>
                                </AccordionDetails>
                            </Accordion> }
                           

                
                                </React.Fragment>                            
                                                       
                            ))}
                            </div>
                        )}
                        />
                </div>
            </div>


            
        </React.Fragment>
    )
}

export default FAQList;