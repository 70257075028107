import Login from "./components/login";
import CancelPayment from "./pages/cancelPayment";
import Cart from "./pages/cart";
import ConfiguratorMob from "./pages/configurator_mob";
import Exchange from "./pages/exchange";
import FAQList from "./pages/faq";
import ForgotPassword from "./pages/forgotpassword";
import Home from "./pages/home";
import HowItWorks from "./pages/howitworks";
import InvoicePreview from "./pages/invoice";
import OrderPrint from "./pages/invoice_old";
import MyOrder from "./pages/myorder";
import Policy from "./pages/policy";
import PreviewProduct from "./pages/preview";
import MyProfile from "./pages/profile";
import Shipping from "./pages/shipping";
import Terms from "./pages/terms";
import ThankYou from "./pages/thankyou";
import WhyGo4M from "./pages/whyGo";


const routes = [
    {
        path:'/login',
        component:Login
    },
    {
        path:'/forgot_password',
        component:ForgotPassword
    },
    {
        path:'/home',
        component:Home
    },
    {
        path:'/shipping',
        component:Shipping
    },
    {
        path:'/exchange',
        component:Exchange
    },
    {
        path:'/terms',
        component:Terms
    },
    {
        path:'/policy',
        component:Policy
    },
    {
        path:'/whyGo4M',
        component:WhyGo4M
    },
    {
        path:'/preview/:item_id',
        component:PreviewProduct
    },
    {
        path:'/profile',
        component:MyProfile
    },
    {
        path:'/cart',
        component:Cart
    },
    {
        path:'/order',
        component:MyOrder
    },
    // {
    //     path:'/invoice',
    //     component:OrderPrint
    // },
    {
        path:'/invoice',
        component:InvoicePreview
    },
    {
        path:'/faq',
        component:FAQList
    },
    {
        path:'/configurator',
        component:ConfiguratorMob
    },
    {
        path:'/thankyou',
        component:ThankYou
    },
    {
        path:'/cancel',
        component:CancelPayment
    },
    {
        path:'/howitworks',
        component:HowItWorks
    },
    
];

export default routes; 