import React from 'react';
import BreadCrumb from '../components/breadcrumbs';
import Footer from '../components/footer';
import Header from '../components/header';

const Policy = props => {
    return(
            <React.Fragment>
                <Header />
                <BreadCrumb current="Privacy Policy" />
            <div className="container py-5">
            <p>Effective October 15, 2020</p>
	<p>Welcome to <a href="go4merchandise.com.">go4merchandise.com.</a>, official customised apparel shopping website maintained by Brij
Fashion India Pvt. Ltd. This Privacy Policy, references to ‘you’, ‘your’, and ‘them’ means any
person submitting any data to us or to our agent or the site. By accessing or using this website
from any digital device, you consent to our privacy policy.<a href="go4merchandise.com.">go4merchandise.com.</a> reserves the right
to amend this privacy policy at any time effective upon its posting, without any prior notice to you.
</p>
<h4>INFORMATION COLLECTION</h4>
<p>We collect your information when you use or purchase of <a href="go4merchandise.com.">go4merchandise.com.</a> product and
service. You have the option not to provide any information but then you many not be able to take
advantage of certain features and services. By using our services, you agree to consent to the use,
disclosure and transfer of your personal information in accordance with this privacy policy and as
permitted an required by law. However, if you change your mind and wish to withdraw your consent
for the use, disclosure and transfer of your personal information, send us an email at
info@go4merchandise.com</p>
<h4>We Collect Your Personal Information in the Following Ways:</h4>
<p>
	I.  When you sign up a go4merchandise account, we will collect your name, username, email
address, company’s name, company’s GST number, Company PAN Number. At the time of any
purchase of product and service at <a href="go4merchandise.com.">go4merchandise.com.</a>, we may collect your address,
postcode and certain other information to fit your needs.<br/>
II.  When you connect/ join with us through any social media account, we may collect your
personal data that you may have provided to that social networking site service that is
permitted under its Terms of Use.<br/>
III.  When we provide you access to Third Party products and services.<br/>
IV.  When you engage with out online communities, we may collect your personal data about those
meetings. This includes when you leave review, click on advertisements, chat with our
customer support service, participated or joined our online community, blogs, and others that
are affiliated with <a href="go4merchandise.com.">go4merchandise.com.</a><br/><br/>
</p>
<h4>We Use Your Personal Information In The Following Ways:</h4>
<p>
	I.  To enhance and personalise user experience.<br/>
II.  To support research and academic research on demographics and psychographics to gain
insight about our customers, products and services used by them and to further innovate and
come up with better products and services for them. This analysis may be leveraged and
shared outside of <a href="go4merchandise.com.">go4merchandise.com.</a><br/>
III.  To address any security issues and resolve disputes if arisen in any given circumstances.<br/>
IV.  To communicate with you via email, newsletters, text messages about go4merchandise.com. In
such cases, we may use your personal data to communicate with you.<br/><br/>
</p>

<h4>DISCLOSURE OF PERSONAL DATA</h4>
<p> <a href="go4merchandise.com.">go4merchandise.com.</a> will disclose your personal information to its subsidiaries and to the third
parties whom  <a href="go4merchandise.com.">go4merchandise.com.</a> hires to provide products and services on
 <a href="go4merchandise.com.">go4merchandise.com.</a> behalf to you so as to complete a transaction or provide a product or service
that you have requested. The access to your personal data will only be to the extent it is necessary
for them to provide their products and services to us or as requested by you.  <a href="go4merchandise.com.">go4merchandise.com.</a>
may also disclose your personal information of required by law</p>

<h4>SECURITY</h4>
<p><a href="go4merchandise.com.">go4merchandise.com.</a> takes reasonable precautions and follow best industry practises to make
sure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed</p>

<h4>PAYMENT</h4>
<p>If you provide us with your credit card information, the information is encrypted using Secure
Socket Layer Technology (SSL) and stored with AES 256 encryption.<br/><br/>
go4merchandise.com is committed to protect your information through the use of physical and
technological procedures. Although no method of transmission over the internet or electronic
storage is 100% secure. Therefore, we recommend you to take additional security measures to
safeguard you and your personal information by installing anti-virus software, keeping your log-in
details confidential and by making sure that you regularly update your software’s and apps on your
devices.</p>

<h4>CHILDREN</h4>
<p><a href="go4merchandise.com.">go4merchandise.com.</a> does not collect any personal information of you unless you choose to
provide it. We do not collect any information from children below 13. If any such information is
provided, it will be considered that age of majority in your state have us your consent to allow any
minor below 13 dependent to use this site.
</p>

<h4>THIRD PARTIES</h4>
<p>Any Third Party providers used by us will collect, use and disclose your information to the extent
necessary to allow them to perform the services they provide to us.<br/>
In case of third party such as payment gateways, they have their own privacy policy in respect to
the information they collect and provided by us to them for any purchase related transaction. For
better understanding, we recommend you to read their privacy policy in relation to your personal
information handled by these third party.</p>

<h4>QUESTIONS AND CONTACT INFORMATION</h4>
<p>If you would like to access, correct, amend, or delete any personal information we have about you,
register a complaint or want more information, simply contact our Privacy Policy Officer at
.<a href="info@go4merchandise.com.">info@go4merchandise.com.</a></p>


            </div>
            <div className="container-fluid">
                <Footer />
            </div>
            </React.Fragment>
    )
}

export default Policy;