import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';

const ConfirmationDialog = (props) => {
    const { show, message, type , onOkClick, onClose} = props;
    const [openDialog , setOpenDialog] = useState(false);

    useEffect(() => {
        setOpenDialog(show);
    },[props]);

    const handleClose = () => {
        setOpenDialog(false);
        onClose();
    }

    return(
        <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" >{"Message from Webpage"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                {"Cancel"}
            </Button>
            {type === "alert" ? null : <Button onClick={onOkClick} color="primary" autoFocus>
                {"Ok"}
            </Button>}
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;