import { Divider } from '@material-ui/core';
import React, { useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Login from '../components/login';
import Step1 from '../assets/images/step1.jpg';
import Step2 from '../assets/images/step2.jpg';
import Step3 from '../assets/images/step3.jpg';
import Step4 from '../assets/images/step4.jpg';
import Step5 from '../assets/images/step5.jpg';
import Step6 from '../assets/images/step6.jpg';
import Step7 from '../assets/images/step7.jpg';
import Step8 from '../assets/images/step8.jpg';
import Step9 from '../assets/images/step9.jpg';
import Step10 from '../assets/images/step10.jpg';
import Step11 from '../assets/images/step11.jpg';
import {Helmet} from "react-helmet";


const HowItWorks = (props) => {
    const [ open , setOpen ] = useState(false);
    return(
        <React.Fragment>
            <Helmet>
                <title>Go4Merchandise | How it works | Customise T-Shirts and Caps</title>
				<meta name="description" content="Create an account , explore and choose product, choose fabric,choose color, upload logo, choose contrast element, Add Order Quantity, Order Preview and Make Payment" />
			</Helmet>
             <Header />
                {open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
                setOpen(false);
            }} /> : null}
            <div className="howitworks_main mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto">
                            <div className="howitworks_main_header">
                                <h3 className="text-uppercase text-center">how it works</h3>
                                <Divider />
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ firstChild mt-5 pb-5">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step1} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>1</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Create an Account </h3>
                                    <p>To sign up for Go4M, create an account using our basic details. 
                                        For quick sign-up, you may continue signing in using Google or 
                                        Face-book.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center flex-row-reverse">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step2} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>2</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Explore & Choose Product </h3>
                                    <p>Explore your product range at the homepage & choose the right 
                                        product that suits your requirement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step3} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>3</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Choose Fabric </h3>
                                    <p>Start the customization by choosing the fabric from the left side of 
                                    the customization panel.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center flex-row-reverse">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step4} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>4</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Choose Color</h3>
                                    <p>Choose the color option that goes well with your brand aesthetics.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step5} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>5</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Upload Logo/ Add text </h3>
                                    <p>Upload your brand logo. There are four different placements to 
                                        add your logo or any text. </p>
                                    <p>For best results, upload a PNG file without background.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center flex-row-reverse">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step6} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>6</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Choose Contrast Elements  </h3>
                                    <p>You can choose to add contrast design element that goes well with 
                                    your brand aesthetics.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5  order-2">
                                <div className="step_image">
                                    <img src={Step7} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>7</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Add Order Quantity </h3>
                                    <p>Add size-wise quantity at the right side of customization page.
                                    After adding the sizes, click proceed to finish your design.</p>
                                    <p>Please check your design & sizes before clicking the proceed 
                                    button as your design will be finalized & will be non-editable. You 
                                    may place an order with us for 50 to 500pcs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center flex-row-reverse">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step8} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>8</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Order Preview </h3>
                                    <p>Preview your order-design,style & sizes and move to cart for 
                                    checkout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step9} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>9</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Make Payment </h3>
                                    <p>Make payment for your order through secure payment methods to 
                                        complete your order through debit/credit card, net banking, or 
                                        wallets.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ ">
                        <div className="row d-flex align-items-center flex-row-reverse">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step10} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>10</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Design & Logo Verification </h3>
                                    <p>Once your order is placed, our Go4M team will review your logo & 
                                    design and a verification email for approval of design will be sent 
                                    to your registered email with 24-48 hours of order confirmation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step_sections_ pb-5">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 order-2 order-md-1">
                                <div className="step_image">
                                    <img src={Step11} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-2 order-1 order-md-2">
                                <div className="step_count"><span>11</span></div>
                            </div>
                            <div className="col-md-5 order-3">
                                <div className="step_image">
                                    <h3>Delivery </h3>
                                    <p>With the promise of quality & commitment, your order will be 
                                    processed as per your design after approval & will be delivered to 
                                    you within 14-21 working days.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                    <Footer />
                </div>
        </React.Fragment>
    )
}

export default HowItWorks;