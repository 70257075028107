import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Loading = (props) => {
    return <div className="loading">
        <CircularProgress />
        <h4 className="ml-3">{props.message}</h4>
    </div>
}

export default Loading;