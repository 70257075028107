import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';  
import Home from './pages/home';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import routes from './routes';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#f36f21',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#f36f21',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      'Verdana', 
      'sans-serif'
    ].join(',')
  } 
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
            {routes.map((route,index) => {
              return <Route key={index} path={route.path} component={route.component} exact />
            })}
            <Redirect from="/" to="/home" />
        </Switch>
    </BrowserRouter>
    </ThemeProvider>  
  );
}

export default App;
