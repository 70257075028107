import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import logo from '../assets/img/Go4.png'
import Slide from '@material-ui/core/Slide';
import {ButtonToggle}  from 'reactstrap';
import { Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, TextField} from '@material-ui/core';
 
import google from '../assets/img/google.png';
import facebook from '../assets/img/facebook.png';
import SimpleReactValidator from 'simple-react-validator';
import useForceUpdate from 'use-force-update';
import OtpInput from 'react-otp-input';
import { LoginController } from '../controllers/login.controller';
 
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Login = (props) => {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [login,setLogin] = useState(true);
    const [alertUtil,setUtil] = useState(false);
    const [message,setMessage] = useState('');
    const [loading,setLoading] = useState(false);
    const [validate,setValidate] = useState(new SimpleReactValidator());
    const [numOtp,setNumOtp] = useState('');
    const [checkEmail,setEmail] = useState(true);
    const [checkMobile,setMobile] = useState(true);
    const [progress , setProgress] = useState(60);
    const [dialogFull,setDialogFull] = useState(false)

    const [loginParam,setLoginParam] = useState({
        email:'',
        password:null
    });
    
    

    const [confirmPassword,setConfirmPassword] = useState('');
    const [validatePassword,setValidatePassword] = useState(false);
    const [tempId ,setTempId] = useState(null);
    const [showPassword,setShowPassword] = useState(false);
    const [registerParam,setRegisterParam] = useState({
        customer_id:'0',       
        first_name:'',
        last_name:'',
        email:'',
        signup_by:'form',
        attachment:'',
        access_token:'',
        mobile:'',
        newsletter: false
    });

    const [validateOtp,setValidateOtp] = useState(true);
    const [otp,setOtp] = useState(false);

    const forceUpdate = useForceUpdate();

    const handleChange = (e) => {
        setLoginParam({...loginParam,
        [e.target.name]:e.target.value})
    }

    const handleRegisterChange = (e) => {

        if(e.target.type == "checkbox")
        {
            setRegisterParam({...registerParam,
                [e.target.name]:e.target.checked})
        }
        else
        {
            setRegisterParam({...registerParam,
                [e.target.name]:e.target.value})
        }
       

        console.log("e.target.type");
        
        console.log(e.target.type);
        
    };

    useEffect(() => {
        if(window.innerWidth < 600){
            setDialogFull(true);
        }
    },[])

    useEffect(() => {   
        setOpen(props.show);
    },[props]);

    const checkLogin =() => {
        setLoading(true);
        LoginController.userLogin(loginParam).then(data => {
            console.log(data)
            if (data.valid) {
                if(data.mobile_verify != "False"){
                    localStorage.setItem("login", true);
                    localStorage.setItem("customer_id", data.customer_id);
                    localStorage.setItem("username", data.first_name + " " + data.last_name);
                    // localStorage.setItem("email", data.email);
                    // localStorage.setItem("mobile", data.mobile);
                    localStorage.setItem("profile", data.image_path);
                    history.go(0)
                }
                else{
                    setOtp(true);
                    setRegisterParam({...registerParam,mobile:data.mobile});
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
                // setUtil(true);
                setMessage("Username or password is incorrect");
            }
        });
    }

    

    const checkOtp = () => {
        setLoading(true)
        const param = {
            mobile:registerParam.mobile,
            otp:numOtp
        }
        LoginController.otpVerification(param).then(data => {
            console.log(data)
            if(data.status == "true"){
                localStorage.setItem("login", true);
                localStorage.setItem("username", data.first_name + " " + data.last_name);
                localStorage.setItem("customer_id", data.customer_id);
                localStorage.setItem("profile", data.image_path);
                history.go(0)
            }
            else{
                setValidateOtp(false);
                setLoading(false)
            }
        })
    }


    useEffect(() => {
        otpCounter()
    },[otp]);

    const otpCounter = () => {
        if(otp){
            var i = setInterval(() => {
                if(progress < 1){
                    clearInterval(i);
                }
                setProgress((previous) => previous - 1);
            }, 1000);
        }
    }

    



    const signup = () => {
        setLoading(true);
        LoginController.userSignUp(registerParam).then(data => {
            if (data.valid) {
                setTempId(data.customer_id);
                setOtp(true);
                setLoading(false);
            }
            else {
                setMessage("Username or password is incorrect");
            }
        });
    }
    
  
   

    const responseGoogle = (response) => {   
        console.log(response)
        var googleResponse = response.profileObj; 
        if(!(response.profileObj === undefined))
        {
          setLoading(true)
          const param = {
            customer_id: "0",
            first_name: googleResponse.givenName,
            last_name: googleResponse.familyName,
            email: googleResponse.email,
            mobile: "",
            access_token:"",
            signup_by: "Google",
            attachment: googleResponse.imageUrl,
            newsletter:false,
          }
          
          LoginController.userSignUp(param).then(data => {
            if (data.valid) {
                setTempId(data.customer_id);
                setValidatePassword(false);
                if(data.mobile_verify == "False" && data.mobile == ""){
                    setRegisterParam({
                        ...registerParam,
                            first_name: data.first_name,
                            last_name: data.last_name,
                            email: data.email,
                            mobile: data.mobile,
                            access_token:"",
                            signup_by: "Google",
                            attachment:data.image_path
                      });
                      setLogin(false);
                }
                else if(data.mobile_verify == "False" && data.mobile != ""){
                    setTempId(data.customer_id);
                    setRegisterParam({...registerParam,mobile:data.mobile})
                    setOtp(true);
                    setLoading(false);   
                }
                else{
                    localStorage.setItem("login", true);
                    localStorage.setItem("username", data.first_name + " " + data.last_name);
                    localStorage.setItem("customer_id", data.customer_id);
                    localStorage.setItem("profile", data.image_path);
                    history.go(0)
                }
                setLoading(false);
              }
              else {
                console.log(data.msg)
              }
          })
        }
        
      }

      const responseFacebook = (response) => {
          var facebookResponse = response;
          if((response.status !== "unknown"))
          {
            
          const param = {
            customer_id: "0",
            first_name: facebookResponse.name,
            last_name: "",
            email: facebookResponse.email,
            mobile: "",
            access_token: facebookResponse.access_token,
            signup_by: "facebook",
            attachment: facebookResponse.picture.data.url,
            newsletter:false,
          }
          
          LoginController.userSignUp(param).then(data => {
             if (data.valid) {
                    if(data.mobile_verify == "False" && data.mobile == ""){
                        setValidatePassword(false);
                        setTempId(data.customer_id);
                        setRegisterParam({
                            ...registerParam,
                            first_name: facebookResponse.name,
                            last_name: "",
                            email: facebookResponse.email,
                            mobile: "",
                            access_token:"",
                            signup_by: "facebook",
                            attachment: facebookResponse.picture.data.url
                        });
                        setLogin(false);
                    }
                    else if(data.mobile_verify == "False" && data.mobile != ""){
                        setTempId(data.customer_id);
                        setOtp(true);
                        setLoading(false);   
                    }
                    else{
                        localStorage.setItem("login", true);
                        localStorage.setItem("username", data.first_name + " " + data.last_name);
                        localStorage.setItem("customer_id", data.customer_id);
                        localStorage.setItem("profile", data.image_path);
                        history.go(0)
                    }
                    setLoading(false);
              }
              else {
                console.log(data.msg);
              }
          })
        }
        }
 

          const resendOTP = () => {
              LoginController.resendOTp({customer_id:tempId}).then(data => {
                  if(data.valid){
                     setProgress(60);
                     otpCounter();
                  }
              });
          }

          const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
          };
        
          const handleMouseDownPassword = (event) => {
            event.preventDefault();
          };

    return(
        <React.Fragment>
             <Dialog fullScreen={dialogFull} className="login-dialog"
                open={true}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent> 
                    <button className="btn btn-link close" onClick={() => props.onHide()}> <i className="fas fa-times"></i></button>
                <div className="login_panel px-md-4">
                    <div className="login_logo d-flex">
                        <img src={logo} alt="" />
                       
                    </div>
                    <div className="logo_header position-relative mt-2 mx-3 mx-md-0">
                        <h4 style={{fontSize:otp ? 13 : 20, fontWeight:otp ? '600' : '400'}}>{otp ? <React.Fragment>We just sent a verfication code to your registered mobile number <span className="text-muted">+91-{registerParam.mobile}</span></React.Fragment> : login ? "LOG IN" : "Sign Up"}</h4>
                        {otp ? null : <p>{login ? "New User?" : "Already have an account?"} <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setLogin(!login);
                        }}>{login ? "Create Account" : "Log In"}</a></p>}
                    </div>
                    {!otp ? <div className="login_content mt-4">
                        {login ?  <React.Fragment>
                            {/* {alertUtil ? <PopAlert message={message} type="danger" /> : null} */}
                         <TextField fullWidth InputLabelProps={{
                            className:"login_labels"
                            
                        }} id="standard-basic" error={validate.message('Email', loginParam.email , 'required|email') || (message != "")} helperText={validate.message('Email', loginParam.email , 'required|email') || message} onChange={handleChange} name="email" label="Email" />
                        
                         <TextField
                          fullWidth 
                          className="mt-3"
                          InputLabelProps={{
                            className:"login_labels"
                        }}
                        type="password"
                        id="standard-basic"
                        onChange={handleChange}
                        name="password"
                        error= {validate.message('Password', loginParam.password , 'required')}
                        label="Password" 
                        helperText={validate.message('Password', loginParam.password , 'required')}
                        />
                        
                        <a href="/forgot_password" className="text-right d-block mt-2 font_13">Forgot Password?</a>
                       

                        <ButtonToggle disabled={loading}  className="btn-theme-outlined-animate btn font_13 px-4 mt-2 d-block mx-auto" onClick={() => {
                             if (validate.allValid()) {
                                checkLogin();
                              } else {
                                validate.showMessages();
                                forceUpdate();
                                // rerender to show messages for the first time
                                // you can use the autoForceUpdate option to do this automatically`
                                 
                              }
                        }}>{loading ? <CircularProgress style={{width:15,height:15}} /> : null} Continue</ButtonToggle>
                         </React.Fragment> : 
                         <React.Fragment>
                             <div className="row">
                                 <div className="col-md-6">
                                    <TextField
                                        fullWidth 
                                        className="mb-2"
                                        InputLabelProps={{
                                            className:"login_labels"
                                        }}
                                        type="text"
                                        id="standard-basic"
                                        value={registerParam.first_name || ""}
                                        onChange={handleRegisterChange}
                                        name="first_name"
                                        error= {validate.message('First Name', registerParam.first_name , 'required')}
                                        helperText={validate.message('First Name', registerParam.first_name , 'required')}
                                        label="First Name" />
                                 </div>
                                 <div className="col-md-6">
                                    <TextField
                                        fullWidth 
                                        className="mb-2"
                                        InputLabelProps={{
                                            className:"login_labels"
                                        }}
                                        type="text"
                                        value={registerParam.last_name  || ""}
                                        id="standard-basic"
                                        onChange={handleRegisterChange}
                                        name="last_name"
                                        error= {validate.message('Last Name', registerParam.last_name , 'required')}
                                        helperText={validate.message('Last Name', registerParam.last_name , 'required')}
                                        label="Last Name" />
                                 </div>
                                 <div className="col-md-12">
                                    <TextField
                                        fullWidth 
                                        className="mb-2"
                                        value={registerParam.email || ""}
                                        InputLabelProps={{
                                            className:"login_labels",
                                            endAdornment: <InputAdornment position="end"><CircularProgress style={{width:15,height:15}} /></InputAdornment>
                                        }}
                                        type="text"
                                        id="standard-basic"
                                        onChange={handleRegisterChange}
                                        name="email"
                                        onBlur={(e) => {
                                            setLoading(true);
                                            LoginController.checkEmail({email:e.target.value}).then(data => {
                                                setEmail(data);
                                                setLoading(false);
                                            });
                                        }}
                                        disabled={!(registerParam.signup_by == "form")}
                                        error= {validate.message('Email', registerParam.email , 'required|email') || (checkEmail == "true")}
                                        helperText={validate.message('Email', registerParam.email , 'required|email') || (checkEmail == "true" ? "Email already registered" : "")}
                                        
                                        label="Email" />
                                        
                                 </div>
                                {registerParam.signup_by == "form" ? <React.Fragment>
                                <div className="col-md-12">
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                        <Input
                                            id="standard-adornment-password"
                                            className="mb-2"
                                            type={showPassword ? 'text' : 'password'}
                                            value={registerParam.access_token}                                             
                                            onChange={handleRegisterChange}
                                            name="access_token"
                                            error= {validate.message('Password', registerParam.access_token , 'required')}
                                            helperText={validate.message('Password', registerParam.access_token , 'required')}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {showPassword ? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                        />
                                        </FormControl>
                                  
                                 </div>
                                 <div className="col-md-12">
                                    <TextField
                                        fullWidth 
                                        className="mb-2"
                                        InputLabelProps={{
                                            className:"login_labels"
                                        }}
                                        type="password"
                                        id="standard-basic"
                                        
                                        onChange={(e) => {
                                            if(registerParam.access_token  !== e.target.value){
                                                setValidatePassword(true);
                                                setConfirmPassword("Password not matched")
                                            }   
                                            else{
                                                setValidatePassword(false);
                                                setConfirmPassword("");
                                            }
                                        }}
                                        name="confirmPassword"
                                        helperText={confirmPassword}
                                        error={validatePassword}
                                        label="Confirm Password" />
                                        
                                 </div>
                               
                                </React.Fragment> : null}
                                 <div className="col-md-12">
                                    <TextField
                                        fullWidth 
                                        className="mb-2"
                                        value={registerParam.mobile || ""}
                                        InputLabelProps={{
                                            className:"login_labels"
                                        }}
                                        type="text"
                                        id="standard-basic"
                                        onChange={handleRegisterChange}
                                        placeholder={"Enter 10 Digit Mobile Number"}
                                        onBlur={(e) => {
                                            setLoading(true)
                                            LoginController.checkMobile({mobile:e.target.value}).then(data => {
                                                setLoading(false);
                                                setMobile(data);
                                            });
                                        }}
                                        name="mobile"
                                        error={validate.message('Mobile', registerParam.mobile , 'required|numeric|min:10|max:10') || (checkMobile == "true")}
                                        
                                        helperText={validate.message('Mobile', registerParam.mobile , 'required|numeric|min:10|max:10') || (checkMobile == "true" ? "Mobile No. already registered" : "")}
                                        label="Mobile" />
                                 </div>
                                 <div className="col-md-12 mt-1 font_13 mb-3">
                                 Subscribe for Promotional email/newsletters <input type="checkbox" className="ml-2" name="newsletter"  onChange={ handleRegisterChange}  id="standard-basic" />
                                 </div>
                                 <div className="col-md-12">
                                     <button disabled={(checkEmail == "true") || loading || (checkMobile == "true")} className="btn-theme-outlined-animate btn font_14 px-4 mt-2 d-block mx-auto"  onClick={() => {
                                         if(registerParam.signup_by == "form"){
                                            console.log("form true");
                                            console.log(validatePassword);
                                            if (validate.allValid() && !validatePassword) {
                                                signup();
                                            } else {
                                                validate.showMessages();
                                                forceUpdate();
                                                // rerender to show messages for the first time
                                                // you can use the autoForceUpdate option to do this automatically`
                                            }
                                         }
                                         else{
                                             if(validate.fieldValid('First Name') && validate.fieldValid('Last Name') && validate.fieldValid('Email') && validate.fieldValid('Mobile')){
                                                signup();
                                             }
                                             else{
                                                validate.showMessages();
                                                forceUpdate();
                                             }
                                         }
                                         
                                     }} type="button">{loading ? <CircularProgress style={{width:15,height:15}} /> : null} Next</button>
                                 </div>
                             </div>
                        </React.Fragment>}
                        <p className="divider position-relative text-center font_13"><span>Or</span></p>
                        <GoogleLogin
                            clientId="1073639551262-t261t88k1uvprga63d8jjsskofq5fg3v.apps.googleusercontent.com"
                            render={renderProps => (
                                <ButtonToggle className="px-5 google_btn mx-auto mb-2 d-block font_13"  onClick={renderProps.onClick}><img src={google} />Continue With Google</ButtonToggle>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                             />
                              <FacebookLogin
                                appId="1519818934885574"
                                // autoLoad={true}  
                                fields="name,email,picture"
                                // onClick={componentClicked}
                                callback={responseFacebook}
                                cssClass={"px-5 facebook_btn mx-auto mb-2 d-block font_13 btn"}
                                render={renderProps => (
                                        <ButtonToggle className="px-5 facebook_btn mx-auto mb-2 d-block font_13" onClick={renderProps.onClick}><img src={facebook} />Continue With Facebook</ButtonToggle>
                                )} />   
                        <p className="text-center text-muted mt-3 font_13">I agree to <a href="/policy">Privacy Policy</a> and <a href="/terms">Terms & Conditions</a></p>
                    </div> : 
                    <div className="otp_content text-center justify-content-center my-4">
                        <label className="text-muted">Enter Code</label>
                        <OtpInput
                            value={numOtp}
                            onChange={(otp) => setNumOtp(otp)}
                            numInputs={6}
                            containerStyle="justify-content-center"
                            separator={<span>-</span>}
                            isInputNum={true}
                            />
                             
                            {validateOtp ? null : <p className="m-0 font_13 text-danger">Incorrect OTP</p>}
                            {validate.message('Otp', numOtp , 'numeric|min:6|max:6')}
                            <ButtonToggle disabled={loading}  className="btn-theme-outlined btn font_1vw px-4 mt-3 d-block mx-auto" onClick={() => {
                             if (validate.fieldValid('Otp')) {
                                checkOtp();
                              } else {
                                validate.showMessages();
                                forceUpdate();
                                // rerender to show messages for the first time
                                // you can use the autoForceUpdate option to do this automatically`
                                 
                              }
                        }}>{loading ? <CircularProgress style={{width:15,height:15}} /> : null} Submit</ButtonToggle>
                        {progress < 1 ? <Button className="font_13 " color="primary" onClick={resendOTP}>Resend OTP</Button> : <p className="mb-0 mt-3 font_13">Waiting Time:{progress} secs</p>}
                        

                            {/* <button className="btn btn-theme-outlined px-4 mt-4 font_13" onClick={}></button> */}
                           
                    </div>
                }
                </div>
               {otp ? <div className="d-flex">
                    {/* <p className="font_13 m-0">Didn't receive the code? <button className="btn font_13 text-theme-color btn-link" disabled={!(progress < 0)}>Resend</button></p> */}
                    {/* <a href="#" onClick={(e) => {
                        e.preventDefault();
                        // resendOTP();
                    }} className="ml-auto font_13">Back</a> */}
                </div> : null}
                </DialogContent>                        
            </Dialog>

              {/* <div className="login_panel">
                    <div className="login_logo">
                        <img src={require("../assets/img/Go4.png")} />
                    </div>
                </div> */}
        </React.Fragment>
    )
}

export default Login;