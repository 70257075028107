import React from 'react';
import BreadCrumb from '../components/breadcrumbs';
import Footer from '../components/footer';
import Header from '../components/header';

const Terms = props => {
    return(
        <React.Fragment>
            <Header />
            <BreadCrumb current="Terms & Conditions" />
<div className="container py-5">
   <h4>ACCEPTANCE OF TERMS OF USE </h4>
   <p>Please read the following Terms of Use (“Terms”) carefully before using our website www.go4merchandise.com. These Terms of Use govern your access and use of this site developed and maintained by Go4Merchandise.com. <br/><br/>
      By accessing or using this site, you conﬁrm that you create a legally binding agreement between you and Go4M and its afﬁliates (which we may refer to as “Go4M”, “Go4Merchandise.com”, “Brij Fashion India Pvt. Ltd”, “we”, “us”, or “our”) bound by these Terms of Use and to any additional guidelines, restrictions or rules that may be posted in connection with speciﬁc sections or services of this site.<br/><br/>
      We may update these Terms from time to time. Read through any changes, and if you don't agree to them, please stop using the site. If you continue to use our site after we notify you of changes, you will be deemed to have accepted the updated Terms.
   </p>
   <p> <span>PRIVACY POLICY: </span> Our Privacy Policy describes the collection and use of personal information on the Site and applies to your use of the Site.</p>
   <h4>USE OF SITE </h4>
   <p>You are granted permission to access and use this Site and its content for the sole purpose of preparing, evaluating and ordering products or services. </p>
   <h4>OWNERSHIP OF CONTENT
   </h4>
   <p>All of the content on our Site, (except for User Content) - including texts, software, scripts, code, designs, graphics, photos, sounds, videos, applications, interactive features, articles, news and general artwork and other content is owned by Brij Fashion India Pvt. Ltd. or others we license content from, and is protected by copyright, trademark, and other laws. <br/><br/>
      • All trademarks, service marks are owned, registered and/ or licensed by Go4Merchandise.com. You do not acquire a license or any ownership rights to any trademarks, service marks through your access or use of the Site or Content.<br/>
      • To the extent, we approve the download or use of Content comprised of Copyrights or Copyrightable works limited to personal, non-transferable access and use such copyrights or copyrightable works solely for their intended purpose with prior written permission from us. You do not acquire any ownership rights in the Content and all such Content is intended for personal, non-commercial use. <br/>
      • You agree not to use, copy, edit translate, display, distribute, download, transmit sell, create derivative works of, or in anyway exploit any Content without <a href="https://go4merchandise.com">go4merchandise.com.</a> prior written consent.<br/>
      • Unauthorised use of the Content may constitute a breach of copyright, trademark or other intellectual property laws and may subject you to criminal or civil charges and penalties
   </p>
   <h4>PARTNERS - THIRD PARTIES</h4>
   <p><a href="https://go4merchandise.com">go4merchandise.com.</a> may link to or partner with third parties (“Third Parties”) at any point of time. You may be able to connect with these Third Parties on the Site, but that does not mean <a href="https://go4merchandise.com">go4merchandise.com.</a> endorses, monitors or has any control over these Third Parties and their activities. You should review Third Parties Sites and Terms of Use and Privacy Policy carefully. 
   </p>
   <h4>INDEMNIFICATION</h4>
   <p>You agree to indemnify defend and hold harmless <a href="https://go4merchandise.com">go4merchandise.com.</a>, and its afﬁliates, ofﬁcers, directors, employees, agents, licensors and suppliers from and against all claims, losses, liabilities, expenses, damages and costs, including reasonable attorney’s fee made by any Third Parties due to or arising from or relating in any way to your use of Site, your conduct in connection with the Site or any breach of these Terms of Use or documents they incorporate by reference.
   </p>
   <section id="Use-of-logo-and-image"></section>
   <h4>LIMITATION OF LIABILITY</h4>
   <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely secure or error-free.<br/> You agree that from time to time we may remove the products or services for indeﬁnite periods of time or cancel at any time without any prior notice to you. You expressly agree that your use of, or inability to use the service is at your sole risk. All products and services delivered to you through the Site are provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchant quality, ﬁtness for a particular purpose, durability, title, and non infringement. In no case <a href="https://go4merchandise.com">go4merchandise.com.</a> and parent, afﬁliates, subsidiaries, partners, ofﬁcers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees will be liable for any injury, loss, claim, or any direct, indirect, punitive, special, or consequential damages, whether based in contract, strict liability or otherwise, arising from your use of any other claim related in any way to your use of the service or any product, including, but not limited to any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Some states do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdiction our liability shall be limited to the maximum extent permitted by law.</p>
   <h4 id="Use-of-logo-and-image">USER PROVIDED LOGO AND IMAGE</h4>
   <p>You agree, represent and warrant to <a href="https://go4merchandise.com">go4merchandise.com.</a> that you have the right to use, copy and distribute each copyright, copyrightable works, trademarks, service marks, trade name, logo, statement, tagline, graphic, artwork, name, photograph, portrait, picture or illusion of any person or any other intellectual property in the way that is to included (embroidered/ printed or applied) on to the product ordered by you on <a href="https://go4merchandise.com">go4merchandise.com.</a> <br/>
      You agree and warrant that in obtaining and using the material submitted to <a href="https://go4merchandise.com">go4merchandise.com.</a>, you have not violated and breached the property rights of any third party. You agree to indemnify defend and hold harmless <a href="https://go4merchandise.com">go4merchandise.com.</a> and its afﬁliates, ofﬁcers, directors, employees, agents, licensors and suppliers from and against all claims, losses, liabilities, expenses, damages and costs, including reasonable attorney’s fee made by any Third Parties due to or arising from or relating in any way to your use of Site, your conduct in connection with the Site.<br/><br/>
      The right of determining the use of not protected copyright, copyrightable works, trademarks, service marks, trade name, logo, statement, tagline, graphic, artwork, name, photograph, portrait, picture or illusion of any person or any other intellectual property rests with you. You are solely responsible for obtaining proper permissions and licenses, except as may be permitted by copyright law, from third parties whose rights may be violated or infringed by the use of any material protected by copyright, trademark, service mark or other proprietary rights. You will not use any logo or trademark in a vulgar, disparaging or otherwise unlawful manner. We assume no responsibility for damages or any wrongdoing that you may cause using a logo, trademark or copyrighted items.
   </p>
   <h4>APPLICABLE LAW </h4>
   <p>Terms of Use shall be governed by and interpreted and construed in accordance with the laws in India. The place of jurisdiction shall be exclusively be in New Delhi.</p>
   <h4>CONTACT INFORMATION </h4>
   <p>Any further questions regarding Terms of Use shall be sent to us at  <a href="mailto:info@go4merchandise.com">info@go4merchandise.com</a></p>
</div>
<div className="container-fluid">
    <Footer />
</div>
        </React.Fragment>
    )
}

export default Terms;