import React from 'react';
import BreadCrumb from '../components/breadcrumbs';
import Footer from '../components/footer';
import Header from '../components/header';

const Shipping = props => {
    return(
        <React.Fragment>
            <Header />
            <BreadCrumb current="SHIPPING AND DELIVERY OPTIONS" />
            <div className="container py-5">
                {/* <h4>SHIPPING AND DELIVERY OPTIONS </h4> */}
                <p><a href="go4Merchandise.com">go4Merchandise.com</a> orders are processed, dispatched and delivered Monday-Friday, excluding national holidays/ gazetted holidays. The orders are delivered within 14-21 business days. <br/><br/>
                FREE SHIPPING on all the orders on <a href="go4Merchandise.com">go4Merchandise.com</a>.<br/> All the orders are shipped through Surface Transport.</p>
            </div>
           <div className="container-fluid">
            <Footer />
           </div>
        </React.Fragment>
    )
}

export default Shipping;