import { Divider, TextField,CircularProgress, Accordion, AccordionSummary, Typography, AccordionDetails, Card, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Login from '../components/login';
import SimpleReactValidator from 'simple-react-validator';
import MuiAlert from '@material-ui/lab/Alert';
import useForceUpdate from 'use-force-update';
import { CartController } from '../controllers/cart.controller';
import { ListController } from '../controllers/list.controller';
import { ButtonToggle, FormGroup, Input, Label,Button, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback } from 'reactstrap';
import emptyCart from '../assets/images/emptycart.png';
import maskImage from '../assets/images/mask.jpg'
import tickImage from '../assets/img/tick.png'
import { CommonMethods } from '../Utils/commonMethod';
import ConfirmationDialog from '../Utils/confirmationDialog';
import Loading from '../Utils/loading';
const gstRegex = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/g;
const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Cart = (props) => {
     
    const [modal, setModal] = useState(false);
    const [cartDetails,setCartDetails] = useState([]);
    const [addressForm,setAddressForm] = useState(false);
    const [open,setOpen] = useState(false);
    const [addressList,setAddressList] = useState([]);
    const [loading,setLoading] = useState(false);
    const [fetching,setFetching] = useState(true);
    const [qtyError , setQtyError] = useState('');
    const [priceDetails,setPriceDetails] = useState({
        basic: "0.00",
        cgst: "0.00",
        cgst_per: "0.0",
        igst: "0.00",
        igst_per: "0.00",
        msg: "",
        sgst: "0.00",
        sgst_per: "0.00",
        total_amount: "0.00",
        total_items: "0",
        total_qty: "0",
        type: "Success",
        show_tax: "0.00",
        valid: true
    });
    const [genderWiseQty,setGenderWiseQty] = useState([]);
    const [selectedItem,setSelectedItem] = useState(0);
    const [productEditedSizes,setproductEditedSizes] = useState(null);
    const [price_slab,setprice_slab] = useState([]);
    const [genderQty,setGenderQty] = useState([]);
    const [sku_id,setSku_id ] = useState();
    const [genderSizes, setGenderSizes] = useState([]); 
    const [genderQtyTotal,setgenderQtyTotal] = useState([]);
    const [isDirty,setIsDirty] = useState(false);
    const [snackOpen , setSnackOpen] = useState(false);

    const [validate,setValidate] = useState(new SimpleReactValidator({ 
        validators: {
          gstn: {  // name the rule
            message: 'Enter valid GSTIN',
            rule: (val, params, validator) => {
              return validator.helpers.testRegex(val,gstRegex) && params.indexOf(val) === -1
            },
            // messageReplace: (message, params) => message.replace(':values', helpers.toSentence(params)),  // optional
            // required: true  // optional
          },
          pan: {  // name the rule
            message: 'Enter valid PAN Number',
            rule: (val, params, validator) => {
              return validator.helpers.testRegex(val , panRegex) && params.indexOf(val) === -1
            },
            // messageReplace: (message, params) => message.replace(':values', helpers.toSentence(params)),  // optional
            // required: true  // optional
          }
        }
      }));
    // const [validateGSTN,setvalidateGSTN] = useState();
    const forceUpdate = useForceUpdate();

    const [addressDetail,setAddressDetail] = useState({
        "address_id": "0",
        "customer_id":localStorage.getItem("customer_id"),
        "company_name": "",
        "contact_person": "",
        "mobile": "",
        "address": "",
        "country": "India",
        "state": "",
        "state_id": "",
        "city": "",
        "pin": "",
        "gstin": "",
        "pan": "",
    });

    const [stateList,setStateList] = useState([]);
    const [shipTo,setShipTo] = useState({
        address:'',
        name:'',
        person:'',
        pan:'',
        gstin:''
    });
    const [showAlert , setShowAlert] = useState({
        show:false,
        message:"",
        type:""
    });
    const [billTo,setBillTo] = useState({
        address:'',
        name:'',
        person:'',
        pan:'',
        gstin:''
    });

    const [payment,setPayment] = useState({
        tran_id:0,
        customer_id:localStorage.getItem("customer_id"),
        billTo_id:"",
        shipTo_id:""
    });

    const [editedProduct,seteditedProduct] = useState({
        sku_id:"",
        cart_id:""
    }); 
    const [currentTran , setCurrentTranId] = useState(false);
    const [Qty,setQty] = useState(0);   
    const [cost,setcost] = useState(0);
    const [editRate,seteditRate] = useState(0);
    const [TotalAmount,setTotalAmount] = useState(0);
    const toggle = () => {
        setModal(!modal);
        setIsDirty(false);
    }

    useEffect(() => {
        if(localStorage.getItem("login")){
            getCartItemDetails();
            getPriceDetails('');
            checkUserMobile();
            getAddressList();
        }
        else{
            setFetching(false);
        }
    },[]);

    useEffect(() => {
        if(isDirty){
            toggle();
        }
    },[genderSizes])


    const getStateList = () => {
        const param = {
            country:1
        }
        ListController.getStateList(param).then(data => setStateList(data));
    }

    const getAddressList = () => {
        const param = {
            customer_id:localStorage.getItem("customer_id")
        }
        CartController.getAddress(param).then(data => setAddressList(data));
    }
    const checkUserMobile = () => {
        const param = {
            customer_id:localStorage.getItem("customer_id")
        }
        CartController.checkMobile(param).then(data => {
            if(data.mobile == "" || data.mobile == null){
                alert("Mobile Not available");
            }
        });
    }

    const getCartItemDetails = () => {
        setFetching(true);
        const params = {
            customer_id:localStorage.getItem("customer_id")
        }
        CartController.getCartDetails(params).then(data => {
            setCartDetails(data);
        });
    }

    const showShipTo = (id) => {
        var temp = addressList.filter(x => x.address_id === id);    
        var address = temp[0];
        setShipTo({...billTo,
            name:address.company_name,
            address:address.address + "," + address.city + "," + address.state + "," +address.pin,
            person:address.contact_person,
            pan:address.pan,
            gstin:address.gstin
        });
    }

    const showBillTo = (id) => {
        var temp = addressList.filter(x => x.address_id === id);    
        var address = temp[0];
        setBillTo({...billTo,
            name:address.company_name,
            address:address.address + "," + address.city + "," + address.state + "," +address.pin,
            person:address.contact_person,
            pan:address.pan,
            gstin:address.gstin
        });
    }

    const createAddress = () => {
        setLoading(true);
        CartController.insertAddress(addressDetail).then(data => {
            if(data.valid){
                getAddressListSelected(data.msg);
                setAddressForm(!addressForm);
                setLoading(false);
                setSnackOpen(true); 
                getAddressList();            
            }
        });
    }

    const getAddressListSelected = (id) => {
        const param = {
            customer_id:localStorage.getItem("customer_id")
        }
        CartController.getAddress(param).then(data => {
            setAddressList(data);
            setPayment({...payment,
                billTo_id:id
            });     
            var temp = data.filter(x => x.address_id === id);    
            var address = temp[0];
            setBillTo({...billTo,
                name:address.company_name,
                address:address.address + "," + address.city + "," + address.state + "," +address.pin,
                person:address.contact_person,
                pan:address.pan,
                gstin:address.gstin
            });
            getPriceDetails(id)
        });
    }

    const handleChange = event => {
        setAddressDetail({...addressDetail,
            [event.target.name]:event.target.value
        })
    }

    const deleteItem = (tran_id) => {
        setLoading(true);
        var data = {
            tran_id: tran_id
        }
        CartController.deleteItem(data).then(data => window.location.reload())
    }

    const getPriceDetails = (address_id) => {
        var data = {
            customer_id: localStorage.getItem("customer_id"),
            address_id: address_id
        }
        CartController.getCustomerCartDetails(data).then(data => {
         var showtax= "0.00";
         if(address_id != "")
         {
            showtax=  (data.basic * 5/100);
         }
            setPriceDetails({...priceDetails,
                basic:data.basic,
                total_amount:data.total_amount,
                total_items:data.total_items,
                total_qty:data.total_qty,
                show_tax: showtax
            });
            setFetching(false);
        })
    }

    const handleSnack = () => {
        setSnackOpen(false);
    }

    const proceedPayment = () => {
        setLoading(true);
        const param = {
            customer_id:localStorage.getItem("customer_id")
        }
        CartController.checkMobile(param).then(data => {
            if(data.mobile == "" || data.mobile == null){
                alert("Mobile Not available");
                setLoading(false);
            }
            else{
                if (billTo.address == "") {
                    showCustomAlert("Select Billing Address","alert"); 
                    setLoading(false);
                }
                else if (shipTo.address == "") {
                    showCustomAlert("Select Shiping Address","alert");
                    setLoading(false);
                }
                else {
                    var temp = {...payment,...priceDetails};
                    CartController.proceedToPayment(temp).then(data => {
                        if (data.valid) {                
                            window.location = "https://go4merchandise.com/payment?i=" + data.msg
                            setLoading(false);
                          }
                    });
                }
            }
        });
    }

    
      
    const initialCalclation = (all_sizes) => {

        //copy size array into local object
        let newGenderSizeArr = [];     
           
        for (let index = 0; index < all_sizes.length; index++) {
            const element = all_sizes[index];    
 
            if(newGenderSizeArr.length > 0){          
             var genderIndex = newGenderSizeArr.findIndex(function(m){
               return m.gender_id === element.gender_id && m.size === element.size;
             });
             if(genderIndex !== -1){
               newGenderSizeArr[genderIndex].qty = parseInt(element.qty === "" ? 0 : element.qty);
             }
             else{
               const param = {        
                 sku_id:sku_id,      
                 gender_id:element.gender_id,
                 size:element.size,
                 qty:parseInt(element.qty === "" ? 0 : element.qty)
               }
               newGenderSizeArr.push(param);
             }
         }
   
         else{
           const param = {  
            sku_id:sku_id,         
             gender_id:element.gender_id,
             size:element.size,
             qty:parseInt(element.qty === "" ? 0 : element.qty) 
           }
           newGenderSizeArr.push(param);
         }   
            
        }
        let GenderCount = getUnique(newGenderSizeArr,'gender_id'); 
 
        let tempGenderQty = 0;
        let tempGenderQtyEtotalArr = [];
        let tempGenderQtyArr = newGenderSizeArr;
         for (let indexGender = 0; indexGender < GenderCount.length; indexGender++) {
             let qtyTotal=0;
             const elementGender = GenderCount[indexGender];
             for (let index = 0; index < newGenderSizeArr.length; index++) {
                 const element = newGenderSizeArr[index];
                 if(element.gender_id === elementGender.gender_id){
                     tempGenderQty = tempGenderQty + element.qty;
                     qtyTotal = qtyTotal + element.qty;
                 }
             } 
             let param ={
                 gender_id:elementGender.gender_id,
                 qty:qtyTotal
             }
             tempGenderQtyEtotalArr.push(param);
             
         }     
       setGenderQty(tempGenderQtyArr);
       setgenderQtyTotal(tempGenderQtyEtotalArr);
       setGenderSizes(newGenderSizeArr);
    }

    const genderCalculation = (value,gender_id,label) => {
          //copy size array into local object
          let newGenderSizeArr = [...genderSizes];
      
          if(newGenderSizeArr.length > 0){;
           
            var genderIndex = newGenderSizeArr.findIndex(function(m){
              return m.gender_id === gender_id && m.size === label;
            });
            if(genderIndex !== -1){
              newGenderSizeArr[genderIndex].qty = parseInt(value === "" ? 0 : value);
            }
            else{
              const param = {        
                sku_id:sku_id,      
                gender_id:gender_id,
                size:label,
                qty:parseInt(value === "" ? 0 : value)
              }
              newGenderSizeArr.push(param);
            }
        }
 
        else{
          const param = {  
           sku_id:sku_id,         
            gender_id:gender_id,
            size:label,
            qty:parseInt(value === "" ? 0 : value) 
          }
          newGenderSizeArr.push(param);
        }
        let GenderCount = getUnique(newGenderSizeArr,'gender_id'); 
        let tempGenderQty = 0;
        let tempGenderQtyEtotalArr = [];
        let tempGenderQtyArr = newGenderSizeArr;
         for (let indexGender = 0; indexGender < GenderCount.length; indexGender++) {
             let qtyTotal=0;
             const elementGender = GenderCount[indexGender];
             for (let index = 0; index < tempGenderQtyArr.length; index++) {
                 const element = tempGenderQtyArr[index];
                 if(element.gender_id === elementGender.gender_id){
                     tempGenderQty = tempGenderQty + element.qty;
                     qtyTotal = qtyTotal + element.qty;
                 }
             }
             let param ={
                 gender_id:elementGender.gender_id,
                 qty:qtyTotal
             }
             tempGenderQtyEtotalArr.push(param);
             
         }   
         setgenderQtyTotal(tempGenderQtyEtotalArr);
         setGenderSizes(newGenderSizeArr);   
    }
    
    useEffect(() => { 
        let qty = 0;
        for (let index2 = 0; index2 < genderSizes.length; index2++) {
           const element2 = genderSizes[index2];
            qty = qty + parseInt(isNaN(element2.qty) ? 0 : element2.qty);
        }
        setQty(qty);   
      
        var Cal_rate = 0;
   
        for (let index = 0; index < price_slab.length; index++) {
           const element = price_slab[index];         
                element.selected= false; 
         }
        for (let index = 0; index < price_slab.length; index++) {
          const element = price_slab[index];
          if(qty < 50){
            setQtyError("Min qty required 50");
        }
        else{
            setQtyError("");
          if(qty === 0)
          {
               Cal_rate =  parseFloat(price_slab[0].price)+ parseFloat(cost);
               seteditRate(Cal_rate); 
                 
          }
           if(qty >= element.min_qty && qty <= element.max_qty){           
              Cal_rate =  parseFloat(price_slab[index].price)+ parseFloat(cost);           
              seteditRate(Cal_rate); 
                 element.selected= true;                 
           }
           else if(qty > 500){
              Cal_rate =  parseFloat(price_slab[price_slab.length - 1].price)+ parseFloat(cost);
              seteditRate(Cal_rate); 
           }
        }
        }
        var TotalCost =  ( parseInt(isNaN(qty) ? 0 : qty) *  (Cal_rate));      
       setTotalAmount(TotalCost); 

    },[genderSizes]); 

   // get unique value form array
  function getUnique(arr, index) {
 
    const unique = arr
          .map(e => e[index])

          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)

          // eliminate the dead keys & store unique objects
          .filter(e => arr[e]).map(e => arr[e]);      

       return unique;
    }
    const UpdateSize = () =>{
        console.log(localStorage.getItem("customer_id"));
    }
   

    
    const UpdateItemsize = () => {
    
        if(Qty < 50){
            showCustomAlert("Please fill minimum quantity (MOQ) of 50 Pcs.","alert");
        
         }
       else if(Qty > 500){
            showCustomAlert("For bulk enquiries of more than 500 Pcs, Kindly visit us at www.brij.com.","alert");
         }
         else{
            const param = {
                customer_id:localStorage.getItem("customer_id"),
                cart_id:editedProduct.cart_id,
                sku_id:editedProduct.sku_id,
                qty:Qty,
                amount:TotalAmount,
                rate:editRate,
                sizes:genderSizes
            }
            console.log("param");
            console.log(param);
           
            CartController.productSizeUpdate(param).then(data => {
                window.location.reload();
            });
         }
       
    }

    const showCustomAlert = (message , type) => {
        setShowAlert({
            show:true,
            message:message,
            type:type
        });
     }


    // const getCustCost = (arr) => {
    //     var temp = 0;
    //     for (let index = 0; index < arr.length; index++) {
    //         const element = arr[index];
    //         temp = temp + parseInt(element.cost);   
    //     } 
    //     return temp;  
    // }

    // const editAddress = () => {

    // }

    return (
        <React.Fragment>
            <Header />
                {open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
                setOpen(false);
            }} /> : null}
             {loading ? <Loading /> : null}
            <ConfirmationDialog show={showAlert.show} onClose={() => setShowAlert({...showAlert,show:false})} type={showAlert.type} onOkClick={() => deleteItem(currentTran)} message={showAlert.message} />
     <div>
      <Modal isOpen={modal} toggle={toggle} centered={true} size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Edit size</ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-md-8 col-12">
                <p className="m-0 font_13 text-theme-color"><a className="best-price-cart ml-auto font_13">
                      View price Slab
                      <div className="best-offer-cart">
                         <table className="table table-bordered">
                            <thead className="thead-dark">
                               <tr>
                                  <td>Qty</td>
                                  <td>Price/Pc</td>
                               </tr>
                            </thead>
                            <tbody>
                               {price_slab.length > 0 ? price_slab.map((prop,index) => {
                               return<tr key={index} className={prop.selected ? "selected":""}>
                                  <td> {prop.min_qty} - {prop.max_qty}</td>
                                  <td>Rs  {prop.price} {prop.selected ? <img style={{width:20,marginLeft:10}} src={tickImage} /> : null}</td>
                               </tr>
                               }) : null}
                            </tbody>
                         </table>
                      </div>
                   </a></p>
                   {cartDetails.length > 0 ? genderSizes.length > 0 ? cartDetails[selectedItem].product_genders.map((gender,index) => {
                       return <div className="col-md-9 col-sm-12" key={"gender" + index}>
                       <span className="d-block my-2 font_13">{gender.gender}</span>
                       <div className="d-flex w-100 align-items-center"> 
                           {gender ? gender.sizes.map((size,index) => {
                               
                                    return  <TextField key={"child" + index} type="text" size="small" className="mr-md-2 sizeInput" style={{
                                        width:40
                                    }} InputLabelProps={{
                                        style:{
                                            fontSize:11
                                        }
                                    }} InputProps={{
                                        style:{
                                                fontSize:11
                                        }
                                    }} id="outlined-basic" label={size.size} value={genderSizes.length > 0 ? parseInt(genderSizes[genderSizes.findIndex(x => x.gender_id === gender.tran_id && x.size === size.size)].qty) === 0 ? "" : genderSizes[genderSizes.findIndex(x => x.gender_id === gender.tran_id && x.size === size.size)].qty : ""}
                                    onChange={(e) => {
                                        if(/^\d+$/.test(e.target.value) || e.target.value === ""){
                                            genderCalculation(e.target.value,gender.tran_id,size.size)
                                          }
                                          else{
                                            return false;
                                          }
                                        
                                     }} variant="outlined" />
                               
                            }) : null}
                           
                       <li className="total_gender">{genderQtyTotal.length > 0 ? genderQtyTotal.findIndex(x => x.gender_id == gender.tran_id) !== -1 ? genderQtyTotal[genderQtyTotal.findIndex(x => x.gender_id == gender.tran_id)].qty : 0 : null}</li>  
                      </div>
                      </div>
                      
                   }) : null : null}
                    <p className="text-danger mb-0 font_13">{qtyError}</p>
                </div>
                <div className="col-md-4 col-12 mt-4 mt-md-0 px-4 px-md-0">
                <div className="row justify-content-end">
                       <div className="col-6 font_13">Quantity: </div>
                       <div className="col-6 font_13">{Qty} <span className="font_11">pcs.</span></div>
                   </div>
                   <div className="row justify-content-end">
                       <div className="col-6 font_13">Rate: </div>
                       <div className="col-6 font_13">Rs. {editRate}</div>
                   </div>
                   <hr/>
                   <div className="row justify-content-end">
                       <div className="col-6 font_13 font-weight-bold">Amount: </div>
                       <div className="col-6 font_13">Rs. {CommonMethods.currenyMasking(parseInt(TotalAmount))}</div>
                   </div>
                </div>
            </div>
       
                  
        </ModalBody>
        <ModalFooter>
            <button className="btn btn-danger font_13"  onClick={toggle}>Cancel</button>
            <button type="button" className="btn btn-theme-outlined font_13" onClick={() => {UpdateItemsize()}}>Save</button>   
         
        </ModalFooter>
      </Modal>
    </div>
           {fetching ? <div className="container position-relative">
            <div className="loading-data d-flex align-items-center mt-5 justify-content-center">
                <CircularProgress />
                    <h4 className="mb-0 ml-3">Loading Cart Items</h4>
            </div>
           </div> :  cartDetails.length > 0 ?  <div className="container mt-5">
            <div className="row mb-3">
            <div className="col-md-8">
                <div className="logo_header position-relative mb-3 ml-3 ml-md-0">
                    <h4>Shopping Bag</h4>
                </div>
            <table id="cart" className="table table-condensed">
    				<thead>
						<tr>
							<th style={{width:'58%'}}>Product Description</th>
							<th style={{width:'12%'}}>Price/Unit</th>						 
							<th style={{width:'17%'}} className="text-center">Total Amount</th>
							<th style={{width:'5%'}}></th>
						</tr>
					</thead>
					<tbody>
						{cartDetails.length > 0 ? cartDetails.map((cart,index) => {
                            return <tr key={index}>
							<td>
								<div className="row no-gutters">
									<div className="col-sm-4 hidden-xs"><img src={"https://api.go4merchandise.in/Upload" + (cart.type === "New" ? "/Final_Product_Main/" : "/SKU_Main/") + cart.image_path} alt="..." className="cartImage img-fluid"/></div>
									<div className="col-sm-8">
										<h6 className="m-0"><span className="font-weight-bold">{cart.product_name}</span></h6>
                                        
										<p className="font_11">({cart.fabric_description})</p>
                                        <Divider className="d-block d-md-none" />
                                        <h6 className="font_13 mt-3"><span className="font-weight-bold">Branding</span> <span className="font_11">(Digital Embroidery)</span></h6>
                                        {cart.customized_text.map((emb,index) => {
                                            return <span className="d-block font_11" key={index}>- {emb.text}</span>
                                        })}
                                        <h6 className="font_13 mt-3"><span className="font-weight-bold">Total Qty.:</span> {cart.qty}pcs</h6>
                                        <div className="d-inline-flex size_collapse">
                                            <span style={{width:50}} className="font_13"> Size :</span>
                                            <div>
                                            {cart.genders.map((gen,index) => {
                                                return <Accordion className="size_collapse" key={index}>
                                                        <AccordionSummary
                                                        expandIcon={<i class="fas fa-chevron-down" style={{fontSize:12}}></i>}
                                                        aria-controls={"panel"+index+"a-content"}
                                                        id={"panel"+ index +"a-header"}>
                                                            <Typography>{gen.gender}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>                                                       
                                                            {gen.sizes.map((size,index) => {
                                                                       return <div className="sizeBox" key={index}>
                                                                    <label>{size.size}</label>
                                                                    <label>{size.qty}</label>
                                                                </div>
                                                            })}
                                                        
                                                        </AccordionDetails>
                                                    </Accordion>   
                                            })}
                                            
                                            </div>
                                        </div>
 

									</div>
								</div>
							</td>
							<td className="font_14 text-right text-md-left"><p className="mb-0"><span className="font-weight-bold d-inline d-md-none">Price: </span>₹ {cart.rate}</p>
                            </td>
							<td className="font_14 text-right text-md-center"><span className="font-weight-bold d-inline d-md-none">Total Amount: </span>₹ {CommonMethods.currenyMasking(parseInt(cart.amount))}.00</td>
							<td className="actions d-flex" >
                                <div className="d-flex w-100 justify-content-center align-items-center" >
                                <div className="close">                             
                                    <i className="fas fa-pencil theme-color-a cursor-pointer" onClick={()=> {  
                                    setSelectedItem(index)
                                    setGenderSizes(cart.all_sizes);    
                                    initialCalclation(cart.all_sizes);
                                    setQty(cart.qty);
                                    setcost(cart.customization_cost);
                                    seteditRate(cart.rate);
                                    setTotalAmount(cart.amount);
                                    seteditedProduct({...editedProduct,sku_id:cart.sku_id,cart_id:cart.tran_id});
                                    setprice_slab(cart.price_slab);
                                    setIsDirty(true);
                                }} style={{fontSize:12}}></i>
                                </div>
                                <div className="close ml-3">
                                    <i className="fas fa-times cursor-pointer d-md-block d-none" onClick={(e) =>  { 
                                        setCurrentTranId(cart.tran_id);          
                                        showCustomAlert("Are you sure want to delete item");
                                   }} style={{fontSize:12}}></i>
                                   <span className="d-md-none font_11 font-weight-light" onClick={(e) =>  { 
                                        setCurrentTranId(cart.tran_id)          
                                        showCustomAlert("Are you sure want to delete item");
                                   }} >Remove item</span>
                                </div>
                                </div>        
							</td>
						</tr>
                        }) : null}
                        {cartDetails.length > 0 ? (cartDetails[0].ts_count != "0" && cartDetails[0].ts_count != "" ? 
                           <tr>
                           <td>
                             <div className="row no-gutters">
                                 <div className="col-sm-4 hidden-xs"><img src={maskImage} alt="..." className="cartImage img-fluid"/></div>
                                 <div className="col-sm-8">
                                     <h6 className="m-0 font_13">Complimentary Fabric Face Mask/ Black</h6>
                                     <span className="font_12">Branding - Go4M Logo <span className="font_11">(Reflective Digital Print)</span></span>
                                        <span className="d-block font_11 mt-2">- Outer Layer : 100% Polyester</span>
                                        <span className="d-block font_11">- Middle Layer : 5-Layer Filter</span>
                                        <span className="d-block font_11">- Inner Layer : 100% Cotton</span>
                                   
                                     <h6 className="font_13 my-1">Total Qty. : {cartDetails[0].ts_count}pcs</h6>
                                     <div className="d-inline-flex size_collapse">
                                       
                                         <div className="font_13">
                                         Features : <span className="font_11">Breathable, washable, reusable, universal fit,
                                            adjustable soft elastic ear loops, nose pin for better fit.</span>
                                         </div>
                                     </div>
                                     <h6 className="font_11 mt-2 red color-red-text">*Introductory Offer valid only on t-shirts order. </h6>

                                 </div>
                             </div>
                         </td>
                         <td className="font_14 text-muted text-right text-md-left">₹ 0</td>
                         <td className="font_14 text-right text-md-center">₹ 0</td>
                         <td className="actions" >                           
                        </td>
                     </tr>                        
                        : null): null}

                     
					</tbody>
        
				</table>
           
            </div>
            {priceDetails ? <div className="col-md-4">
                <div className="summary p-3" style={{marginTop:40}}>
                    <div className="logo_header position-relative ml-3">
                        <h4>Order Summary</h4>
                    </div>
                    <p className="mb-3 font_13">Estimated shipping and tax</p>

                    <Divider /> 
                    <p className="font_13 mt-2 mb-2">Total Item(s)<span className="float-right">{priceDetails.total_items}</span></p> 
                    <p className="font_13 mb-2">Total Quantity<span className="float-right">{priceDetails.total_qty}</span></p> 
                    <Divider />  
                    {/* <p className="font_13 mt-2 mb-2">Subtotal<span className="float-right">₹ {CommonMethods.currenyMasking(parseFloat(priceDetails.basic))}</span></p> */}
                    <p className="font_13 mt-2 mb-2">Subtotal<span className="float-right">₹ {parseFloat(priceDetails.basic)}</span></p> 
                    <p className="font-weight-bold mb-2">Taxes</p> 
                    <p className="font_13 mb-2">Taxes<span className="ml-1" style={{opacity:0.3,fontSize:11}}>(5%)</span><span className="float-right">₹ {parseFloat(priceDetails.show_tax).toFixed(2)}</span></p>  
                    
                    <Divider />
                    <p className="font-weight-bold font_13 mt-3 mb-0">Total Amount <span className="float-right">₹ {CommonMethods.currenyMasking(parseFloat(priceDetails.total_amount))}</span></p>
                    <span className="text-muted" style={{opacity:0.3,fontSize:11,marginTop:-2,display:'block'}}>(incl. GST & Taxes)</span>
                    <button disabled={loading ? <CircularProgress style={{width:15,height:15}} /> : null} className="btn btn-theme-outlined font_13 mt-3 w-100" onClick={proceedPayment}>{loading ? <CircularProgress style={{width:15,height:15}} /> : null}Proceed to payment</button>
                    <span className="btn  text-muted font_11 w-100">OR</span>
                    <a className="btn btn-link text-muted font_13 w-100 Continue-shopping" onClick={() =>{window.location.pathname='\home'}}>Continue Shopping</a>
                </div>
            </div> : null} 
            </div>
            <div class="a-divider-inner"></div>
            <div className="choose_address mb-5 px-md-5 py-3">
                <div className="row">
                    <div className="col-md-6">
                        <h6>Billing Address</h6>
                        <select disabled={addressForm} className="form-control  font_13" onChange={(e) => {
                            setPayment({...payment,
                                billTo_id:e.target.value
                            });
                            showBillTo(e.target.value);                       
                        }}>
                            <option value="" disabled selected>Select Address</option>
                            {addressList.length > 0 ? addressList.map((address,index) => {
                                return <option key={index} value={address.address_id}>
                                            {address.company_name} {address.address}
                                        </option>
                            }) : null}
                        </select>
                        {/* {billTo.address !== "" ? <button className="btn btn-link text-theme-color font_11">Edit Address...</button> : null} */}
                        {billTo.name !== "" ? <div className="card mt-3 p-3 bg-light">
                            <h6>{billTo.name}</h6>
                            <p className="font_13 mb-1">{billTo.address}</p>
                            <p className="font_13 mb-1"><b>Contact Person: </b>{billTo.person}</p>
                            {billTo.gstin != "" ?  <p className="font_13 mb-1"><b>GSTIN: </b>{billTo.gstin}</p> : null}
                            {billTo.pan != "" ?  <p className="font_13 mb-1"><b>PAN: </b>{billTo.pan}</p> : null}
                        </div> : null}
                    </div>
                    <div className="col-md-6 mt-3 mt-md-0">
                        <h6>Shipping Address</h6>
                        <select disabled={addressForm} className="form-control font_13" onChange={(e) => {
                            setPayment({...payment,
                                shipTo_id:e.target.value
                            });
                            showShipTo(e.target.value);
                            getPriceDetails(e.target.value)
                        }}>
                            <option value="" disabled selected>Select Address</option>
                            {addressList.length > 0 ? addressList.map((address,index) => {
                                return <option key={index} value={address.address_id}>
                                {address.company_name} {address.address}
                            </option>
                            }) : null}
                        </select>
                        {/* {shipTo.address !== "" ? <button className="btn btn-link text-theme-color font_11">Edit Address...</button> : null} */}
                       {shipTo.name !== "" ?  <div className="card mt-3 p-3 bg-light">
                            <h6>{shipTo.name}</h6>
                            <p className="font_13 mb-1">{shipTo.address}</p>
                            <p className="font_13 mb-1"><b>Contact Person: </b>{shipTo.person}</p>
                            {shipTo.gstin != "" ?  <p className="font_13 mb-1"><b>GSTIN: </b>{shipTo.gstin}</p> : null}
                            {shipTo.pan != "" ?  <p className="font_13 mb-1"><b>PAN: </b>{shipTo.pan}</p> : null}
                         
                        </div> : null}
                    </div>
                    <div className="col-md-12">
                    {addressForm ?   <div className="add_address_form w-100 mr-auto py-4 rounded">
                    <div className="row">
                        <div className="col-md-12">
                            <FormGroup>
                                <Label className="font_13">Company Name</Label>
                                <Input className="font_13" invalid={validate.message('Name', addressDetail.company_name , 'required')} name="company_name" onChange={handleChange} />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label className="font_13">Contact Name</Label>
                                <Input className="font_13" invalid={validate.message('Contact', addressDetail.contact_person , 'required')} name="contact_person" onChange={handleChange} />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                             <FormGroup>
                                <Label className="font_13">Mobile No.</Label>
                                <Input className="font_13" invalid={validate.message('Mobile',addressDetail.mobile , 'required|numeric|min:10|max:10')} name="mobile" onChange={handleChange} />
                                <FormFeedback>{validate.message('Mobile', addressDetail.mobile , 'required|numeric|min:10|max:10')}</FormFeedback>
                            </FormGroup>
                             
                        </div>
                        <div className="col-md-12">
                            <FormGroup>
                                <Label className="font_13">Address</Label>
                                <Input className="font_13" invalid={validate.message('Address', addressDetail.address , 'required')} name="address" onChange={handleChange} />
                            </FormGroup>
                             
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label className="font_13">Country</Label>
                                <Input className="font_13" invalid={validate.message('Country', addressDetail.country , 'required')} name="country" value={addressDetail.country} />
                            </FormGroup>
                             
                        </div>
                        <div className="col-md-6">
                             <FormGroup>
                                <Label className="font_13">State</Label>
                                <Input invalid={validate.message('State', addressDetail.state_id , 'required')} className="font_13" type="select" name="state_id" onChange={handleChange}>
                                {stateList.length > 0 ? stateList.map((state,index) => {
                                        return <option key={index} value={state.state_id}>{state.state_name}</option>
                                    }) : null}
                                </Input>
                                 
                            </FormGroup>
                            
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label className="font_13">City</Label>
                                <Input className="font_13" invalid={validate.message('City', addressDetail.city , 'required')} name="city" onChange={handleChange} />
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                             <FormGroup>
                                <Label className="font_13">Pincode</Label>
                                <Input className="font_13" invalid={validate.message('Pincode', addressDetail.pin , 'required')} name="pin" onChange={handleChange} />
                            </FormGroup>
                           
                        </div>
                        <div className="col-md-6">
                        <FormGroup>
                                <Label className="font_13">GSTIN</Label>
                                <Input className="font_13 text-uppercase" invalid={validate.message('GSTIN', addressDetail.gstin , 'gstn')} maxLength="15" name="gstin" value={addressDetail.gstin} onChange={handleChange} />
                                <FormFeedback invalid>{validate.message('GSTIN', addressDetail.gstin , 'gstn')}</FormFeedback>
                        </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label className="font_13">PAN</Label>
                                <Input className="font_13 text-uppercase" invalid={validate.message('Pan Number', addressDetail.pan , 'pan')} name="pan" onChange={handleChange} />
                                <FormFeedback invalid>{validate.message('Pan Number', addressDetail.pan , 'pan')}</FormFeedback>
                            </FormGroup>
                            
                        </div>
                        <div className="col-md-12">
                            <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleSnack}>
                                <Alert onClose={handleSnack} severity="success">
                                        Address Added Successfully
                                </Alert>
                            </Snackbar>
                        <ButtonToggle disabled={loading}  className="btn-theme-outlined btn font_13 px-4 mt-3 mr-2" onClick={() => {
                             if (validate.allValid()) {
                                createAddress();
                              } 
                              else {
                                validate.showMessages();
                                forceUpdate();
                                // rerender to show messages for the first time
                                // you can use the autoForceUpdate option to do this automatically`
                                 
                              }
                        }}>{loading ? <CircularProgress style={{width:15,height:15}} /> : null} Submit</ButtonToggle>
                         <button className="btn btn-theme-outlined font_13 mt-3" onClick={() => {
                            getStateList();
                            setAddressForm(!addressForm);
                        }}>{addressForm ? "Cancel" : "Add Address"}</button>
                        </div>
                    </div>
                </div>
            : null}
                       {addressForm ? null : <button className="btn btn-theme-outlined font_13 mt-3" onClick={() => {
                            getStateList();
                            setAddressForm(!addressForm);
                        }}>{addressForm ? "Cancel" : "Add Address"}</button>}
                    </div>
                </div>
                        
            </div>
        </div>
       : 
       <div className="container">
           <div className="no_items_box text-center">
               <img src={emptyCart} className="w-50" />
               <h4 className="text-center">No Items in cart</h4>
               {localStorage.getItem("login") ? <button onClick={() => window.location = "/home"} className="btn btn-theme-outlined font_13">Start Shopping</button> : <button className="btn btn-theme-outlined font_13 mt-2" onClick={() => setOpen(true)}>Login</button> }
           </div>
       </div> }
        
       
        
        </React.Fragment>
    )
}

export default Cart;