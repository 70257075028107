import { userService } from "../_services/api";

export const CartController = {
    checkMobile,
    getCartDetails,
    getAddress,
    insertAddress,
    deleteItem,
    getCustomerCartDetails,
    proceedToPayment,
    getOrdersList,
    generateInvoice,
    productSizeUpdate
}

async function checkMobile(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerProfilePrivew`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getCartDetails(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerCartItemPreview`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getAddress(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerAddressList`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}


async function insertAddress(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerInsertAddress`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function deleteItem(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerCartDelete`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getCustomerCartDetails(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerCartMain`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function proceedToPayment(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerOrderInsert`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function getOrdersList(payload) {
    try {
        let apiEndpoint = `api/Customer/GetCustomerOrderList`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function generateInvoice(payload) {
    try {
        let apiEndpoint = `api/Customer/rpt_order`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}

async function productSizeUpdate(payload) {
    try {
        let apiEndpoint = `api/Customer/UpdateCustomerSize`;
        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}



