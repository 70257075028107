import React, { useEffect, useState } from 'react';
import thankYou from '../assets/images/thankyou.png';
import thankYouMobile from '../assets/images/thankyouomobile.png';
import Header from '../components/header';
import Login from '../components/login';


const ThankYou = () => {
    const [open,setOpen] = useState(false);
    const orderNo = new URLSearchParams(window.location.search).get("orderno");
    const amount = new URLSearchParams(window.location.search).get("amount");
    const [imageType,setImageType] = useState(false);

    useEffect(() => {
        if(window.outerWidth < 600){
            setImageType(true)
        }

        setTimeout(() => {
            window.location = "/order"
        }, 6000);
    },[])
    return(
        <React.Fragment>
            <Header />
			{open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
            	setOpen(false);
          	}} /> : null}
             <div className="mainScreen">
                    <div className="thankyou_panel position-relative">
                    {imageType ? <img src={thankYouMobile} style={{height:window.innerHeight - 114,objectFit:'contain',width:window.outerWidth}} className="mx-auto d-block" /> : <img src={thankYou} className="w-85 mx-auto d-block" /> }
                    <div className="orderDetails">
                        <div className="d-flex mb-2">
                            <span>Order No.:</span>
                            <span>{orderNo}</span>
                        </div>
                        <div className="d-flex">
                            <span>Amount Paid:</span>
                            <span>₹ {amount}</span>
                        </div>
                    </div>
                </div>
             </div>
        </React.Fragment>
    )
}

export default ThankYou;