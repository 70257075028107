import config from '../config/config';
import axios from 'axios';
const token = localStorage.getItem("token");


export const userService = {
    get,
    post,
    put,
    imagePost
    // getStatus
};


function get(apiEndpoint,userId){
    return   axios.get(config.baseUrl+apiEndpoint, getOptions())
        .then((response)=>{
            return response;
        }).catch((err)=>{
            console.log(err);
        
        if(err.response!=undefined && err.response.status==401){
            // window.open('/','_self');
            console.log(err)
        }else{
            // alert('operation not able to perform');
        }
        })
}
 function post(apiEndpoint, payload){

    return  axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
    .then((response)=>{
        return response;
       
    }).catch((err)=>{
        console.log(err);
        if(err.response!=undefined && err.response.status==401){
            // window.open('/','_self');
           }else{
               //alert('operation not able to perform');
           }
           return err;
    })
}

function imagePost(apiEndpoint, payload){

    return  axios.post(config.baseUrl+apiEndpoint, payload, getImageOptions())
    .then((response)=>{
        return response;
       
    }).catch((err)=>{
        console.log(err);
        if(err.response!=undefined && err.response.status==401){
            // window.open('/','_self');
           }else{
               //alert('operation not able to perform');
           }
           return err;
    })
}

function put(apiEndpoint, payload){

    
    return axios.put(config.baseUrl+apiEndpoint, payload, getOptions())
    .then((response)=>{
        return response;
    }).catch((err)=>{
        console.log(err);
        if(err.response!=undefined && err.response.status==401){
            window.open('/','_self');
           }else{
              // alert('operation not able to perform');
           }
    })
}

function getOptions(){ 
    let options = {};
    // if(localStorage.getItem('token')){
    //     options.headers = {
    //         'Authorization': 'Bearer ' + localStorage.getItem("token"),
    //         // 'Content-Type': 'multipart/form-data'
    //     };
    // }
    return options;
}

function getImageOptions(){ 
     const config = {
         headers:{
            'content-type': 'multipart/form-data'
         }
     }
    return config;
}

export const getClientInfo = () => {
    return new Promise((resolve,reject) => {
        fetch("https://geolocation-db.com/json/" + config.GEO_API)
        .then((response) => response.json())
        .then((response) => {
            resolve(response)
        })
        .catch((error) => {
            reject(error);
        })
    })
}