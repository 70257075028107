import React, { useEffect, useState } from 'react';
import cancelPayment from '../assets/images/cancelPayment.png';
import Header from '../components/header';
import Login from '../components/login';
import { CartController } from '../controllers/cart.controller';

const CancelPayment = (props) => {
    const [open,setOpen] = useState(false);
    const id = new URLSearchParams(props.location.search).get('id');
    const [tran_id,setTranID] = useState("");
    
    useEffect(() => {

        CartController.generateInvoice({order_no:id}).then(data => {
            setTranID(data.tran_id);
        });
     },[]);

    return <React.Fragment>
          <Header />
                {open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
                setOpen(false);
            }} /> : null}
        <div className="cancelContainer text-center mt-4">
            <img src={cancelPayment} className="w-100" />
            <h5 className="mt-4">Oops! Something went wrong</h5>
            <p className="font_13 text-muted">Your payment couldn't be completed. Please retry!</p>
            <a href={'https://go4merchandise.com/payment?i=' + tran_id} className="btn btn-theme-outlined-animate px-5 font_13">Retry</a>
        </div>
    </React.Fragment>
}

export default CancelPayment;