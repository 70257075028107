import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import {Helmet} from "react-helmet";


const WhyGo4M = () => {
    return(
        <React.Fragment>
            <Helmet>
                <title>Go4Merchandise | Why us </title>
				<meta name="description" content="30 years of experience in customised merchandise, fully integrated manufacturing units, customised merchandise with embroidered logo, shipping in 14-21 business days" />
			</Helmet>   
            <Header />
 <section class="category-page">
        <div class="container" data-aos="fade-up">         
            
            <div class="row">
            <div class="col-lg-12">
            <h4 style={{borderLeft:"3px solid #f36f21",marginBottom:"40px",paddingLeft:"10px",marginTop:"30px"}}>Why <span style={{color: "#f36f21"}}>Go4Merchandise.com</span></h4>

            <p>
            With an Experience of more than <strong>30 years</strong> in the Industry, understanding the demand & challenges of Customer & Brij as a manufacturer this idea of <strong>Go4Merchandise</strong> created in 2015.
              <br/><br/>
We used to cater No’s of orders Size & Inquiries for our different Apparel Merchandise with Customisation from all over the country/ Pan India & even from International Market its challenging to cater the demand and make <strong>customer Satisfy</strong> without human intervention.
<br/><br/>
Orders for Min quantity of <strong>50 to 500 No’s</strong> are more challenging.
<br/><br/>
We came across this idea to create an <strong>online Solution</strong> where any of our customer from any part of country/ world willing for Good Quality Apparel Merchandise direct from the experienced Manufacturer on reasonable prices & best services with different level of Customisation which is not possible for order size of 50-500 No’s
<br/><br/>
With Fully integrated <strong>manufacturing infrastructure</strong> right from <strong>Fabric to Packed Shipment</strong> include in-house capability for Print/ Embroidery/ Digital any type of Logo Branding or Messages we initiated to make this happen for our all size of Orders from respective Customers having their offices anywhere or their teams/factory/promotions or even for any personal purpose with options of Products/ Customisation levels to choose/ Order & get this delivered to their door their own designed Branded apparel Merchandise with just <strong>4 Easy Steps. </strong> 
<br/><br/>
<strong>Go4Merchandise Team</strong> has worked in depth to create this as maximum user friendly & we hope you will enjoy & get maximum for what you expected & paid from the House of Brij, A trusted Brand in Customised Apparel Merchandise among Domestic & International Brand.
<br/><br/>
Feel free to Write Suggestions & User Experience to make Go4Merchendise.com more effective & user friendly. 
<br/><br/>                
                <strong>-Praveen Tayal  </strong>
            </p>
            </div>

         

            </div>
        </div>
      </section>
      <div className="container-fluid">
          <Footer />
      </div>
        </React.Fragment>
    )
}

export default WhyGo4M;