import { CircularProgress ,Dialog,DialogActions,DialogContent,DialogTitle,IconButton,TextField,Button, makeStyles, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleReactValidator from 'simple-react-validator';
import useForceUpdate from 'use-force-update';
import { ProfileController } from '../controllers/profile.controller';
import { ButtonToggle } from 'reactstrap';
import camera from '../assets/images/camera.png';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../Utils/cropImage'
import Login from '../components/login';
import Header from '../components/header';
import gallery from '../assets/images/gallery.png';
import deleteImage from '../assets/images/delete.png'
import config from '../config/config';
import { ConfiguratorController } from '../controllers/configurator.controller';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    menuPaper: {
      maxHeight: 200
    }
  }));

const MyProfile = props => {
    const classes = useStyles();
    const forceUpdate = useForceUpdate();
    const [validate,setValidate] = useState(new SimpleReactValidator());
    const [profile,setProfile] = useState(null);
    const [editImage,setImage] = useState(false);
    const [file,setFile] = useState('');
	const [open,setOpen] = useState(false);

      document.body.classList.add("bg-light");

      const [isLoading,setLoading] = useState(false);
      const [FormDetails,setFormDetails] = React.useState({
        customer_id:localStorage.getItem("customer_id"),
        first_name:"",
        last_name:"",
        mobile:"",
        email:"",
        dob:"",
        image_path:"",
        gender:""
    });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [state,setState] = useState({
        image: '',
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 4 / 3,
      });

    const [dob,setDob] = useState({
        date:'',
        month:'',
        year:''
    });

    const handleChange = e => {
        setFormDetails({...FormDetails,
        [e.target.name]: e.target.value
    })
    }

    const handleDobChange = e => {
        setDob({...dob,
        [e.target.name]: e.target.value
    });
    }

    useEffect(() => {
        setLoading(true)
        const param = {
            customer_id:localStorage.getItem("customer_id")
        }
        ProfileController.getProfileDetails(param).then(data => {
            localStorage.setItem("username", data.first_name + " " + data.last_name);
            localStorage.setItem("email", data.email);
            localStorage.setItem("mobile", data.mobile);
            localStorage.setItem("profile", data.image_path);
              setFormDetails(data);
              if(data.dob != ""){
                var tempDob = {...dob};
                var date = new Date(data.dob);
                tempDob.date = date.getDate();
                tempDob.month = date.getMonth() + 1;
                tempDob.year = date.getFullYear();
                setDob(tempDob);
              }
              setLoading(false);
        });
    },[])

    const insertProfileDetails = () => {
        setLoading(true);
        var temp = {...FormDetails};
        var date = dob.date != "" ? (dob.month + "/" + dob.date + "/" + dob.year) : "";
        let formData = new FormData();
        formData.append("imagefile", file);
        formData.append("customer_id",localStorage.getItem("customer_id"));
        formData.append("first_name",temp.first_name);
        formData.append("last_name",temp.last_name);
        formData.append("mobile",temp.mobile);
        formData.append("dob",date);
        formData.append("image_path",temp.image_path);
        formData.append("gender",temp.gender);
        formData.append("WebsiteURl",config.baseUrl);
        ProfileController.insertProfileDetails(formData).then(data => {
            localStorage.setItem("username", data.first_name + " " + data.last_name);
            localStorage.setItem("email", data.email);
            localStorage.setItem("mobile", data.mobile);
            localStorage.setItem("profile", data.image_path);
            console.log(data);
                setFormDetails(data);
                 window.location = "/home";
        });
    }

    const onCropChange = (crop) => {
        setState({ ...state,
        crop:crop })
      }
     
      const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])
     
      const onZoomChange = (zoom) => {
        setState({ ...state,
            zoom:zoom })
      }

      const showCroppedImage = useCallback(async () => {
        try {
          setLoading(true);
          const croppedImage = await getCroppedImg(
            state.image,
            croppedAreaPixels
          )
          setFormDetails({...FormDetails,image_path:croppedImage});
          blobtobase64(croppedImage)
        } catch (e) {
          console.error(e)
        }
      }, [croppedAreaPixels])

      const fileToDataUri = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result)
            };
        reader.readAsDataURL(file);
        });

        async function blobtobase64 (blobs) {
            let theBlob = await fetch(blobs).then(r => r.blob());
            var reader = new FileReader();
            reader.readAsDataURL(theBlob); 
            reader.onloadend = function() {
                var base64data = reader.result;   
                var temp = base64data.replace("jpeg","png");
                setFile(temp);
                UploadPicture(temp);
                setLoading(false);
                setImage(false);
            }
          }

 
          const UploadPicture = (img) => {       
            let formData = new FormData();
            formData.append("customer_id",localStorage.getItem("customer_id"));
            formData.append("image_path",FormDetails.image_path);
            formData.append("imagefile",img);  
            formData.append("WebsiteURl",config.baseUrl);
    
            ProfileController.insertProfilePicture(formData).then(data => {window.location.reload();});
        }

    const handleClose = () => {
        setImage(false);
    }
    
    return(
        <React.Fragment>
             <Dialog 
                open={editImage}
                fullWidth
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-title">{"Edit Profile Photo"}
                <button className="btn btn-link close" onClick={handleClose}> <i className="fas fa-times"></i></button></DialogTitle>
                <DialogContent className="position-relative" style={{height:300}}>
                <Cropper
                    image={state.image}
                    crop={state.crop}
                    zoom={state.zoom}
                    aspect={state.aspect}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                    cropShape={'round'}
                    cropSize={{
                        width:150,
                        height:150
                    }}
                    style={{
                        containerStyle:{
                            height:300
                        }
                    }}
                />
                </DialogContent>
                <DialogActions className="py-3">
                <input
                    accept="image/*"
                    className={"d-none"}
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                        if(e.target.files.length > 0){
                            if(e.target.files[0].size > 3000000){
                                alert("Max file size : 3MB");
                            }
                            else {
                                fileToDataUri(e.target.files[0]).then(data => {
                                    setState({...state,image:data})
                                    setImage(true);
                                });
                            }
                        }
                    }}
                />
                <label className="m-0" htmlFor="contained-button-file">
                    <Button variant="contained" className="btn-theme-outlined font_13" disableElevation color="primary" component="span">
                    Change Photo
                    </Button>
                </label>
                    <button className="btn btn-theme-contained px-5 font_13" onClick={showCroppedImage}>Upload</button>
                </DialogActions>
            </Dialog>
            <Header />
                {open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
                setOpen(false);
            }} /> : null}
                <div className="profile_container mt-3 mx-auto">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="logo_header position-relative ml-3">
                                    <h4>My Profile</h4>
                                </div>
                                <div className="profileBox mt-3 mt-md-5">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="profileImg mt-3" style={{border:FormDetails.image_path ? 'none' : '3px dashed #ccc'}}>
                                                <img src={FormDetails.image_path} className="w-100" />
                                                <input accept="image/*" className="d-none" id="icon-button-file" onChange={(e) => {
                                                   if(e.target.files.length > 0){
                                                    if(e.target.files[0].size > 3000000){
                                                        alert("Max file size : 3MB");
                                                    }
                                                    else {
                                                        fileToDataUri(e.target.files[0]).then(data => {
                                                            setState({...state,image:data})
                                                            setImage(true);
                                                        });
                                                    }
                                                }
                                                }} type="file" />
                                               {FormDetails.image_path ? null :  <label htmlFor="icon-button-file">
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        <img src={camera} />
                                                    </IconButton>
                                                </label>}
                                            </div>
                                            
                                            {FormDetails.image_path  ? <div className="profile_options text-center mt-3">
                                                <label htmlFor="icon-button-file" style={{opacity:0.7}}>
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                    <img src={gallery} />
                                                    </IconButton>
                                                </label>
                                                <button className="btn btn-link" style={{opacity:0.7}} onClick={() => {
                                                    setFormDetails({...FormDetails,image_path:""})
                                                }}><img src={deleteImage} /></button>
                                                
                                            </div> : null}
                                        </div>
                                        <div className="col-md-7 offset-md-1">
                                            <div className="profileForm">
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField variant="standard" value={FormDetails.first_name}   error={validate.message('First Name', FormDetails.first_name , 'required')} helperText={validate.message('First Name', FormDetails.first_name , 'required')} fullWidth label="First Name" onChange={handleChange} name="first_name" size="small" />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField variant="standard" value={FormDetails.last_name}   error={validate.message('Last Name', FormDetails.last_name , 'required')} helperText={validate.message('Last Name', FormDetails.first_name , 'required')} fullWidth label="Last Name" onChange={handleChange} name="last_name" size="small" />
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <TextField variant="standard" value={FormDetails.email} fullWidth error={validate.message('Email', FormDetails.email , 'required|email')} label="E-mail" helperText={validate.message('Email', FormDetails.email , 'required|email')} onChange={handleChange} name="email" size="small" />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField variant="standard" value={FormDetails.mobile} fullWidth label="Mobile" error={validate.message('Mobile', FormDetails.mobile , 'required')} helperText={validate.message('Mobile', FormDetails.mobile , 'required')} onChange={handleChange} name="mobile" size="small" />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            id="standard-select-currency"
                                                            select
                                                            label="Gender"
                                                            fullWidth 
                                                            name="gender"
                                                            onChange={handleChange}
                                                            error={validate.message('Gender', FormDetails.gender , 'required')}
                                                            helperText={validate.message('Gender', FormDetails.gender , 'required')}
                                                            value={FormDetails.gender}  
                                                            >
                                                                <MenuItem value="Male">Male</MenuItem>
                                                                <MenuItem value="Female">Female</MenuItem>
                                                            
                                                            </TextField>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                    <FormControl  fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                                                    <Select
                                                    name="date"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                            onChange={handleDobChange}
                                                            value={dob.date}
                                                    >
                                                                <MenuItem value="1">1</MenuItem>
                                                                <MenuItem value="2">2</MenuItem>
                                                                <MenuItem value="3">3</MenuItem>
                                                                <MenuItem value="4">4</MenuItem>
                                                                <MenuItem value="5">5</MenuItem>
                                                                <MenuItem value="6">6</MenuItem>
                                                                <MenuItem value="7">7</MenuItem>
                                                                <MenuItem value="8">8</MenuItem>
                                                                <MenuItem value="9">9</MenuItem>
                                                                <MenuItem value="10">10</MenuItem>
                                                                <MenuItem value="11">11</MenuItem>
                                                                <MenuItem value="12">12</MenuItem>
                                                                <MenuItem value="13">13</MenuItem>
                                                                <MenuItem value="14">14</MenuItem>
                                                                <MenuItem value="15">15</MenuItem>
                                                                <MenuItem value="16">16</MenuItem>
                                                                <MenuItem value="17">17</MenuItem>
                                                                <MenuItem value="18">18</MenuItem>
                                                                <MenuItem value="19">19</MenuItem>
                                                                <MenuItem value="20">20</MenuItem>
                                                                <MenuItem value="21">21</MenuItem>
                                                                <MenuItem value="22">22</MenuItem>
                                                                <MenuItem value="23">23</MenuItem>
                                                                <MenuItem value="24">24</MenuItem>
                                                                <MenuItem value="25">25</MenuItem>
                                                                <MenuItem value="26">26</MenuItem>
                                                                <MenuItem value="27">27</MenuItem>
                                                                <MenuItem value="28">28</MenuItem>
                                                                <MenuItem value="29">29</MenuItem>
                                                                <MenuItem value="30">30</MenuItem>
                                                                <MenuItem value="31">31</MenuItem>
                                                    </Select>
                                                    {/* <FormHelperText>{validate.message('Date', dob.date , 'required')}</FormHelperText> */}
                                                </FormControl>
                                                         
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                    <FormControl  fullWidth >
                                                        <InputLabel id="demo-simple-select-label2">Month</InputLabel>
                                                        <Select
                                                        name="month"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        labelId="demo-simple-select-label2"
                                                        id="demo-simple-select2"
                                                        onChange={handleDobChange}
                                                        value={dob.month}>
                                                         <MenuItem value="1">Jan</MenuItem>
                                                                <MenuItem value="2">Feb</MenuItem>
                                                                <MenuItem value="3">Mar</MenuItem>
                                                                <MenuItem value="4">Apr</MenuItem>
                                                                <MenuItem value="5">May</MenuItem>
                                                                <MenuItem value="6">Jun</MenuItem>
                                                                <MenuItem value="7">Jul</MenuItem>
                                                                <MenuItem value="8">Aug</MenuItem>
                                                                <MenuItem value="9">Sep</MenuItem>
                                                                <MenuItem value="10">Oct</MenuItem>
                                                                <MenuItem value="11">Nov</MenuItem>
                                                                <MenuItem value="12">Dec</MenuItem>
                                                            </Select>
                                                            {/* <FormHelperText>{validate.message('Month', dob.month , 'required')}</FormHelperText> */}
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                    <FormControl  fullWidth >
                                                        <InputLabel id="demo-simple-select-label3">Year</InputLabel>
                                                        <Select
                                                        name="year"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        labelId="demo-simple-select-label3"
                                                        id="demo-simple-select3"
                                                        onChange={handleDobChange}
                                                        value={dob.year}>
                                                                                                                          
                                                                <MenuItem value="1920">1920</MenuItem>
                                                                <MenuItem value="1921">1921</MenuItem>
                                                                <MenuItem value="1922">1922</MenuItem>
                                                                <MenuItem value="1923">1923</MenuItem>
                                                                <MenuItem value="1924">1924</MenuItem>
                                                                <MenuItem value="1925">1925</MenuItem>
                                                                <MenuItem value="1926">1926</MenuItem>
                                                                <MenuItem value="1927">1927</MenuItem>
                                                                <MenuItem value="1928">1928</MenuItem>
                                                                <MenuItem value="1929">1929</MenuItem>
                                                                <MenuItem value="1930">1930</MenuItem>
                                                                <MenuItem value="1931">1931</MenuItem>
                                                                <MenuItem value="1932">1932</MenuItem>
                                                                <MenuItem value="1933">1933</MenuItem>
                                                                <MenuItem value="1934">1934</MenuItem>
                                                                <MenuItem value="1935">1935</MenuItem>
                                                                <MenuItem value="1936">1936</MenuItem>
                                                                <MenuItem value="1937">1937</MenuItem>
                                                                <MenuItem value="1938">1938</MenuItem>
                                                                <MenuItem value="1939">1939</MenuItem>
                                                                <MenuItem value="1940">1940</MenuItem>
                                                                <MenuItem value="1941">1941</MenuItem>
                                                                <MenuItem value="1942">1942</MenuItem>
                                                                <MenuItem value="1943">1943</MenuItem>
                                                                <MenuItem value="1944">1944</MenuItem>
                                                                <MenuItem value="1945">1945</MenuItem>
                                                                <MenuItem value="1946">1946</MenuItem>
                                                                <MenuItem value="1947">1947</MenuItem>
                                                                <MenuItem value="1948">1948</MenuItem>
                                                                <MenuItem value="1949">1949</MenuItem>
                                                                <MenuItem value="1950">1950</MenuItem>
                                                                <MenuItem value="1951">1951</MenuItem>
                                                                <MenuItem value="1952">1952</MenuItem>
                                                                <MenuItem value="1953">1953</MenuItem>
                                                                <MenuItem value="1954">1954</MenuItem>
                                                                <MenuItem value="1955">1955</MenuItem>
                                                                <MenuItem value="1956">1956</MenuItem>
                                                                <MenuItem value="1957">1957</MenuItem>
                                                                <MenuItem value="1958">1958</MenuItem>
                                                                <MenuItem value="1959">1959</MenuItem>
                                                                <MenuItem value="1960">1960</MenuItem>
                                                                <MenuItem value="1961">1961</MenuItem>
                                                                <MenuItem value="1962">1962</MenuItem>
                                                                <MenuItem value="1963">1963</MenuItem>
                                                                <MenuItem value="1964">1964</MenuItem>
                                                                <MenuItem value="1965">1965</MenuItem>
                                                                <MenuItem value="1966">1966</MenuItem>
                                                                <MenuItem value="1967">1967</MenuItem>
                                                                <MenuItem value="1968">1968</MenuItem>
                                                                <MenuItem value="1969">1969</MenuItem>
                                                                <MenuItem value="1970">1970</MenuItem>
                                                                <MenuItem value="1971">1971</MenuItem>
                                                                <MenuItem value="1972">1972</MenuItem>
                                                                <MenuItem value="1973">1973</MenuItem>
                                                                <MenuItem value="1974">1974</MenuItem>
                                                                <MenuItem value="1975">1975</MenuItem>
                                                                <MenuItem value="1976">1976</MenuItem>
                                                                <MenuItem value="1977">1977</MenuItem>
                                                                <MenuItem value="1978">1978</MenuItem>
                                                                <MenuItem value="1979">1979</MenuItem>
                                                                <MenuItem value="1980">1980</MenuItem>
                                                                <MenuItem value="1981">1981</MenuItem>
                                                                <MenuItem value="1982">1982</MenuItem>
                                                                <MenuItem value="1983">1983</MenuItem>
                                                                <MenuItem value="1984">1984</MenuItem>
                                                                <MenuItem value="1985">1985</MenuItem>
                                                                <MenuItem value="1986">1986</MenuItem>
                                                                <MenuItem value="1987">1987</MenuItem>
                                                                <MenuItem value="1988">1988</MenuItem>
                                                                <MenuItem value="1989">1989</MenuItem>
                                                                <MenuItem value="1990">1990</MenuItem>
                                                                <MenuItem value="1991">1991</MenuItem>
                                                                <MenuItem value="1992">1992</MenuItem>
                                                                <MenuItem value="1993">1993</MenuItem>
                                                                <MenuItem value="1994">1994</MenuItem>
                                                                <MenuItem value="1995">1995</MenuItem>
                                                                <MenuItem value="1996">1996</MenuItem>
                                                                <MenuItem value="1997">1997</MenuItem>
                                                                <MenuItem value="1998">1998</MenuItem>
                                                                <MenuItem value="1999">1999</MenuItem>
                                                                <MenuItem value="2000">2000</MenuItem>
                                                            
                                                        </Select>
                                                        {/* <FormHelperText>{validate.message('Year', dob.year , 'required')}</FormHelperText> */}
                                                    </FormControl>
                                                         
                                                    </div>
                                                    <div className="col-md-12 text-right">
                                                    <ButtonToggle disabled={isLoading}  className="btn-theme-outlined btn font_13 mb-3 px-4 mt-2 d-block ml-auto" onClick={() => {
                                                        if (validate.allValid()) {
                                                            insertProfileDetails();
                                                            //console.log(dob)
                                                        } else {
                                                            validate.showMessages();
                                                            forceUpdate();
                                                            // rerender to show messages for the first time
                                                            // you can use the autoForceUpdate option to do this automatically`
                                                            
                                                        }
                                                    }}>{isLoading ? <CircularProgress style={{width:15,height:15}} /> : null} Save</ButtonToggle>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container-fluid">
                    <Footer />
                </div> */}
        </React.Fragment>
    )
}

export default MyProfile;