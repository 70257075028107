import { userService } from "../_services/api";

export const ListController = {
    getStateList
}

async function getStateList(payload) {
    try {
        let apiEndpoint = `api/Customer/StateList`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}