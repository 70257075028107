import { Divider, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
 
import Header from '../components/header';
import Login from '../components/login';
import { PreviewController } from '../controllers/preview.controller';
import { CommonMethods } from '../Utils/commonMethod';
import tickImage from '../assets/img/tick.png';
import { Beforeunload } from 'react-beforeunload';
import Loading from '../Utils/loading';
import ConfirmationDialog from '../Utils/confirmationDialog';

const PreviewProduct = () => {
    const {item_id} = useParams();
    // Store price slab response 
    const [price_slab, setPriceSlabs] = useState([]);
    const [all_sizes, setAllsizes] = useState([]);
    const [skuProduct,setProduct] = useState(null);
    const [Qty,setQty] = useState(0);
    const [cost,setcost] = useState(0);
    const [TotalAmount,setTotalAmount] = useState(0);
    const [open,setOpen] = useState(false);
    const [selectedGender,setSelectedGender] = useState(0);
    const [selectedIndex,setSelectedIndex] = useState(0);
    const [selectedPath,setSelectedPath] = useState(null);
    const [proceedBefore , setProceedBefore] = useState(false);
    const [showAlert , setShowAlert] = useState({
        show:false,
        message:"",
        type:""
    });
    const [genderQty,setGenderQty] = useState([]);
    const [genderQtyTotal,setgenderQtyTotal] = useState([]);
    const [sku_id,setSku_id ] = useState();
    // Gender Calculation
    const [genderSizes, setGenderSizes] = useState([]); 
    const [editSize,seteditSize]=useState(true);
    const [isLoading,setIsLoading] = useState(true);
    const [qtyError , setQtyError] = useState('');


    useEffect(() => {
        const params = {
            tran_id:window.atob(item_id),
            customer_id:localStorage.getItem("customer_id")
        }
        PreviewController.getCartDesignItem(params).then(data => {   
            setGenderSizes(data.all_sizes);       
            setProduct(data);
            setSelectedPath(data.genders[0].image_paths[0].type == "old" ? "SKU_new" : "Final_Product_Main");


                for (let index = 0; index < data.price_slab.length; index++) {
                    const element = data.price_slab[index];         
                        element.selected= false; 
                    }
                    for (let index = 0; index < data.price_slab.length; index++) {
                        const element = data.price_slab[index];      
                        if(data.qty >= element.min_qty && data.qty <= element.max_qty){   
                            element.selected= true; 
                        }
                }
                
            setcost(data.customization_cost);
            setSku_id(data.sku_id);
            setPriceSlabs(data.price_slab);
            setQty(data.qty);
            setTotalAmount(data.amount);          
            setAllsizes(data.all_sizes);
            initialCalclation(data.all_sizes);
            setIsLoading(false);
        });
     },[]);

     const showCustomAlert = (message , type) => {
        setShowAlert({
            show:true,
            message:message,
            type:type
        });
     }



     const AddToCart = () => {
        if(Qty < 50){
            showCustomAlert("Please fill minimum quantity (MOQ) of 50 Pcs.","alert");
        
         }
       else if(Qty > 500){
            showCustomAlert("For bulk enquiries of more than 500 Pcs, Kindly visit us at www.brij.com.","alert");
        }
         else{
            setProceedBefore(true);
            setIsLoading(true);
            const param = {
                customer_id:localStorage.getItem("customer_id"),
                cart_id:window.atob(item_id),
                sku_id:skuProduct.sku_id,
                qty:Qty,
                amount:TotalAmount,
                rate:skuProduct.rate,
                sizes:genderSizes
            }
            PreviewController.addItemsCart(param).then(data => {
                window.location.pathname = "/cart"
            });
         }
       
    }

    const checkObject = obj => {
        return typeof obj === 'object' && obj !== null 
    }



    const initialCalclation = (all_sizes) => {

        //copy size array into local object
       let newGenderSizeArr = [];     
           
       for (let index = 0; index < all_sizes.length; index++) {
           const element = all_sizes[index];    

           if(newGenderSizeArr.length > 0){          
            var genderIndex = newGenderSizeArr.findIndex(function(m){
              return m.gender_id === element.gender_id && m.size === element.size;
            });
            if(genderIndex !== -1){
              newGenderSizeArr[genderIndex].qty = parseInt(element.qty === "" ? 0 : element.qty);
            }
            else{
              const param = {        
                sku_id:sku_id,      
                gender_id:element.gender_id,
                size:element.size,
                qty:parseInt(element.qty === "" ? 0 : element.qty)
              }
              newGenderSizeArr.push(param);
            }
        }
  
        else{
          const param = {  
           sku_id:sku_id,         
            gender_id:element.gender_id,
            size:element.size,
            qty:parseInt(element.qty === "" ? 0 : element.qty) 
          }
          newGenderSizeArr.push(param);
        }   
           
       }
       let GenderCount = getUnique(newGenderSizeArr,'gender_id'); 

       let tempGenderQty = 0;
       let tempGenderQtyEtotalArr = [];
       let tempGenderQtyArr = newGenderSizeArr;
        for (let indexGender = 0; indexGender < GenderCount.length; indexGender++) {
            let qtyTotal=0;
            const elementGender = GenderCount[indexGender];
            for (let index = 0; index < newGenderSizeArr.length; index++) {
                const element = newGenderSizeArr[index];
                if(element.gender_id === elementGender.gender_id){
                    tempGenderQty = tempGenderQty + element.qty;
                    qtyTotal = qtyTotal + element.qty;
                }
            }
            let param ={
                gender_id:elementGender.gender_id,
                qty:qtyTotal
            }
            tempGenderQtyEtotalArr.push(param);
            
        }     
      setGenderQty(tempGenderQtyArr);
      setgenderQtyTotal(tempGenderQtyEtotalArr);
      setGenderSizes(newGenderSizeArr);
         
    }
      
      const genderCalculation = (value,gender_id,label) => {

         //copy size array into local object
        let newGenderSizeArr = [...genderSizes];
      
         if(newGenderSizeArr.length > 0){;
          
           var genderIndex = newGenderSizeArr.findIndex(function(m){
             return m.gender_id === gender_id && m.size === label;
           });
           if(genderIndex !== -1){
             newGenderSizeArr[genderIndex].qty = parseInt(value === "" ? 0 : value);
           }
           else{
             const param = {        
               sku_id:sku_id,      
               gender_id:gender_id,
               size:label,
               qty:parseInt(value === "" ? 0 : value)
             }
             newGenderSizeArr.push(param);
           }
       }

       else{
         const param = {  
          sku_id:sku_id,         
           gender_id:gender_id,
           size:label,
           qty:parseInt(value === "" ? 0 : value) 
         }
         newGenderSizeArr.push(param);
       }
       let GenderCount = getUnique(newGenderSizeArr,'gender_id'); 
       let tempGenderQty = 0;
       let tempGenderQtyEtotalArr = [];
       let tempGenderQtyArr = newGenderSizeArr;
        for (let indexGender = 0; indexGender < GenderCount.length; indexGender++) {
            let qtyTotal=0;
            const elementGender = GenderCount[indexGender];
            for (let index = 0; index < tempGenderQtyArr.length; index++) {
                const element = tempGenderQtyArr[index];
                if(element.gender_id === elementGender.gender_id){
                    tempGenderQty = tempGenderQty + element.qty;
                    qtyTotal = qtyTotal + element.qty;
                }
            }
            let param ={
                gender_id:elementGender.gender_id,
                qty:qtyTotal
            }
            tempGenderQtyEtotalArr.push(param);
            
        }   
        setgenderQtyTotal(tempGenderQtyEtotalArr);
        setGenderSizes(newGenderSizeArr);   
        
        
     }

     useEffect(() => { 
        let qty = 0;
        for (let index2 = 0; index2 < genderSizes.length; index2++) {
           const element2 = genderSizes[index2];
            qty = qty + parseInt(isNaN(element2.qty) ? 0 : element2.qty);
        }  
        setQty(qty);

        var Cal_rate = 0;
   
        for (let index = 0; index < price_slab.length; index++) {
           const element = price_slab[index];         
                element.selected= false; 
         }
        for (let index = 0; index < price_slab.length; index++) {
            const element = price_slab[index];
            if(qty < 50){
                setQtyError("Min qty required 50");
            }
            else{
                setQtyError("");
                if(qty === 0)
                {
                    Cal_rate =  parseFloat(price_slab[0].price)+ parseFloat(cost);
                    setProduct({...skuProduct,rate:Cal_rate});           
                }
                if(qty >= element.min_qty && qty <= element.max_qty){           
                        Cal_rate =  parseFloat(price_slab[index].price)+ parseFloat(cost);           
                        setProduct({...skuProduct, rate:Cal_rate});
                        element.selected= true;                 
                }
                else if(qty >= 500){
                    Cal_rate =  parseFloat(price_slab[price_slab.length - 1].price)+ parseFloat(cost);
                    setProduct({...skuProduct,rate:Cal_rate});
                }
            }
          
        }
        var TotalCost =  ( parseInt(isNaN(qty) ? 0 : qty) *  (Cal_rate));      
       setTotalAmount(TotalCost); 

    },[genderSizes]); 


  // get unique value form array
  function getUnique(arr, index) {
 
    const unique = arr
          .map(e => e[index])

          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)

          // eliminate the dead keys & store unique objects
          .filter(e => arr[e]).map(e => arr[e]);      

       return unique;
    }


   

    return(
        <React.Fragment>
            <Header />
                {open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
                setOpen(false);
            }} /> : null}
            {isLoading ? <Loading /> : null}
            {proceedBefore ? null : <Beforeunload onBeforeunload={() => "Are you sure want to leave this page? You have unsaved changes.If you leave the page this changes will be lost."} />}
        <ConfirmationDialog show={showAlert.show} onClose={() => setShowAlert({...showAlert,show:false})} type={showAlert.type} message={showAlert.message} />
        <div className="order-container mt-4 mb-5">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                <div className="logo_header position-relative mb-3 ml-3 ml-md-0">
                    <h4>Order Preview</h4>
                </div>
                    <div className="left_side">
                        <div className="row">
                            <div className="col-md-9 order-md-last">
                                <div className="imageView w-100 text-center">
                                    {checkObject(skuProduct) ? <img className="w-75 mx-auto" src={"https://api.go4merchandise.com/Upload/" + selectedPath + "/" + skuProduct.genders[selectedGender].image_paths[selectedIndex].image_path} /> : null }
                                </div>
                            </div>
                            <div className="col-md-3 order-md-first">
                                    {checkObject(skuProduct) ? skuProduct.genders.map((gender,index) => {
                                       if(index === selectedGender){
                                            return <ul className="list-unstyled mt-3 d-small-flex justify-content-small-center" key={index}>
                                            {gender.image_paths.map((image,jdex) => {
                                                return <li className={jdex === selectedIndex ? "active" : ""} onClick={() => {
                                                    setSelectedGender(index);
                                                    setSelectedIndex(jdex);
                                                    setSelectedPath(image.type == 'old' ? "SKU_new" : "Final_Product_Main");
                                                }} key={jdex}><img src={"https://api.go4merchandise.com/Upload/" + (image.type == "old" ? "SKU_Thumbnail" : "Final_Product_Mail" )+ "/" + image.image_path} /></li>
                                            })}
                                        </ul>
                                       }
                                    }) : null}

                                    {checkObject(skuProduct) ? skuProduct.genders.map((gender,index) => {
                                        if(gender.gender == "Male" || gender.gender == "Female"){
                                            return <button key={index} onClick={() => {
                                                setSelectedGender(index);
                                            }} className={"btn btn-link gender font_13 " + (index === selectedGender ? "active" : "text-muted")}>{gender.gender}</button>
                                        }
                                         
                                    }) : null}
                            </div>
                          
                        </div>
                    </div>
                    
                </div>
                <div className="col-md-6 mt-1 mt-md-0">
                     <p className="m-0 font_13 text-theme-color">{checkObject(skuProduct) ? skuProduct.sku : null}  <a className="best-price ml-auto">
                      View Price Slab
                      <div className="best-offer width-50">
                         <table className="table table-bordered">
                            <thead className="thead-dark">
                               <tr>
                                  <td>Qty</td>
                                  <td>Price/Pc</td>
                               </tr>
                            </thead>
                            <tbody>
                               {price_slab.length > 0 ? price_slab.map((prop,index) => {
                               return<tr key={index} className={prop.selected ? "selected":""}>
                                  <td> {prop.min_qty} - {prop.max_qty}</td>
                                  <td>Rs  {prop.price} {prop.selected ? <img style={{width:20,marginLeft:10}} src={tickImage} /> : null}</td>
                               </tr>
                               }) : null}
                            </tbody>
                         </table>
                      </div>
                   </a></p>
                     <h6>{checkObject(skuProduct) ? skuProduct.product_name : null}</h6>
                     <Divider />
                     <div className="color_details mt-1">
                         <p className="font_13 m-0">
                        
                             
                             
                             <strong>Base Color</strong>: {checkObject(skuProduct) ? skuProduct.color : null}</p>
                         {checkObject(skuProduct) ? skuProduct.color == skuProduct.contrast_color ? null : <p className="font_13 m-0"><strong>Contrast Color</strong>:{checkObject(skuProduct) ? <img className="img-30" src={"https://api.go4merchandise.com/Upload/Product_Contrast/" + skuProduct.contrast_color_icon} /> : null } {checkObject(skuProduct) ? skuProduct.contrast_color : null}</p> : null}
                     </div>
                     <p className="font_13 mt-1 mb-1">{checkObject(skuProduct) ? skuProduct.description : null}</p>
                     <div className="d-flex">
                         <p className="font-weight-bold font_13 mb-1">Fabric Blend:&nbsp;</p>
                         <div>
                                <p className="font_13 m-0">{checkObject(skuProduct) ? skuProduct.fabric_description : null}</p>
                         </div>
                     </div>
                     <div className="d-flex mt-1">
                         <p className="font-weight-bold font_13 mb-0">Branding: &nbsp;</p>
                         <div>
                             <p className="font_13 m-0">Digital Embroidery</p>
                         </div>
                     </div>
                     {checkObject(skuProduct) ? skuProduct.customized_text.map((brand,index) => {
                            return <div className="brandingText d-flex align-items-center">
                                <p className="font_13 m-0" key={index}>-{brand.text}</p>
                                {brand.comment != "" ? <p className="font_10 mb-0 ml-1">({brand.comment})</p> : null}
                            </div>
                     }) : null}
                      <div className="font-weight-bold font_13 mt-2 mb-2 d-flex align-items-center">Size: &nbsp; 
                      <div className="editSize mr-auto">                             
                        <i className="fas fa-pencil theme-color-a cursor-pointer" onClick={()=>{
                            seteditSize(!editSize);
                        }} style={{fontSize:12}}></i>
                      </div>
                      </div>
                     <div className="d-lg-flex mt-1">
                      
                         <div>
                             <div className="font_13">
                                 
                                {genderSizes.length > 0 ?  skuProduct ? skuProduct.product_genders.map((gen,index) => {
                                     return <div key={index} className="mb-3 d-flex align-items-center">
                                     <span className="d-inline-block mr-2 width-50px">{gen.gender}</span> 
                                     {gen.sizes.map((size,index) => {
                                         return  <TextField autoFocus disabled={editSize} key={"child" + index} type="text" size="small" className="mr-md-2 sizeInput" style={{
                                            width:48
                                        }} InputLabelProps={{
                                            style:{
                                                fontSize:10
                                            }
                                        }} InputProps={{
                                            style:{
                                                  fontSize:10
                                            }
                                        }} id="outlined-basic" label={size.size} value={genderSizes.length > 0 ? parseInt(genderSizes[genderSizes.findIndex(x => x.gender_id === gen.tran_id && x.size === size.size)].qty) === 0 ? "" : genderSizes[genderSizes.findIndex(x => x.gender_id === gen.tran_id && x.size === size.size)].qty : ""}
                                        
                                        onChange={(e) => {
                                            if(/^\d+$/.test(e.target.value) || e.target.value === ""){
                                                genderCalculation(e.target.value,gen.tran_id,size.size)
                                              }
                                              else{
                                                return false;
                                              }
                                            
                                         }} variant="outlined" />
                                        
                                     })}
                                       <li className="total_gender">{typeof genderQtyTotal[genderQtyTotal.findIndex(x => x.gender_id === gen.tran_id)] !== 'undefined' ? genderQtyTotal[genderQtyTotal.findIndex(x => x.gender_id === gen.tran_id)].qty : 0}</li>
                                 </div>
                                 }) : null : null}
                                  
                             </div>
                             <p className="text-danger mb-0 font_13">{qtyError}</p>
                         </div>
                
                     </div>
                     <p className="mt-auto mb-0 font_13 ml-auto text-right">Total Qty.: { Qty }pcs</p>
                     <Divider className="mt-2" />
                     <div className="d-flex justify-content-end mt-2">
                         <p className="font_13 mr-2 mt-1 mb-0">Total Amount &#8377; { CommonMethods.currenyMasking(parseInt(TotalAmount))}</p>
                         <div>
                             <span className="text-muted" style={{fontSize:10}}>(inclusive of taxes)</span>
                             <span className="text-muted d-block text-right" style={{fontSize:10}}>(Rs. {checkObject(skuProduct) ? skuProduct.rate : null}/pc)</span>
                         </div>
                     </div>
                     <Divider />
                     <p className="text-center mt-2 text-danger" style={{fontSize:9}}>Renders are only for graphic representation. Actual color, size, logo may vary from the image shared.</p>
                     <div className="row">
                         <div className="col-lg-6 text-center feature">
                             <p className="font_13 font-weight-bold">Features</p>
                              <ul className="list-unstyled d-flex">
                                  {checkObject(skuProduct) ? skuProduct.fabric_features.feature.map((feature,index) => {
                                      return <li className="d-inline-block p-2">
                                      <img key={index} src={"https://api.go4merchandise.com/Upload/Fabric_Feature/" + feature.icon_path} />
                                      <p className="m-0 font_11 text-muted">{feature.feature}</p>
                                  </li>
                                  }) : null}
                              </ul>
                         </div>
                         <div className="col-lg-6 text-center feature">
                             <p className="font_13 font-weight-bold">Wash Care</p>
                                <ul className="list-unstyled d-flex">
                                    {checkObject(skuProduct) ?  skuProduct.fabric_features.washcare.map((wash,index) => {
                                        return <li className="d-inline-block p-2">
                                        <img key={index} src={"https://api.go4merchandise.com/Upload/Fabric_Feature/" + wash.icon_path} />
                                        <p className="m-0 font_11 text-muted">{wash.feature}</p>
                                    </li>
                                    }) : null}
                                </ul>
                         </div>
                         <div className="col-md-12 text-center">
                             <button className="btn btn-theme-outlined-animate font_13 mt-3 mt-md-0" onClick={AddToCart}>Move to Cart</button>
                         </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
    {/* <div className="container-fluid">
        <Footer />
    </div> */}
    </React.Fragment>
    )
}

export default PreviewProduct;