import React, { useState } from 'react';
import flag from '../assets/images/flag.png';
import twitter from '../assets/images/twitter.png';
import facebook from '../assets/images/facebook.png';
import instagram from '../assets/images/instagram.png';
import linkedin from '../assets/images/linkedin.png';
import youtube from '../assets/images/youtube.png';
import follow from '../assets/images/follow-us-arrow.jpg';
import Login from './login';
import { FooterController } from '../controllers/footer.controller';
import { Divider } from '@material-ui/core';

const Footer = () => {
	const [showLogin,setShowLogin] = useState(false);
	const [isLogin,setIsLogin] = useState(false);
	const [validateEmail,setValidateEmail] = useState({
		valid:false,
		message:'',
		value:''
	});

	const subscribeUser = () => {
		if(validateEmail.value !== ''){
			const param = {
				email:validateEmail.value
			}
			FooterController.subscribeUser(param).then(data => {
				if(data.valid){
					if(data.type === "false"){
						alert("Already subscribed !");
					}
					else{
						alert("Thanks for subscribing !")
					}
				}
			});
		}
		else{
			alert("Please Fill Email Id")
		}
	}
    
    return(
		<React.Fragment>
			{showLogin ? <Login onHide={() => setShowLogin(false)} onSuccess={() => {
            setIsLogin(true);
            setShowLogin(false);
          }} /> : null}
		
        <div className="row ftr pt-5">
				<footer className="w-100">
					<div className="container">
						<div className="ftr1 mb-3 mb-md-0">
							<p className="font-weight-bold mb-0 mb-md-2">About Us</p>
							<ul>
								<li><i className="arrow right"></i><a href="/whyGo4M">Why Go4M</a></li>
								<li><i className="arrow right"></i><a href="/howitworks">How It Works</a></li>
							</ul>
						</div>
						<div className="ftr1 mb-3 mb-md-0">
							<p className="mb-0 mb-md-2"><strong>Customise Products</strong></p>
							<ul>
								<li><i className="arrow right"></i><a href="#"  onClick={(e) => {
									e.preventDefault();
									if(localStorage.getItem("login") && (window.outerWidth > 600)){
										window.open(`https://configurator.go4merchandise.com/customise/15?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
									  }
									  else if(localStorage.getItem("login") && (window.outerWidth < 600)){
										window.open(`/configurator?selectionId=15`);
									  }
									  else{
										setShowLogin(true);
									  }
								}}>T-shirt</a></li>
								<li><i className="arrow right"></i><a href="#" onClick={(e) => {
									e.preventDefault();
									if(localStorage.getItem("login") && (window.outerWidth > 600)){
										window.open(`https://configurator.go4merchandise.com/customise/53?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
									  }
									  else if(localStorage.getItem("login") && (window.outerWidth < 600)){
										window.open(`/configurator?selectionId=53`);
									  }
									  else{
										setShowLogin(true);
									  }
								}}>5-Panel Cap</a></li>
								<li><i className="arrow right"></i><a href="#" onClick={(e) => {
									e.preventDefault();
									if(localStorage.getItem("login") && (window.outerWidth > 600)){
										window.open(`https://configurator.go4merchandise.com/customise/102?domain=${window.btoa(localStorage.getItem("login").toString())}&customer=${window.btoa(localStorage.getItem("customer_id"))}`,"_blank");
									  }
									  else if(localStorage.getItem("login") && (window.outerWidth < 600)){
										window.open(`/configurator?selectionId=102`);
									  }
									  else{
										setShowLogin(true);
									  }
								}}>6-Panel Cap</a></li>
							</ul>
						</div>
						 
						<div className="ftr1  mb-3 mb-md-0">
							<p className="mb-0 mb-md-2"><strong>Useful Links</strong></p>
							<ul>
								<li><i className="arrow right"></i><a href="/shipping">Shipping &amp; Delivery</a></li>
								<li><i className="arrow right"></i><a href="/exchange">Exchange &amp; Return</a></li>
								<li><i className="arrow right"></i><a href="/faq">FAQ's</a></li>
							</ul>
						</div>
						<div className="ftr2 mt-3 mt-md-0">
							<p><strong>Let's Connect for Special Offers</strong></p>							
							<div className="group subs mb-4">      
							  <input type="text" className="text-white" value={validateEmail.value} onChange={(e) => setValidateEmail({...validateEmail,value:e.target.value})} placeholder="Email" required />
							  {/* <span className="highlight"></span>	 */}
							  {/* <span className="bar"></span> */}
							</div>
							<div className="sbmt mb-3 mb-md-0"><input type="submit" value="Subscribe" className="btn-theme-outlined-animate btn font_13" onClick={subscribeUser} /></div>
							<div className="clr"></div>
							<a href="mailto:info@go4merchandise.com" className="text-decoration-none d-none d-md-block"><strong className="text-white">Contact Us</strong>: info@go4merchandise.com</a>
						</div>											
					</div>
					<div className="container">
						<div className="ftrbx px-3 px-md-4 mt-md-5 mt-2">
							<div className="row">
							<div className="col-lg-9 d-md-flex align-items-center"><img className="flag mr-2" src={flag} /><span className="">2020, The content of this site is copyright-protected and is the property of 
								<a href="/home" className="ml-2">Go4Merchandise.com</a></span>
							</div>
							<div className="col-lg-3">
								<ul>
									<li><a href="/terms">Terms of use</a></li> | 
									<li className="ml-1"><a href="/policy">Privacy Policy</a></li>
								</ul>
							</div>
							</div>
						</div>
					</div>
					
					<div className="sidesocial">
						<ul>
							<li><img src={follow} className="sidesocialimg" /></li>
							<li><a target="_blank" href="https://www.linkedin.com/company/go4merchandise"><img src={linkedin} /></a></li>
							<li><a target="_blank" href="https://twitter.com/go4merchandise1" target="_blank"><img src={twitter} /></a></li>
							<li><a target="_blank" href="https://www.instagram.com/go4merchandise/"><img src={instagram} /></a></li>
							<li><a target="_blank" href="https://www.facebook.com/Go4MerchandisebyBrij"><img src={facebook} /></a></li>
							<li><a target="_blank" href="https://www.youtube.com/channel/UCnrxxidNH6oPUef8Ho0sq7A"><img src={youtube} /></a></li>
						</ul>
					</div>
					
					
				</footer>
			</div>
		</React.Fragment>
    )
}

export default Footer;