import { Accordion, AccordionDetails, AccordionSummary, Divider, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Login from '../components/login';
import Footer from '../components/footer';
import { CartController } from '../controllers/cart.controller';
import emptyOrder from '../assets/images/emptyorder.png';
import Loading from '../Utils/loading';


const MyOrder = props => {
    document.body.classList.add("bg-light");
    const [isLoading,setIsLoading] = useState(true);
    const [orderList,setOrderList] = useState([]);
    const [collapsible,setCollapsible] = useState(null);
    const [orderDetails,setOrderDetails] = useState(null);
    const [open,setOpen] = useState(false);

    
    useEffect(() => { 
        const param = {
            customer_id:localStorage.getItem("customer_id")
        }
        CartController.getOrdersList(param).then(data => {
            setOrderList(data);
            setIsLoading(false)
            // setOrderDetails(data[0]);
        });
    },[]);

    const viewOrderDetails = (index) => {
        if(orderDetails){
            setOrderDetails(null);
        }
        else{ 
            var order = orderList[index];
            setOrderDetails(order);
        }
    }

    const printnow = (order_no) =>{
        // console.log(order_no)
        var orderno = window.btoa(order_no)
        window.open("https://go4merchandise.com/invoice?selected=" + orderno)
      }

    const pay_now =(id)=>{
        window.open("https://go4merchandise.com/payment?i=" + id);
    }

    return <React.Fragment>
        {isLoading ? <Loading message={"Fetching Orders..."} /> : null}
          <Header />
                {open ? <Login onHide={() => setOpen(false)} onSuccess={() => {
                setOpen(false);
            }} /> : null}
        <div className="myorder-container mt-5">
            <div className="container">
                <div className="logo_header position-relative mt-2">
                    <h4>Your Orders</h4>
                </div>
               {!isLoading && orderList.length ?  <div className="row mt-4">
                 {orderDetails ?  <div className="col-md-10">
                       <div className="order_details p-3 mb-3">
                            <div className="d-flex">
                               <p className="mb-0"><span className="font_13 text-muted">Order {orderDetails.order_status == "Success" ? "Placed" : "Canceled "} on </span><span className="font_13">{orderDetails.datetime}</span></p>
                               <p className="mb-0 ml-auto"><span className="font_13 text-muted">Order </span><span className="font_13">#{orderDetails.order_no}</span></p>
                            </div>
                            <div className="d-flex">
                               <p className="mb-0"><span className="font_13 text-muted">Order {orderDetails.order_status == "Success" ? "Placed" : "Canceled "} by </span><span className="font_13 text-capitalize">{localStorage.getItem("username")}</span></p>
                               <p className="mb-0 ml-auto">   
                               <button className="btn btn-link text-theme-color font_13" onClick={() => viewOrderDetails("")}>Order Detail</button>  
                               {orderDetails.order_status == "Success" ? <button onClick={() => printnow(orderDetails.order_no)} className="btn btn-link text-theme-color font_13">Invoice</button> : 
                               <button type="button" className="btn btn-link text-theme-color font_13" onClick={() => pay_now(orderDetails.tran_id)}>Retry Payment</button>}
                                </p>
                                </div>
                        <Divider className="mb-3"/>
                            <h6 className="font-weight-bold">Shipping Address</h6>
                            <p className="font_13 text-uppercase">{localStorage.getItem("username")}</p>
                            <p className="font_13 mb-2">{orderDetails.ship_to}</p>
                            <p className="font_13 mb-0">{orderDetails.address} {orderDetails.city} {orderDetails.state_name} {orderDetails.pin}</p>
                            <p className="font_13">Mobile: 1234567890</p>
                                <Divider className="mb-3" />
                            <h6 className="font-weight-bold">Billing Address</h6>
                            <p className="font_13 text-uppercase">{localStorage.getItem("username")}</p>
                            <p className="font_13 mb-2">{orderDetails.bill_to}</p>
                            <p className="font_13 mb-0">{orderDetails.saddress} {orderDetails.scity} {orderDetails.sstate_name} {orderDetails.spin}</p>
                            <p className="font_13">Mobile: {orderDetails.mobile}</p>
                            <table id="cart" className="table table-hover table-condensed">
    				<thead>
						<tr>
							<th className="font-weight-bold" style={{width:'62%'}}>Product Description</th>
							<th className="font-weight-bold" style={{width:'8%'}}>Price/Unit</th>
						 
							<th className="font-weight-bold" style={{width:'17%'}} className="text-center">Total Amount</th>
							
						</tr>
					</thead>
					<tbody>
						{orderDetails.product.map((cart,index) => {
                            return <tr key={index}>
							<td>
								<div className="row no-gutters">
									<div className="col-sm-4 hidden-xs"><img src={"https://api.go4merchandise.in/Upload" + (cart.type === "New" ? "/Final_Product_Main/" : "/SKU_new/") + cart.product_image} alt="..." className="cartImage img-fluid"/></div>
									<div className="col-sm-8">
										<h6 className="m-0">{cart.product_name}</h6>
										<p className="font_13">({cart.fabric_description})</p>
                                        <h6>Branding <span className="font_11">(Digital Embroidery)</span></h6>
                                        {cart.customized_text.map((emb,index) => {
                                            return <span className="d-block font_11" key={index}>- {emb.text}</span>
                                        })}
                                        <h6 className="font_13 mt-3">Total Qty.: {cart.qty}pcs</h6>
                                        <div className="d-inline-flex size_collapse">
                                            <span style={{width:50}} className="font_13"> Size :</span>
                                            <div>
                                            {cart.genders.map((gen,index) => {
                                                return <Accordion className="size_collapse" key={index}>
                                                <AccordionSummary
                                                expandIcon={<i class="fas fa-chevron-down" style={{fontSize:12}}></i>}
                                                aria-controls={"panel"+ index +"a-content"}
                                                id={"panel"+ index +"a-header"}>
                                                    <Typography>{gen.gender}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>                                                       
                                                    {gen.sizes.map((size,index) => {
                                                        return <div className="sizeBox" key={index}>
                                                            <label>{size.size}</label>
                                                            <label>{size.qty}</label>
                                                        </div>
                                                    })}
                                                
                                                </AccordionDetails>
                                            </Accordion>
                                                
                                                // <React.Fragment key={index}>
                                                //      <button className={"collapsible " + (collapsible === index ? "active" : "")} onClick={() => setCollapsible(isNaN(collapsible) ? null : index)}>{gen.gender}</button>
                                                //     <div className={"content " + (collapsible === index ? "activeContainer" : "")}>
                                                //         {gen.sizes.map((size,index) => {
                                                //             return <TextField disabled
                                                //                 key={index} size="small" className="mr-2 mb-2" style={{
                                                //                 width:50
                                                //             }} InputLabelProps={{
                                                //                 style:{ 
                                                //                     fontSize:11
                                                //                 }
                                                //             }} InputProps={{
                                                //                 style:{
                                                //                     fontSize:11
                                                //                 }
                                                //             }} id="outlined-basic" label={size.size} value={size.qty} variant="outlined" />
                                                //         })}
                                                //     </div>
                                                // </React.Fragment>
                                            })}
                                            </div>
                                        </div>
 

									</div>
								</div>
							</td>
							<td className="font_14">₹ {cart.rate}</td>
							<td className="font_14 text-center">₹ {cart.amount}</td>
							 
						</tr>
                        })}
					</tbody>
        
				</table>
                <Divider className="mb-3" />
                <strong className="mb-3 d-block">Payment Details</strong>
                {orderDetails.order_status == "Success" ? 
                <p className="font_13 mb-2">Payment Method <span className="float-right">{orderDetails.payment_mode}</span></p> : null}
                <p className="font_13 mb-2">Total Item(s) <span className="float-right">{orderDetails.product.length}</span></p>
                <p className="font_13 mb-2">Subtotal <span className="float-right">₹ {orderDetails.basic}</span></p>
                <p className="font_13 mb-2">Taxes (5%) <span className="float-right">₹ {parseFloat(orderDetails.igst) + parseFloat(orderDetails.sgst) + parseFloat(orderDetails.cgst)}</span></p>
                <p className="font_13 mb-2">Shipping Charges <span className="float-right">₹ 0</span></p>
                <div className="strip position-relative">
                     
                    <strong className="font_13 mb-2 d-block position-relative">Total amount <span className="float-right">₹ {orderDetails.total_amount} </span></strong>
                </div>
                       </div>
                   </div> :  <div className="col-md-10">
                       {!isLoading && orderList.length > 0 ? orderList.map((order,index) => {
                           return   <div className="orders-section p-2 p-md-3 mb-3" key={index}>
                           <div className="d-flex">
                               <p className="mb-0"><span className="font_13 text-muted">Order {order.order_status == "Success" ? "Placed" : "Canceled "} on </span><span className="font_13">{order.datetime}</span></p>
                               <p className="mb-0 ml-auto"><span className="font_13 text-muted">Order </span><span className="font_13">#{order.order_no}</span></p>
                           </div>
                           <div className="d-flex">
                               <p className="mb-0"><span className="font_13 text-muted">Order {order.order_status == "Success" ? "Placed" : "Canceled "} by </span><span className="font_13 text-capitalize">{localStorage.getItem("username")}</span></p>
                               <p className="mb-0 ml-auto"><button className="btn btn-link text-theme-color font_13" onClick={() => viewOrderDetails(index)}>Order Detail</button>
                               {order.order_status == "Success" ? <button onClick={() => printnow(order.order_no)} className="btn btn-link text-theme-color font_13">Invoice</button> : 
                               <button type="button" className="btn btn-link text-theme-color font_13" onClick={() => pay_now(order.tran_id)}>Retry Payment</button>}</p>
                           </div>
                           <Divider />
                           {order.product.map((cart,index) => {
                               return <div className="products row no-gutters py-3" key={index}>
                               <div className="col-sm-2 hidden-xs"><img src={"https://api.go4merchandise.in/Upload" + (cart.type === "New" ? "/Final_Product_Main/" : "/SKU_Main/") + cart.product_image} alt="..." className="cartImage img-fluid"/></div>
                               <div className="col-sm-10">
                                   <h6 className="m-0">{cart.product_name}</h6>
                                   <p className="font_13">({cart.fabric_description})</p>
                                   {/* <p className="font_13">(60% Cotton + 40% Polyester)</p> */}
                                   <h6>Branding <span className="font_11">(Digital Embroidery)</span></h6>
                                    {/* {cart.customized_text.map((emb,index) => {
                                        return <span className="d-block font_11" key={index}>- {emb.text}</span>
                                    })} */}
                                   <h6 className="font_13 mt-3">Total Qty.: {cart.qty}pcs</h6>
                                   <div className="d-md-flex d-block size_collapse w-100 ">
                                       <span style={{width:50}} className="font_13">Size :</span>
                                       <div>
                                       {cart.genders.map((gen,index) => {
                                           return <Accordion className="size_collapse" key={index}>
                                           <AccordionSummary
                                           expandIcon={<i class="fas fa-chevron-down" style={{fontSize:12}}></i>}
                                           aria-controls={"panel"+ index +"a-content"}
                                           id={"panel"+ index +"a-header"}>
                                               <Typography>{gen.gender}</Typography>
                                           </AccordionSummary>
                                           <AccordionDetails>                                                       
                                               {gen.sizes.map((size,index) => {                                                 
                                                   return <div className="sizeBox" key={index}>
                                                       <label>{size.size}</label>
                                                       <label>{size.qty}</label>
                                                   </div>
                                               })}
                                           
                                           </AccordionDetails>
                                       </Accordion>
                                           
                               
                                       })}
                                       </div>
                                       
                                   </div>
                               </div>
                           </div>
                           })}
                           <Divider className="mb-2" />
                           <p className="font_13 mb-0 ml-auto mt-3 my-md-auto text-right"><span className="text-muted">Total amount</span> <span className="ml-2">₹ {order.total_amount}</span></p>
                       </div>
                   
                       }) : null}
                    </div>}
                  
                </div>
            : isLoading ? null :  <div className="no-orders mb-5 text-center">
            <img src={emptyOrder} style={{width:200}} />
            <h4>You haven't place any order yet</h4>
            <p className="font_13 text-muted low-opacity">Order section is empty after placing order, you can track them here!</p>
            <a href="/home" className="btn-theme-outlined-animate px-4 py-2">Start Customising</a>
        </div>}
            </div>
        </div>
        <div className="container-fluid">
                    <Footer />
                </div>
    </React.Fragment>
}

export default MyOrder;