import React from 'react';
import BreadCrumb from '../components/breadcrumbs';
import Footer from '../components/footer';
import Header from '../components/header';

const Exchange = props => {
    return(
<React.Fragment>
    <Header />
    <BreadCrumb current="Exchange and Returns" />
        <div className="container py-5">
		{/* <h4>Exchange and Returns</h4> */}
        <div class="container">
        {/* <h4><span>Exchange and Returns</span></h4> */}
        <p><a href="https://go4Merchandise.com">go4Merchandise.com</a> design, process and manufactures your product following strict quality levels
            which is a part of its DNA.  <br/><br/>With an experience of 30 years in industry, we know that all designs are specifically
            customised and made exclusively for you. Any return which comes to us can’t be sold to any
            other customer with your customised Merchandise having your Brand. So, we ensure
            whatever reaches you must be what you ordered. In this case No Refund will be initiated
            although, either it will be exchanged or an equalling amount will be credited to your wallet to
            redeem when you make your next purchase. <br/> <br/></p>

            <h4>Defective/ Faulty Garment</h4>
            <p>In case of any manufacturing defect/ wrong order, kindly drop a mail to us, Within 48 hours
            of receipt of order at <a href="support@go4merchandise.com">support@go4merchandise.com</a> to log your issue. Will need an original
            invoice in case of any exchange. <br/> <br/></p>

            <h4>Late Deliveries</h4>
            <p>Please keep sufficient buffers to avoid delayed deliveries.
            We offer free shipment via surface mode and your order should be delivered within 14-21
            business days*. In case of any delays, reasons could be relating to logo uploaded quality/
            size issues or any unavoidable situation during transit. </p>
                </div>
           </div>
            <div className="container-fluid">
              <Footer />
            </div>
        </React.Fragment>

    )
}

export default Exchange;