import React, { useEffect } from 'react';
import { CartController } from '../controllers/cart.controller';
import moment from 'moment';
import logo from '../assets/images/logo.jpg';
import stamp from '../assets/images/stamp.png';
const InvoicePreview = (props) => {
    const [formDateObject, setdata] = React.useState(null);
    const [total_count, setTotalCount] = React.useState(0);
    const [total_items, setTotalItems] = React.useState(0);

    const order_no = new URLSearchParams(window.location.search).get("selected")

    useEffect(() => {

        CartController.generateInvoice({order_no:window.atob(order_no)}).then(data => {
             setdata(data);
             setTotalCount(data.items[0].ts_count);
             setTotalItems(parseFloat(data.total_items));
        });
     },[]);



    return <>
    {formDateObject ?  <div className="invoice-box">
        <table cellPadding="0" cellSpacing="0">
            <tr className="top">
                <td colSpan="12">
                    <table>
                        <tr>
                            <td className="title">
                                <img src={logo} style={{width:'100%',maxWidth:200}} />
                            </td>
                            <td>
                                <span className="font_13">A-1/2 WAZIRPUR IND. AREA, DELHI 110052</span><br />
                                <span className="font_13">PAN No. : AANFB7663B GSTIN : 07AANFB7663B1ZF </span><br />
                                <span className="font_13">011-47063900-01-02 </span><br />
                                <a href="mailto:info@go4merchandise.com" className="font_13 text-theme-color">info@go4merchandise.com</a> 
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr className="information borders">
                <td colSpan="12">
                    <table>
                        <tr>
                            <td className="font_13 align-bottom">
                               <h4>Invoice</h4>
                            </td>
                            
                            <td className="font_13">
                                <b>Order #:</b>  {formDateObject ? formDateObject.order_no : null}<br />
                                <b>Created:</b> {formDateObject ? formDateObject.date : null}
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>    
            <tr className="information">
                <td colSpan="12">
                    <table>
                        <tr>
                            <td className="font_13">
                                <b>Bill to: </b>{formDateObject.billto_company}<br />
                                {formDateObject.billto_address}<br />
                                {formDateObject.billto_city}, {formDateObject.billto_state}, {formDateObject.billto_country}  
                            </td>
                            
                            <td className="font_13">
                                <b>Ship to: </b>{formDateObject.shipto_company}<br />
                                {formDateObject.shipto_address}<br />
                                {formDateObject.shipto_city}, {formDateObject.shipto_state}, {formDateObject.shipto_country}  
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <br />
            <tr className="heading font_14 item_head">
                <td >
                    Sr No.
                </td>
                <td >
                    Description
                </td>
                <td >
                    Quantity(Pcs.)
                </td>
                <td className="text-right"> 
                    Price
                </td>
                
                <td className="text-right">
                    Amount
                </td>
            </tr>
            {formDateObject  ? formDateObject.items.map((item,index) => (
                <tr className="item font_13" key={index}>
                        <td>
                            {index + 1}
                        </td>
                        <td className="text-left">
                            {item.product_name}
                        </td>
                        <td className="text-right">
                            {item.qty}
                        </td>
                        <td className="text-right">
                            {item.rate}
                        </td>
                        <td className="text-right">
                            {item.amount}
                        </td>
                </tr>
              )) : null}
         {total_count > 0 ? <tr className="item font_13" >
                        <td>
                        {total_items+ 1}
                        </td>
                        <td className="text-left">
                        Complimentary Fabric Face Mask/ Black
                        </td>
                        <td className="text-right">
                            {total_count}
                        </td>
                        <td className="text-right">
                            0
                        </td>
                        <td className="text-right">
                           0
                        </td>
                </tr> : null}
          
             
             <tr className="item heading font_13">
                        <td></td>
                        <td className="text-left"></td>
                        <td className="text-right"></td>
                        <td className="text-right">Subtotal</td>
                        <td className="text-right">₹ {formDateObject.basic}</td>
                </tr>
                <tr className="item heading font_13">
                        <td></td>
                        <td className="text-left"></td>
                        <td className="text-right"></td>
                        <td className="text-right">Tax(%) & Amount</td>
                        {/* <td className="text-right">{formDateObject.igst_amt == "0.00" ? `${(parseInt(formDateObject.cgst_per) + parseInt(formDateObject.sgst_per))}%` : parseInt(formDateObject.igst_per)} & ₹ {formDateObject.igst_amt == "0.00" ? (parseInt(formDateObject.cgst_amt) + parseInt(formDateObject.sgst_amt)) : parseInt(formDateObject.igst_amt)}</td> */}
                        <td className="text-right">   5% & ₹ {(parseFloat(formDateObject.total_amount) - parseFloat(formDateObject.basic)).toFixed(2)}</td>
                </tr>
                <tr className="item heading font_13">
                        <td></td>
                        <td className="text-left"></td>
                        <td className="text-right"></td>
                        <td className="text-right">Total Amount</td>
                        <td className="text-right">₹ {formDateObject.total_amount}</td>
                </tr>
            <tr className="total">
                <td colSpan="12" className="text-right">

                </td>
            </tr>
            <tr className="information">
                <td colSpan="12" className="text-right">
                    <img style={{width:100}} src={stamp} />
                    <p className="font_13">Authorised Signatory</p>
                </td>
                
            </tr>
       
            <tr><td colSpan="12"> <button className="btn btn-default" onClick={() => {window.print()}}><i class="fas fa-print"></i> Print Invoice</button></td></tr>
        </table>
    </div>
   : null}
   </>
}

export default InvoicePreview;