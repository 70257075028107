import { userService } from "../_services/api";

export const FooterController = {
   subscribeUser
}


async function subscribeUser(payload) {
    try {
        let apiEndpoint = `api/Customer/CustomerSubscriberInsert`;

        let response = await userService.post(apiEndpoint,payload);
        
        if (response) {
            return (response.data);
        }
        else {
            if (response.data.status !== 500) {
                alert(response.data);
            }
            return null;
        }
    }
    catch (error) {
        console.log("error", error);
        return null;
    }
}